import React from 'react'
import { Grid, Tooltip, ButtonBase } from '@mui/material'
import { useNavigate } from "react-router-dom";
import makeApiRequest from '../../../../../api/api';
import CloseIcon from '@mui/icons-material/Close';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DownloadIcon from '@mui/icons-material/Download';
import { useStyles } from './styles';


export default function MenuProjectOptions({ project_id, project_name, setProgress, set_delete_dialog }) {
  const navigate = useNavigate();
  const classes = useStyles()


  const handleClose = (purpose) => {
    purpose === 'edit' && navigate(`/admin_panel/project_edit/${project_id}/`)
    if (purpose === 'download') {
      setProgress(true)
      makeApiRequest(`/api/projects/${project_id}/download_pdf/`).then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${project_name}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setProgress(false)
      })
    }
    purpose === 'delete' && set_delete_dialog(true)
  };

  return (
    <Grid container p={0} justifyContent={'center'} className={classes.root}>
      <Grid item xs={12} align='center' m={.5}>
        <Tooltip title='delete ad'>
          <ButtonBase className={classes.btnError} onClick={() => handleClose('delete')}>
            <CloseIcon />
          </ButtonBase>
        </Tooltip>
      </Grid>
      <Grid item xs={12} align='center' m={.5}>
        <Tooltip title='edit ad'>
          <ButtonBase onClick={() => handleClose('edit')}>
            <EditNoteIcon />
          </ButtonBase>
        </Tooltip>
      </Grid>
      <Grid item xs={12} align='center' m={.5}>
        <Tooltip title='download PDF'>
          <ButtonBase onClick={() => handleClose('download')}>
            <DownloadIcon />
          </ButtonBase>
        </Tooltip>
      </Grid>

    </Grid>
  );
}