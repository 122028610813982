import { Grid, Paper, Typography, Button, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useDispatch } from "react-redux";
import { updateTariffInfo } from "../../../../../../../features/admin_panel/adminPanelSlice";
import { useStyles } from "./styles";

const BtnData = [
    { label: 'Buy / Add Extra Addition', action: 'buy_addition' },
    { label: 'Cancel Your Package', action: 'cancel_package' },
    { label: 'See Details', action: 'details' }
]

export default function ProfilePackageCard({ packageInfo, userInfo, setAction, setCurrentTariff }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
        <Grid container p={1} component={Paper} className={classes.root} maxWidth={'300px'}>
            <Grid container p={1} pt={0}>
                <Typography variant="h6">
                    Your
                    {userInfo.is_staff && ' Root '}
                    {userInfo.have_access_like_realtor && ' Realtor '}
                    {userInfo.have_access_like_investor && ' Investor '}
                    Package
                </Typography>
            </Grid>
            <Grid container p={1} >
                <Grid item xs={12} className={classes.priceBlock} p={1} pb={0}>
                    <Typography className={classes.packageName}>{packageInfo.title} Package</Typography>
                    <Typography>
                        <span>${packageInfo.price}</span> <span>/{packageInfo.title === 'unlimited' ? <AllInclusiveIcon /> : packageInfo.month_duration} Months</span>
                    </Typography>

                </Grid>

            </Grid>
            <Grid container p={0} spacing={1}>
                {BtnData.map((el, index) => (
                    <Grid item xs={12} key={index}>
                        <Button variant="outlined" size="small" onClick={() => {
                            setAction(el.action)
                            setCurrentTariff(packageInfo)
                            }}>{el.label}</Button>
                    </Grid>
                ))}

                <Grid container p={.5} pl={2}>
                    <FormControl color="info" >
                        <FormControlLabel
                            className={classes.checkbox}
                            value="start"
                            control={<Checkbox
                                onChange={(e) =>
                                    dispatch(
                                        updateTariffInfo(
                                            {
                                                data: { 'auto_payment': !packageInfo.auto_payment },
                                                tariffId: packageInfo.id
                                            }
                                        )
                                    )
                                }
                                checked={packageInfo.auto_payment}
                            />}
                            label="Automatic payment"
                            labelPlacement="end"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    )
}