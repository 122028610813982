import { Grid, Typography, Button, Paper, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EuroSharpIcon from '@mui/icons-material/EuroSharp';
import CertificateDialog from '../Projects/components/dialogs/CertificateDialog';
import { setProjectId, fetchUnconfirmedProjects, selectAllUnconfirmedProjects, 
    selectUnStatusProjects, approveRejectProject } from '../../../features/projects/projectsSlice';
import { useNavigate } from 'react-router-dom';
import InfoDialog from '../components/InfoDialog/InfoDialog';
import { useStyles } from './styles';

export default function Confirmation() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const classes = useStyles()

    const un_project = useSelector(selectAllUnconfirmedProjects)
    const status = useSelector(selectUnStatusProjects)

    const [approveDialog, setApproveDialog] = useState(false)
    const [action, setAction] = useState()

    const [cert_open, setCertOpen] = useState(false)
    const [cert, setCert] = useState([])

    const [currentProjectId, setCurrentProjectId] = useState()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (status === 'idle') {
                setIsLoading(true);
                await dispatch(fetchUnconfirmedProjects());
            }
            setIsLoading(false);
        };
        fetchData();
    }, [])

    const view_cert = (el) => {
        setCert(Object.values(el?.certificates).map((item, index) => [item.certificate, index]))
        setCertOpen(true)
    }

    const selectProject = (id) => {
        dispatch(setProjectId({ project_id: id }))
        navigate(`/admin_panel/project_view/${id}/`)
    }

    const set_Action = (purpose, project_id) => {
        setCurrentProjectId(project_id)
        setAction(purpose)
        setApproveDialog(true)
    }

    const get_discount = (content) => {
        let price = content?.price
        let discount = content?.discount
        let discount_price = (price - (discount * price / 100)).toFixed(0)
        return `${discount_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    const handleUpdate = () => {
        dispatch(approveRejectProject({ projectId: currentProjectId, data: { status: action } })).then(() => {
            setApproveDialog(false)
        })
    }

    if (isLoading) {

        return <Grid container p={1} className={classes.root} alignItems="flex-start" alignContent={'flex-start'} justifyContent='space-between'>
            <Grid container justifyContent={'center'} alignContent={'center'} height={'100%'}>
                <CircularProgress />
            </Grid>
        </Grid>

    }

    return (
        <Grid container p={1} className={classes.root} alignItems="flex-start" alignContent={'flex-start'} justifyContent='space-between'>
            <Grid container p={0} height={'100%'} alignContent={'baseline'}>
                {un_project.map(el => (
                    <Grid container item xs={12} md={6} p={1}
                        className={classes.unconfirmedBlock} alignItems="flex-start" alignSelf={'stretch'} >
                        <Grid container item xs={3} md={2.5} p={0} alignSelf={'stretch'} alignItems="flex-start" onClick={() => selectProject(el.id)}>
                            <Paper sx={{ backgroundImage: `url(${el.preview})` }}></Paper>
                        </Grid>

                        <Grid container item xs={7} md={7.5} p={0} pl={1} alignSelf={'stretch'} onClick={() => selectProject(el.id)}
                            alignItems="flex-start" className={classes.unconfirmedBlockInfo}>
                            <Grid container p={1}>
                                <Grid item xs={12} mb={2}>
                                    <Typography color={'white'}>{el.name}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography color={'grey'} fontSize={10}>{el?.description.slice(0, 165)} {el?.description.length > 165 && '...'}</Typography>
                                </Grid>

                            </Grid>

                            <Grid container p={0} pl={1}>
                                <Grid item>
                                    <Typography color='white' fontSize={20} align="start">
                                        {el?.currency === 'USD' && !el?.flat_for_land_basis && '$'}
                                        {el?.currency === 'EUR' && !el?.flat_for_land_basis && <EuroSharpIcon fontSize='10' />}
                                        {el?.discount ? get_discount(el) : el?.price}
                                        {el?.flat_for_land_basis && !el?.price && 'Flat for Land Basis'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item p={1} xs={2} className={classes.unconfirmedBlockBtn} alignContent={'space-evenly'} alignSelf={'stretch'}>
                            <Grid item xs={12} mb={1}>
                                <Button color='success' variant='contained' onClick={() => set_Action('approve', el.id)}>Approve</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button color='error' variant='contained' onClick={() => set_Action('reject', el.id)}>Reject</Button>
                            </Grid>
                        </Grid>
                        <Button className={classes.unconfirmedBtnCert}
                            disabled={el.certificates?.length > 0 ? false : true} variant='contained'
                            onClick={() => view_cert(el)}> View Certification</Button>
                    </Grid>
                ))}
                {un_project.length === 0 && (
                    <Grid container justifyContent={'center'} alignContent={'center'} height={'100%'}>
                        <Typography className={classes.noContentText}> No Content</Typography>
                    </Grid>
                )}
            </Grid>

            <CertificateDialog open={cert_open} setOpen={setCertOpen} certificates={cert} />
            <InfoDialog isOpen={approveDialog} setIsOpen={setApproveDialog} successFunction={handleUpdate} textType={action} />
        </Grid>
    )
}