import { configureStore } from '@reduxjs/toolkit';
import projectsReducer from '../features/projects/projectsSlice'
import adminPanelReducer from '../features/admin_panel/adminPanelSlice'
import requestsSliceReducer from '../features/requests/requestsSlice'

export default configureStore({
    reducer: {
        projects: projectsReducer,
        adminPanel: adminPanelReducer,
        requests: requestsSliceReducer
    }
})