import { Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from "@mui/material"
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { FilterData } from "../constants";
import { useStyles } from "./styles";


export default function CustomFilter({ setAmountAds, setValidTimePeriod, setHighlightQty, setSupport }) {
    const classes = useStyles()

    return (
        <Grid container justifyContent={'flex-start'} p={2} className={classes.root} >
            <Grid container p={0} spacing={1} alignItems={'center'} justifyContent={'center'} flexWrap={'wrap'}>
                {FilterData.map((el, index) => (
                    <Grid item align='center' key={index} flex={1} minWidth={'250px !important'}>
                        <FormControl fullWidth size='small' >
                            <InputLabel id="demo-simple-select-label">{el?.label}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Valid Time Period"
                                onChange={e => {
                                    el.field === 'how_many_ads' &&
                                        setAmountAds({ label: `Can Add ${e.target.value !== 'undefined' ? e.target.value : ''} Ads (for All Estate Types)`, value: e.target.value, active: e.target.value !== 'undefined' ? true : false })
                                    el.field === 'valid_tome_period' &&
                                        setValidTimePeriod({ label: FilterData[1].options.find(el => el.key === e.target.value)?.value, value: e.target.value, active: e.target.value !== 'undefined' ? true : false })
                                    el.field === 'highlight_qty' &&
                                        setHighlightQty({ label: `Highlight - Promotion (${e.target.value !== 'undefined' ? e.target.value : '0'} Use) Ad`, value: e.target.value, active: e.target.value !== 'undefined' ? true : false })
                                }}
                            >
                                <MenuItem value={'undefined'}>Choose option</MenuItem>
                                {el.options.map((option, index) => (
                                    <MenuItem value={option.key} key={index}>{option.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                ))}

                <Grid item align='center' ml={{md: 1}}>
                    <FormControlLabel
                        control={<Checkbox
                            size="large"
                            icon={<CropSquareRoundedIcon />}
                            checkedIcon={<SquareRoundedIcon />}
                            onChange={(e) => setSupport({ label: 'Customer and Target Market Analysis', value: e.target.checked, active: e.target.checked })}
                        />}
                        label="Support / Fast Contact / Analysis"
                        labelPlacement="end"
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}