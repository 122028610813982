import { Grid, Button } from "@mui/material";
import FilterLocation from "./components/FilterLocation";
import FilterTypesEstate from "./components/FilterTypeEstate";
import FilterPrice from "./components/FilterPrice";
import FilterProject from "./components/FilterProject";
import FilterMeasure from "./components/FilterMeasure";
import { useSelector, useDispatch } from "react-redux";
import { selectFilterEstateCategory, selectProjectFilters, fetchProjects, cleanFilterAllData } from "../../../../../features/projects/projectsSlice";
import RealEstateFilter from "./components/EstateFilters/RealEstate/RealEstateFilter";
import LandTerrainFilter from "./components/EstateFilters/LandTerrain/LandTerrainFilter";
import TourismFilter from "./components/EstateFilters/Tourism/TourismFilter";
import BusinessFilter from "./components/EstateFilters/Business/BusinessFilter";
import { useStyles } from "./styles";

export default function FilterBlog() {
    const category = useSelector((state) => selectFilterEstateCategory(state))
    const filters = useSelector((state) => selectProjectFilters(state))

    const dispatch = useDispatch()
    const classes = useStyles()

    return (
        <Grid container className={classes.root}>
            <Grid container item xs={12} p={0} mt={4} alignItems={'center'} justifyContent={'space-evenly'}>

                <FilterTypesEstate />

                <Grid item alignSelf={'normal'} mb={1} mt={1} className={classes.filterLine}></Grid>

                <FilterLocation />

                <Grid item alignSelf={'normal'} mb={1} mt={1} className={classes.filterLine}></Grid>

                <FilterPrice />

                <Grid item alignSelf={'normal'} mb={1} mt={1} className={classes.filterLine}></Grid>

                <FilterProject />

                <Grid item alignSelf={'normal'} mb={1} mt={1} className={classes.filterLine}></Grid>

                <FilterMeasure />

            </Grid>

            <Grid container item xs={12} alignItems={'center'} p={0}>
                {category === 'real_estate' && <RealEstateFilter />}
                {category === 'land_and_terrain' && <LandTerrainFilter />}
                {category === 'tourism_and_facility' && <TourismFilter />}
                {category === 'business' && <BusinessFilter />}
            </Grid>

            <Grid container item xs={12} spacing={2} justifyContent={'flex-end'}>
                <Grid item>
                    <Button variant="contained" onClick={() => dispatch(fetchProjects({filters:filters}))} className={classes.filterBtn}>Apply</Button>
                </Grid>

                <Grid item>
                    <Button variant="contained"
                        onClick={() => {
                            dispatch(fetchProjects({}))
                            dispatch(cleanFilterAllData())
                        }}
                        className={classes.filterBtn}>Clean filter</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}