import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            background: colors.secondary.DEFAULT,
            borderRadius: '8px',
            '& .MuiSvgIcon-root': {
                color: colors.primary.DEFAULT,
            },
            '& .MuiInputBase-root': {
                '&:hover fieldset':{
                    borderColor: `${colors.primary.DEFAULT}`,
                }
            },
        }
    })
})