import * as React from 'react'
import { Grid } from '@mui/material'
import TreeMenu from './components/TreeMenu'
import { useStyles } from './styles'


export default function SideBar({userInfo, isOpen}) {
    const classes = useStyles()

    return (
        <Grid container item p={1} className={classes.bmMenuWrap} alignItems='start'>
            <Grid container item p={0}>
                <Grid item xs={12} align="center">
                    <TreeMenu userInfo={userInfo} swStatus={isOpen} />
                </Grid>
            </Grid>
        </Grid>
    )
}