import { Box } from '@mui/material';
import ButtonAppBar from './components/ToolBar/ToolBar';
import Header from "./Header/Header";
import WeAre from "./WhoWeAre/WhoWeAre";
import FeaturedProjects from "./Projects/Projects";
import Testimony from "./Testimonials/Testimonials";
import News from "./LatestNews/Latest_news";
import Footer from "./components/Footer/Footer";
import { useState, useEffect } from 'react'
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import "./home.css";
import "./style.css";

const URL = process.env.REACT_APP_API_DEV

export default function HomePage() {
    const [loader, setLoader] = useState(true)
    const [bgImage, setBgImage] = useState('');

    useEffect(() => {
        axios.get(`${URL}/api/slider/`).then((res) => {
            let img = new Image();
            img.src = res.data['photo'];
            img.onload = () => {
              setBgImage(res.data['photo']);
              setLoader(false);
            };
        })

    }, [])

    return (
        loader
            ? <Loader />
            : <Box>
                <ButtonAppBar mode="tran" page='home' />
                <Header url_background={bgImage} />
                <WeAre />
                <FeaturedProjects />
                <Testimony />
                <News />
                <Footer />
            </Box>
    )
}