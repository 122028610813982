import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& .MuiButtonBase-root': {
                color: colors.secondary.DEFAULT,
                background: colors.primary.DEFAULT,
                borderRadius: '5px',
                padding: '2px 2px'
            }
        },

        btnError: {
            background: `${colors.tomato} !important`
        }
        
    })
})