import { Grid, Typography, Select, TextField, MenuItem, FormControl, InputLabel } from '@mui/material'
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { putFilterData, selectProjectFilters } from '../../../../../../features/projects/projectsSlice';
import React from 'react';

const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator="."
                decimalSeparator=','
                valueIsNumericString
            />
        );
    },
);

export default function FilterPrice() {
    const dispatch = useDispatch()
    const filters = useSelector(selectProjectFilters)

    return (
        <Grid container item xs={12} md={2} p={1} mb={2} spacing={1} alignSelf={'center'}>
            <Grid item xs={12} pr={1} mb={1}>
                <Typography color={'white'}>Price Range</Typography>
            </Grid>

            <Grid container pl={1} pr={1} alignItems={'center'}>
                <Grid item xs={12} mb={1}>
                    <TextField fullWidth size='small' InputProps={{ inputComponent: NumericFormatCustom}}
                    onChange={(e) => dispatch(putFilterData({ 'field': 'price__gte', 'data': e.target.value }))}
                    defaultValue={filters?.price__gte}
                    className='tab_text_field' label="from" variant="outlined" />
                </Grid>

                <Grid item xs={12}>
                    <TextField fullWidth size='small' InputProps={{ inputComponent: NumericFormatCustom}}
                    onChange={(e) => dispatch(putFilterData({ 'field': 'price__lte', 'data': e.target.value }))}
                    defaultValue={filters?.price__lte}
                    className='tab_text_field' label="to" variant="outlined" />
                </Grid>
            </Grid>

            <Grid item xs={12} pr={1} mb={1}>
                <Typography color={'white'}>Currency</Typography>
            </Grid>

            <Grid container xs={12}  pl={1} pr={1}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Currency"
                        className='tab_text_field'
                        onChange={(e) => dispatch(putFilterData({ 'field': 'currency', 'data': e.target.value }))}
                        defaultValue={filters?.currency}
                    >
                        <MenuItem value={''}>Choose currency</MenuItem>
                        <MenuItem value={'USD'}>USD</MenuItem>
                        <MenuItem value={'EUR'}>EUR</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}