import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { useSelector, useDispatch } from 'react-redux'
import { selectFilterEstateCategory, putFilterData, selectProjectFilters } from "../../../../../../features/projects/projectsSlice";
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';


export default function FilterProject() {
    const dispatch = useDispatch()
    const filters = useSelector(selectProjectFilters)

    const category = useSelector(selectFilterEstateCategory)

    return (
        <Grid container item xs={12} md={2} p={1} spacing={1} alignSelf={'center'} >
            <Grid container xs={12} pl={1} pr={1}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                        {category !== 'land_and_terrain' ? 'Project State' : 'Zoning State'}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={category !== 'land_and_terrain' ? 'Project State' : 'Zoning State'}
                        className='tab_text_field'
                        defaultValue={filters?.state}
                        onChange={(e) => dispatch(putFilterData({'field': 'state', 'data': e.target.value}))}
                    >
                        <MenuItem value={''}>Choose State</MenuItem>
                        {category !== 'land_and_terrain' && <MenuItem value={'complete_project'}>Completed Project</MenuItem>}
                        {category !== 'land_and_terrain' && <MenuItem value={'under_construction'}>Under Construction</MenuItem>}
                        {category !== 'land_and_terrain' && <MenuItem value={'tenanted_estate'}>Tenanted Estate</MenuItem>}
                        {category !== 'land_and_terrain' && <MenuItem value={'off_plan_property'}>Off Plan Property</MenuItem>}

                        {category === 'land_and_terrain' && <MenuItem value={'improved_land'}>Improved Land</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'vacant_land'}>Vacant Land</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'raw_land'}>Raw Land</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'residential_zone'}>Residential Zone</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'farming_field'}>Farming Field</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'ranch_for_sale'}>Ranch for Sale</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'hobby_garden'}>Hobby Garden</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'warehouse'}>Warehouse</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'woodland_and_forest'}>Woodland & Forest</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'vineyard'}>Vineyard</MenuItem>}
                        {category === 'land_and_terrain' && <MenuItem value={'olive_grove'}>Olive Grove</MenuItem>}

                    </Select>
                </FormControl>
            </Grid>

            <Grid container xs={12} pr={1} pl={1} pt={1}>
                <TextField size="small" fullWidth className='tab_text_field'
                onChange={(e) => dispatch(putFilterData({'field': 'title_deed_status', 'data': e.target.value}))}
                defaultValue={filters?.title_deed_status} label="Title Deed Status" variant="outlined" />
            </Grid>

            <Grid container xs={12} pr={1} pl={1}>
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" >
                        <FormControlLabel
                            className='checkbox_field'
                            control={<Checkbox
                                checkedIcon={<SquareRoundedIcon />}
                                onChange={(e) => dispatch(putFilterData({'field': 'urgent_sale', 'data': e.target.checked}))}
                                checked={filters?.urgent_sale}
                                defaultChecked={filters?.urgent_sale}
                            />}
                            label="Urgent Sale"
                            labelPlacement="right"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}