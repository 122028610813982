import * as React from "react";
import {Grid, Box, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import QuickInquiry from "../../HomePage/components/QuickInquiry/quick_inquiry";
import Footer from "../../HomePage/components/Footer/Footer";
import png1 from "../../../../assets/images/about/our_team/1.png"
import png2 from "../../../../assets/images/about/our_team/2.png"
import png3 from "../../../../assets/images/about/our_team/3.png"
import png4 from "../../../../assets/images/about/our_team/4.png"
import ToolBar from "../../HomePage/components/ToolBar/ToolBar";
import './our_team.css';



export default function OurTeam() {
    return (
        <Box className='team_root'>
            <ToolBar mode="dark" page='about' />
            <Grid container pl={0} pr={0}>
                <Grid container mt={20}>
                    <Grid item xl={12} xs={12}>
                        <Typography variant="h1" className="overview_title">
                            OUR TEAM
                        </Typography>
                    </Grid>
                    <Grid item xl={12} xs={12} align="center" alignContent="center">
                        <Typography variant="body1">
                            <a href="/" className="color_home_overview">HOME</a> <NavigateNextIcon/>
                            <span className="overview_text-overview">OUR TEAM</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container mt={5} p={0}>
                <Grid container item xs={12} p={0} md={6} lg={3} justifyContent="center" className="our_team_wrap_div">
                    <img src={png1} width="305" height="320" className="img_our_team" alt="png1"/>
                    <Grid item xl={12} sm={12}>
                        <Typography variant="body1" align="center" className="name_member">
                            JACOB JONES
                        </Typography>
                    </Grid>

                    <Grid item xl={12} sm={12} >
                        <Typography variant="body1" align="center" className="prof_member">
                            FOUNDER
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} md={6} lg={3} mt={5} justifyContent="center" className="our_team_wrap_div">
                    <img src={png2} width="305" height="320" className="img_our_team" alt="png2"/>
                    <Grid item xl={12} sm={12}>
                        <Typography variant="body1" align="center" className="name_member">
                            COURTNEY HENRY
                        </Typography>
                    </Grid>

                    <Grid item xl={12} sm={12} >
                        <Typography variant="body1" align="center" className="prof_member">
                            ACCOUNT MANAGER
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} md={6} lg={3} mt={5} justifyContent="center" className="our_team_wrap_div">
                    <img src={png3} width="305" height="320" className="img_our_team" alt="png3"/>
                    <Grid item xl={12} sm={12}>
                        <Typography variant="body1" align="center" className="name_member">
                            ELEANOR PENA
                        </Typography>
                    </Grid>

                    <Grid item xl={12} sm={12} >
                        <Typography variant="body1" align="center" className="prof_member">
                            HEAD OF DESIGN
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} md={6} lg={3} mt={5} justifyContent="center" className="our_team_wrap_div">
                    <img src={png4} width="305" height="320" className="img_our_team" alt="png4"/>
                    <Grid item xl={12} sm={12}>
                        <Typography variant="body1" align="center" className="name_member">
                            BESSIE COOPER
                        </Typography>
                    </Grid>

                    <Grid item xl={12} sm={12} >
                        <Typography variant="body1" align="center" className="prof_member">
                            PRODUCT MANAGER
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>


            <Grid container xl={12} sm={12} justifyContent="center">
                <QuickInquiry/>
            </Grid>
            <Footer/>

        </Box>
    )
}