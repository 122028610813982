import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            background: colors.black.DEFAULT,
            borderRadius: '0 0 10px 10px',
            borderRight: `1px solid ${colors.primary.DEFAULT}`,
            borderLeft: `1px solid ${colors.primary.DEFAULT}`,
            borderBottom: `1px solid ${colors.primary.DEFAULT}`,
        },

        filterLine: {
            borderColor: colors.primary.DEFAULT,
            borderLeft: '1px solid'
        },

        filterBtn: {
            color: `${colors.white.DEFAULT} !important`
        }
        
    })
})