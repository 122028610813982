import { Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Autocomplete } from "@mui/material"
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from "react-redux";
import { putFilterData, cleanFilterData, selectProjectFilters } from "../../../../../../../../features/projects/projectsSlice";


const DATA1 = { 'land_condition': 'Land Condition', 'floor_area_ration': 'Floor Area Ration', 'environmental_condition': 'Environmental Condition' }

const DATA2 = {
    'from_owner': 'From Owner', 'flat_for_land_basis': 'Flat for Land', 'swap_statement': 'Swap Method', 'ready_for_construction': 'Ready for Construction'
}

const FEATURES = {
    'general_area_feat': [
        'with_project', 'parcelling', 'corner_zone', 'public_domain'
    ],
    'infrastructure_feat': [
        'natural_gas', 'water_line', 'power_line', 'highway', 'fiber_optic_cable', 'internet', 'telecommunication', 'sewer_system'
    ],
    'surroundings_feat': [
        'downtown', 'grocery', 'shopping_mall', 'pharmacy', 'hospital', 'police_station', 'school', 'university', 'beach', 'sanctuary',
        'fire_department', 'city_hall'
    ],
    'natural_resources_feat': [
        'spring_water', 'historical_place', 'ocean_or_sea', 'seasonal_activities'
    ],
    'landscape_feat': [
        'sea_or_lake', 'city_view', 'mountain', 'forest'
    ]
}

export default function PartA() {
    const matches = useMediaQuery('(max-width:1800px)');
    const filters = useSelector(selectProjectFilters)
    const dispatch = useDispatch()

    const putAutocompleteData = (field, value) => {
        Object.keys(filters?.additional).map(key => {
            let v = key.split('__')
            if (v[0]===field) {
                dispatch(cleanFilterData({'field': key, 'node': 'additional'}))
            }
        })

        for (let el of value) {
            dispatch(putFilterData({'field': `${field}__${el}`, 'data': true, parent_node: 'additional'}))    
        }
    }

    const defaultAutocomplete = (field) => {
        let data = []
        Object.keys(filters?.additional).map(key => {
            let v = key.split('__')
            if (v[0]===field) {
                data.push(v[1])
            }
        })
        return data
    }

    return (
        <Grid container xs={12} p={1} spacing={1} alignItems={'center'} justifyContent={'space-between'}>

            <Grid container xs={12} md={2} p={0} spacing={1} >
                {Object.keys(DATA2).map(key => (
                    <Grid item xs={12} md={12}>
                        <FormControlLabel
                            className='checkbox_field'
                            control={<Checkbox
                            // onChange={(e) =>  dispatch({field: '', data: '', parent_node: 'additional'})}
                            checkedIcon={<SquareRoundedIcon />}
                            checked={filters?.additional[key]}
                            />}
                            label={DATA2[key]}
                            labelPlacement="right"
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid container xs={12} md={3} p={0} spacing={1}>
                {Object.keys(DATA1).map(key => (
                    <Grid container xs={12} p={0} mt={1}>
                        <Grid item xs={12}>
                            <Typography color={'white'}>{DATA1[key]}</Typography>
                        </Grid>
                        <Grid item xs={12} p={0}>
                            <TextField className='tab_text_field'
                            onChange={(e) => dispatch(putFilterData({field: key, data: e.target.value, parent_node: 'additional'}))}
                            defaultValue={filters?.additional[key]}
                            size='small' fullWidth />
                        </Grid>
                    </Grid>
                ))}
                <Grid container xs={12} p={0} mt={2}>
                    <Grid item xs={12} p={0}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Infrastructure</InputLabel>
                            <Select
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Infrastructure"
                                defaultValue={filters?.additional['infrastructure']}
                                onChange={(e) => dispatch(putFilterData({field: 'infrastructure', data: e.target.value, parent_node: 'additional' }))}
                            >
                                <MenuItem value={''}>Choose Infrastructure</MenuItem>
                                <MenuItem value={'fully_equipped'}>Fully Equipped</MenuItem>
                                <MenuItem value={'under_construction'}>Under Construction</MenuItem>
                                <MenuItem value={'unmade'}>Unmade</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>


            <Grid container xs={12} md={6} p={0} justifyContent={'flex-start'} alignSelf={'normal'} spacing={1} >
                {Object.keys(FEATURES).map(key => (
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            limitTags={matches ? 1 : 2}
                            id="multiple-limit-tags"
                            size="small"
                            options={FEATURES[key]}
                            onChange={(e, v) => {putAutocompleteData(key, v)}}
                            defaultValue={() => defaultAutocomplete(key)}
                            getOptionLabel={(option) => option.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1) + ' ')}
                            renderInput={(params) => (
                                <TextField {...params} className='tab_text_field'
                                    label={key.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1) + ' ')} />
                            )}
                            sx={{ width: 'auto' }}
                        />
                    </Grid>
                ))}
            </Grid>

        </Grid>
    )
}