import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: { },
        cardError: {
            display: 'flex',
            position: 'absolute',
            background: colors.black.DEFAULT,
            opacity: 0,
            zIndex: 2,
            padding: '5px',
            animation: '$ani 1.5s forwards',
            width: 'inherit',
            '& p': {
                margin: 'auto 5px',
                color: colors.primary.DEFAULT
            },
        },
        toRight: {
            right: 0
        },
        '@keyframes ani': {
            '0%': { opacity: 0 },
            '100%': { opacity: 1 }
        }
    })
})