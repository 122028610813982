import { Grid, Typography, TextField, FormControlLabel, Checkbox, Autocomplete } from "@mui/material"
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from "react-redux";
import { putFilterData, selectProjectFilters, cleanFilterData } from "../../../../../../../../features/projects/projectsSlice";

const DATA1 = {
    'Number of Rooms': ['number_of_rooms__gte', 'number_of_rooms__lte'],
    'Number of Beds': ['number_of_beds__gte', 'number_of_beds__lte'],
    'Number of Floors': ['number_of_floors__gte', 'number_of_floors__lte']
}

const DATA2 = {
    'airstrip': 'Airstrip'
}

const FEATURES = {
    'hotel_rules_feat': [
        'pet_friendly', 'smoke_free_rooms', 'smoke_free_rooms', 'adults_only', 'thermal_features'
    ],
    'infrastructure_feat': [
        'natural_gas', 'water_line', 'power_line', 'highway', 'fiber_optic_cable', 'internet', 'telecommunication', 'sewer_system'
    ],
    'surroundings_feat': [
        'downtown', 'grocery', 'shopping_mall', 'pharmacy', 'hospital', 'police_station', 'school', 'university', 'beach', 
        'sanctuary', 'fire_department', 'city_hall'
    ],
    'natural_resources_feat': [
        'spring_water', 'historical_place', 'ocean_or_sea', 'seasonal_activities'
    ],
    'landscape_feat': [
        'sea_or_lake', 'city_view', 'mountain', 'forest'
    ],
    'room_feat': [
        'standard', 'suit', 'deluxe', 'apart'
    ]

}

export default function PartA() {
    const matches = useMediaQuery('(max-width:1800px)');
    const dispatch = useDispatch()
    const filters = useSelector(selectProjectFilters)

    const putAutocompleteData = (field, value) => {
        Object.keys(filters?.additional).map(key => {
            let v = key.split('__')
            if (v[0] === field) {
                dispatch(cleanFilterData({ 'field': key, 'node': 'additional' }))
            }
        })

        for (let el of value) {
            dispatch(putFilterData({ 'field': `${field}__${el}`, 'data': true, parent_node: 'additional' }))
        }
    }

    const defaultAutocomplete = (field) => {
        let data = []
        Object.keys(filters?.additional).map(key => {
            let v = key.split('__')
            if (v[0] === field) {
                data.push(v[1])
            }
        })
        return data
    }

    return (
        <Grid container xs={12} md={5.5} p={1} spacing={1}>

            <Grid container p={0} mb={1} mt={1} >
                {Object.keys(DATA1).map(key => (
                    <Grid container xs={12} md={6} p={0} mt={1}>
                        <Grid item xs={12}>
                            <Typography color={'white'}>{key}</Typography>
                        </Grid>
                        {DATA1[key].map((el, index) => (
                            <Grid item xs={12} md={6} pr={1}>
                                <TextField className='tab_text_field' size='small' type="number"
                                    fullWidth
                                    placeholder={index === 0 ? 'from' : 'to'}
                                    defaultValue={filters?.additional?.[el]}
                                    onChange={(e) => dispatch(putFilterData({ 'field': el, 'data': e.target.value, parent_node: 'additional' }))}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Grid>

            <Grid container p={0} justifyContent={'flex-start'}>
                {Object.keys(DATA2).map(key => (
                    <Grid item xs={12} md={4}>
                        <FormControlLabel
                            className='checkbox_field'
                            control={<Checkbox
                            onChange={(e) => dispatch(putFilterData({field: key, data: e.target.checked, parent_node: 'additional'}))}
                            checkedIcon={<SquareRoundedIcon />}
                            checked={filters?.additional?.[key]}
                            />}
                            label={DATA2[key]}
                            labelPlacement="right"
                        />
                    </Grid>
                ))}
            </Grid>

            <Grid container p={0} justifyContent={'flex-start'} mt={1}>
                {Object.keys(FEATURES).map(key => (
                    <Grid item xs={12} md={6} p={0} pr={1} pt={1}>
                        <Autocomplete
                            multiple
                            limitTags={matches ? 1 : 2}
                            id="multiple-limit-tags"
                            size="small"
                            options={FEATURES[key]}
                            onChange={(e, v) => { putAutocompleteData(key, v) }}
                            defaultValue={() => defaultAutocomplete(key)}
                            getOptionLabel={(option) => option.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1) + ' ')}
                            renderInput={(params) => (
                                <TextField {...params} className='tab_text_field'
                                    label={key.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1) + ' ')} />
                            )}
                            sx={{ width: 'auto' }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>

    )
}