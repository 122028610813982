import { Grid, Typography, TextField, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import FormError from "../../../../components/FormError/FormError"
import { useStyles } from "../styles"
import { useDispatch } from "react-redux"
import { updateCompanyInfo } from "../../../../../../features/admin_panel/adminPanelSlice"
import { SnackbarProvider, enqueueSnackbar } from "notistack"
import React from "react"

const DATA = [
    { label: 'Change Contact Number', name: 'contact_number', type: 'text' },
    {
        label: 'Change Contact Email', name: 'contact_email', type: 'email', validation: {
            pattern: {
                message: 'Please enter valid email!',
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            }
        }
    }
]

export default function ConfirmedInfo({ userInfo }) {
    const { register, handleSubmit, formState: { dirtyFields, errors } } = useForm({ mode: 'onChange' })
    const dispatch = useDispatch()
    const classes = useStyles()

    const handleSave = (data) => {
        dispatch(updateCompanyInfo({ 'data': data, 'companyId': userInfo?.company?.pk }))
            .then(() => enqueueSnackbar('Information has been updated', { variant: 'success', style: { background: '#BD9A68' } }))
    }

    return (
        <Grid container p={0} className={classes.root}>
            <form onSubmit={handleSubmit(handleSave)} >
                <Grid container className={classes.titleBlock}>
                    <Typography variant="h4" >Change Your Confirmed Information</Typography>
                </Grid>
                <Grid container spacing={1} mt={2}>
                    {DATA.map((el, index) => (
                        <Grid container item xs={12} p={0} alignItems={'center'} key={index} >
                            <Grid item xs={6} alignContent={'center'} >
                                <Typography >{el.label}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <TextField size="small" variant="outlined"
                                    defaultValue={userInfo?.company?.[el.name]}
                                    {...register(el.name, {
                                        ...el.validation
                                    })}
                                />
                                <FormError error={errors[el.name]} errors={errors} name={el.name} />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid container mt={2}>
                    <Button disabled={Object.keys(dirtyFields).length > 0 ? false : true} variant="contained" type="submit" sx={{ color: 'white' }}>
                        Set Changes
                    </Button>
                </Grid>
            </form>
            <SnackbarProvider autoHideDuration={20000} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} />
        </Grid>
    )
}