import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import makeApiRequest from "../../api/api";

const initialState = {
    requests: [],
}

export const fetchRequests = 
    createAsyncThunk('requests/fetchRequests', async() => {
        const response =  await makeApiRequest(`/api/requests/`, 'GET', {})
        return response.data
})

export const updateRequests = 
    createAsyncThunk('requests/updateRequests', async({data, requestId}) => {
    const response =  await makeApiRequest(`/api/requests/${requestId}/`, 'PATCH', data)
    return response.data
})

export const deleteRequests = 
    createAsyncThunk('requests/deleteRequests', async({requestId}) => {
        const response =  await makeApiRequest(`/api/requests/${requestId}/`, 'DELETE')
        return response.data
    })

const requestsSlice = createSlice({
    name: 'adminPanel',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
        .addCase(fetchRequests.fulfilled, (state, action) => {
            state.requests = action.payload
        })

        .addCase(updateRequests.fulfilled, (state, action) => {
            let r_id = state.requests.findIndex((request) => request.id === action.payload['id'])
            state.requests[r_id] = action.payload
        })

        .addCase(deleteRequests.fulfilled, (state, action) => {
            let r_id = state.requests.findIndex((req) => req.id === action.payload['id'])
            state.requests.splice(r_id, 1)
        })
    }
})

export default requestsSlice.reducer

export const allRequests = (state) => state.requests.requests