import { useEffect, useState } from "react";
import { Grid, Link } from "@mui/material";
import logo from "../../../assets/images/basic/logo1.png"
import backgroundImage from '../../../assets/images/registration/background.jpg'
import "../HomePage/style.css";
import "./registration.css";
import SignUp from "./SignUp/sign_up";
import SignIn from "./SignIn/sign_in";
import RecoveryPassword from "./RecoveryPassword/RecoveryPassword";
import RecoveryFromEmail from "./RecoveryFromEmail/RecoveryFromEmail";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";


export default function RegistrationPage({ purpose }) {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const [bgImage, setBgImage] = useState('');

    useEffect(() => {
        let img = new Image();
        img.src = backgroundImage
        img.onload = () => {
            setBgImage(backgroundImage);
            setLoader(false);
        };
    }, [])

    const move_to_home = () => {
        navigate('/')
    }

    return (
        loader
            ? <Loader />
            :
        <Grid container className="registration_wrap" alignItems="baseline" sx={{ 'alignContent': 'center', backgroundImage: `url(${bgImage})` }} justifyContent="center">
            <Grid container item xs={12} mt={8} p={0} justifyContent={'center'}>
                <Link href='/' onClick={move_to_home}>
                    <img src={logo} width='250px' alt="logo" />
                </Link>
            </Grid>
            <Grid container item xs={12} md={8} lg={6} xl={4} p={0} mb={5} justifyContent="center" mr={0}
                className="registr_contain_for_shadow">

                <Grid container item justifyContent='space-between' mt={2} >

                    {purpose === 'sign_up' && <SignUp />}
                    {purpose === 'sign_in' && <SignIn />}
                    {purpose === 'recovery_password' && <RecoveryPassword />}
                    {purpose === 'recovery_password_from_email' && <RecoveryFromEmail />}

                </Grid>
            </Grid>

        </Grid>
    )
}