import * as React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardContent, CardMedia, Grid, Typography, Box, Button } from '@mui/material'
import { useEffect } from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EuroIcon from "@mui/icons-material/Euro";
import CurrencyLiraIcon from "@mui/icons-material/CurrencyLira";
import axios from 'axios'
import { Cookies } from 'react-cookie'
import { useState } from 'react'
import SearchPanel from '../../../AdminPanel/Projects/components/SearchPanel/SearchPanel';
import FilterBlog from '../../../AdminPanel/Projects/components/FilterBlog/FilterBlog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const ProjectExcerpt = ({ project }) => {
    return (
        <Grid container item xs={12}  sm={6} lg={3} mt={10} p={0} alignItems='center' justifyContent='center'>
            <Link to={`/project_info/${project.pk}`}>
                <Grid item style={{ 'cursor': 'pointer' }} m={1}>
                    <Card sx={{ maxWidth: 340, position: 'relative' }} >
                        <CardMedia 
                            className="card_media_block"
                            sx={{ height: 300 }}
                            component={'img'}
                            src={project.image_preview.image}
                        />
                        <Box className='logo' >
                            <img src={project.company_logo} alt="" />
                        </Box>
                        <CardContent>
                            <Grid container p={0} alignItems='center' justifyContent='center'>

                                <Grid container item xs={12} p={0} alignItems='center' >
                                    {project.status === 'confirmed' &&
                                        <Grid item xs={1}>
                                            <CheckCircleIcon color='success' sx={{ width: '18px' }} />
                                        </Grid>
                                    }
                                    <Grid item xs={8}>
                                        <Typography variant="body1">

                                            {project.name.slice(0, 25)} {project.name.length > 25 && '...'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} align="center" >
                                        <Button className='btn_type_estate' sx={{margin: '0 !important'}}  variant='outlined'>
                                            {project.type_estate}
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} mt={1}>
                                    <Typography variant="body1" className="card_property">
                                        {project.description.slice(0, 70)}...
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} p={0} align='start' mt={1}
                                    alignItems='center'>
                                    <Grid item>
                                        {project.currency === 'USD' &&
                                            <AttachMoneyIcon sx={{
                                                'font-size': '25px !important',
                                                'padding-top': '3px'
                                            }} />}
                                        {project.currency === 'EUR' &&
                                            <EuroIcon sx={{
                                                'font-size': '20px !important',
                                                'padding-top': '3px'
                                            }} />}
                                        {project.currency === 'TRY' &&
                                            <CurrencyLiraIcon sx={{
                                                'font-size': '15px !important',
                                                'padding-top': '3px'
                                            }} />}
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body"
                                            sx={{ 'font-size': '20px !important' }}>
                                            {project.price_from}-{project.price_up_to}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Link>
        </Grid>
    )
}


export default function ListProjects() {
    const cookie = new Cookies()
    const [projects, setProjects] = React.useState(null)

    const init_filter_data = {
        apartment: true, hotel: true, land: true,
        price_from: 0, price_to: 9999999999999,
        created_status: true, confirmed_status: true,
        country: 'default', region: 'default', city: 'default'
    }

    const [estate, setEstate] = useState(init_filter_data)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState(false)
    const [noAuth, setNoAuth] = useState(true)


    function handleClearFilter() {
        setEstate(init_filter_data)
    }

    useEffect(() => {
        async function fetchData() {

            let filter_data = {
                'type_estate': [
                    estate.apartment && 'apartment',
                    estate.hotel && 'hotel',
                    estate.land && 'land',
                ],
                'status': [
                    estate.created_status && 'created',
                    estate.confirmed_status && 'confirmed',
                ]
            }
            let data

            await axios.get(`${process.env.REACT_APP_API_DEV}/api/projects/`, {
                headers: {
                    Authorization: `Bearer ${cookie.get('access_token')}`,
                    'X-CSRFToken': cookie.get('csrftoken'),
                }
            }).then((res) => {
                data = res.data
                setNoAuth(!noAuth)
            }).catch(async (err) => {
                await axios.get(`${process.env.REACT_APP_API_DEV}/api/projects/no_auth_projects/`).then((res) => {
                    data = res.data
                })
            })

            let new_pr_list = data.filter((project) => filter_data.type_estate.includes(project.type_estate)
                && (project.price_from >= estate.price_from && project.price_up_to <= estate.price_to)
                && (filter_data.status.includes(project.status))
                && (estate.country === 'default' ? project.country : project.country === estate.country)
                && (estate.region === 'default' ? project.region : project.region === estate.region)
                && (estate.city === 'default' ? project.city : project.city === estate.city)
            )
            if (search !== '') {
                new_pr_list = new_pr_list.filter((project) => project.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
            }
            setProjects(new_pr_list)

        }
        fetchData()
    }, [estate, search])


    let content = projects && projects.map((project) => (
        <ProjectExcerpt key={project.pk} project={project} />
    ))

    return (
        <React.Fragment>
            <Grid container p={1} alignItems="flex-start" justifyContent='space-between' >

                <SearchPanel filter={filter} filterSet={setFilter} setSearch={setSearch} noAuth={noAuth} />

                <FilterBlog estate={estate}
                    setEstate={setEstate}
                    filter={filter}
                    handleClearFilter={handleClearFilter}
                    noAuth={noAuth}
                />

                {content}
            </Grid>
        </React.Fragment>
    )
}