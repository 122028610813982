import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            borderRadius: '10px !important',
            '& h6': {
                color: colors.gray[50],
                textDecoration: 'underline',
                textUnderlinePosition: 'under'      
            },
            '& .MuiSvgIcon-root': {
                verticalAlign: 'middle'
            },
            '& .MuiButtonBase-root': {
                color: colors.white.DEFAULT,
                borderRadius: '8px'
            },
            '& .MuiFormControlLabel-root': {
                '& span': {
                    color: `${colors.primary.DEFAULT} !important`
                }
            }
        },
        priceBlock: {
            border: `2px solid ${colors.primary.DEFAULT}`,
            borderRadius: '10px !important',
            '& span:nth-child(1)': {
                color: colors.primary.DEFAULT,
                fontWeight: 600,
                fontSize: '30px !important'
            },
            '& span:nth-child(2)': {
                color: colors.primary.DEFAULT,
                verticalAlign: 'text-bottom',
                fontSize: '16px !important'
            },
        
        },
        packageName: {
            fontSize: '22px !important',
            textTransform: 'capitalize !important',
        },
        checkbox: {
            color: `${colors.primary.DEFAULT} !important`
        }
        
    })
})