import { Grid, FormControl, InputLabel, Select, MenuItem, Autocomplete, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { putFilterData, selectProjectFilters } from "../../../../../../../../features/projects/projectsSlice"

const CONSTR_OPTIONS = ['Metal', 'Cement', 'Concrete', 'Structure Steel', 'Carbon Fiber', 'Reinforced Steel', 'Steel Wire',
    'Bitumen', 'Adobe', 'Plastic Materials', 'Bamboo', 'Briquette', 'Tile', 'Soil - Sand - Clay', 'Aggregate - Gravel - Stone',
    'Brick', 'Polymer', 'Pine', 'Wood - Plank', 'Ceramic', 'Plastic', 'Polymer', 'Aluminum']


const DATA1 = {
    'building_age': {
        'new_build': 'New Build', 'months_6_12': '6-12 months', 'years_1_5': '1-5 Years', 'years_5_10': '5-10 Years',
        'years_10_15': '10-15 Years', 'years_15_20': '15-20 Years', 'years_20_and_more': '20+'

    },
    'infrastructure': {
        'fully_equipped': 'Fully Equipped', 'under_construction': 'Under Construction', 'unmade': 'Unmade'
    },
    'ventilation_method': {
        'air_extraction_components': 'air extraction components', 'natural_ventilation': 'natural ventilation',
        'air_conditioner': 'air conditioner', 'auxiliary_ventilation': 'auxiliary ventilation',
        'range_hood_method': 'range hood method'
    },
    'heating_method': {
        'central_heating': 'central heating', 'floor_heating': 'floor heating', 'air_conditioned': 'air conditioned',
        'gas_or_water_heater': 'gas or water heater', 'solar_system': 'solar system'
    }
}

const DATA2 = {
    'Number of Rooms': ['number_of_rooms__gte', 'number_of_rooms__lte'],
    'Number of Workspace': ['number_of_workspace__gte', 'number_of_workspace__lte'],
    'Number of Store/Shop': ['number_of_store__gte', 'number_of_store__lte'],
    'Number of Floors': ['number_of_floors__gte', 'number_of_floors__lte']
}

export default function PartB() {
    const dispatch = useDispatch()
    const filters = useSelector(selectProjectFilters)

    return (
        <Grid container xs={12} md={6} p={0}>
            <Grid container xs={12} p={0}>
                {Object.keys(DATA1).map(key => (
                    <Grid item xs={12} md={4} pr={1} pt={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">{key.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1) + ' ')}</InputLabel>
                            <Select
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={key.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1) + ' ')}
                                onChange={(e) => dispatch(putFilterData({ field: key, data: e.target.value, parent_node: 'additional' }))}
                                defaultValue={filters?.additional?.[key]}
                            >
                                <MenuItem value={''}>Choose {key.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1) + ' ')}</MenuItem>
                                {Object.keys(DATA1[key]).map(el => (
                                    <MenuItem value={el}>{DATA1[key][el]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                ))}
            </Grid>

            <Grid container xs={12} p={0}>
                <Grid item xs={12} pt={2}>
                <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    size="small"
                    options={CONSTR_OPTIONS}
                    // onChange={(e, v) => {setValue('details.construction_materials', v, {shouldDirty: true})}}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField {...params} className='tab_text_field' label="Construction Materials" />
                    )}
                    sx={{ width: 'auto' }}
                />
                </Grid>
            </Grid>

            {Object.keys(DATA2).map(key => (
                <Grid container xs={12} md={6} p={0} mt={1}>
                    <Grid item xs={12}>
                        <Typography color={'white'}>{key}</Typography>
                    </Grid>
                    {DATA2[key].map((el, index) => (
                        <Grid item xs={12} md={6} pr={1}>
                            <TextField className='tab_text_field' size='small' type="number"
                                fullWidth
                                placeholder={index === 0 ? 'from' : 'to'}
                                defaultValue={filters?.additional?.[el]}
                                onChange={(e) => dispatch(putFilterData({ field: el, data: e.target.value, parent_node: 'additional' }))}
                            />
                        </Grid>
                    ))}
                </Grid>
            ))}

        </Grid>

    )
}