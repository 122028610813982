import { Grid, Card, CardContent, Typography, Table, TableBody, TableRow, TableCell, IconButton } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import { useStyles } from "../styles"


export default function PackageCard({ properties, price, validTimePeriod, description = null, label = null, duration = null, logo = null, maxWidth = null }) {
    const classes = useStyles()

    return (
        <Grid item xs={12} sx={{ height: '100%', maxHeight: '90%' }} align={'center'}>
            <Card className={classes.tariffCard} component={Grid} height={'100%'} maxWidth={maxWidth ? maxWidth : '340px'} minWidth={'300px'}>
                <CardContent >
                    <Grid container p={0} justifyContent='center'>

                        {logo &&
                            <Grid item xs={12} align='center' pt={0}>
                                <IconButton aria-label="logo" size="large" >
                                    {logo}
                                </IconButton>
                            </Grid>
                        }

                        <Grid item xs={12} align='center'>
                            <Typography variant='h1' className={classes.tariffTitle}>
                                {label ? label : 'Custom Plan'}
                            </Typography>
                        </Grid>

                        <Grid container justifyContent={'center'} mt={2}>
                            <Grid item xs={4} className={classes.cardLine}></Grid>
                        </Grid>

                        <Grid item xs={12} mt={2} align='center'>
                            <Typography variant='h3' className={classes.tariffPrice}>
                                ${price >= 0 ? price : '-'} <span > / {duration ? `${duration} Months` : 'Renewable'}</span>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} mt={3} align='center'>
                            <Typography variant='caption' className={classes.tariffDescription}>
                                {description
                                    ? description
                                    : `This package and its features are valid for ${validTimePeriod?.value !== 'undefined' ? validTimePeriod?.label : ''}`
                                }
                            </Typography>
                        </Grid>

                        <Grid item xs={12} mt={4} align='center'>
                            <Table size='small' >
                                <TableBody>
                                    {properties.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ width: 0 }}>{item.active && <CheckIcon />}</TableCell>
                                            <TableCell className={!item.active ? classes.notActiveRow : ''}>{item?.label}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}