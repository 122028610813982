import {
    Grid, Typography, TextField, FormControlLabel, Checkbox, Autocomplete, MenuItem, FormControl, InputLabel,
    Select
} from '@mui/material'
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { putFilterData, selectProjectFilters, cleanFilterData } from '../../../../../../../../features/projects/projectsSlice';

const DATA1 = {
    'building_type': 'Building Type', 'business_type': 'Business Type'
}

const DATA2 = {
    'furnished': 'Furnished'
}

const DATA2_1 = {
    'parking': 'Parking Lot', 'elevator': 'Elevator', 'security': 'Security', 'garden_or_private_area': 'Garden & Private Area'
}

const FEATURES = {
    'building_format_feat': [
        'for_factory', 'for_office', 'for_trade', 'station_or_warehouse'
    ],
    'infrastructure_feat': [
        'natural_gas', 'water_line', 'power_line', 'highway', 'fiber_optic_cable', 'internet', 'telecommunication', 'sewer_system'
    ],
    'surroundings_feat': [
        'downtown', 'grocery', 'shopping_mall', 'pharmacy', 'hospital', 'police_station', 'school', 'university', 'beach', 'sanctuary',
        'fire_department', 'city_hall'
    ],
    'transporting_feat': [
        'subway', 'bus_station', 'railway', 'airport'
    ],
    'external_feat_structure': [
        'generator', 'power_plant', 'solar_energy', 'agriculture_zone'
    ],

    'landscape_feat': [
        'sea_or_lake', 'city_view', 'mountain', 'forest'
    ]
}

export default function PartA() {
    const matches = useMediaQuery('(max-width:1800px)');
    const dispatch = useDispatch()
    const filters = useSelector(selectProjectFilters)

    const putAutocompleteData = (field, value) => {
        Object.keys(filters?.additional).forEach(key => {
            let v = key.split('__')
            if (v[0]===field) {
                dispatch(cleanFilterData({'field': key, 'node': 'additional'}))
            }
        })

        for (let el of value) {
            dispatch(putFilterData({'field': `${field}__${el}`, 'data': true, parent_node: 'additional'}))    
        }
    }

    const defaultAutocomplete = (field) => {
        let data = []
        Object.keys(filters?.additional).forEach(key => {
            let v = key.split('__')
            if (v[0]===field) {
                data.push(v[1])
            }
        })
        return data
    }

    return (
        <Grid container xs={12} md={5.5} p={0} >
            <Grid container p={0} mb={1}>
                {Object.keys(DATA1).map(key => (
                    <Grid container xs={12} md={4} p={0} >
                        <Grid item xs={12}>
                            <Typography color={'white'}>{DATA1[key]}</Typography>
                        </Grid>
                        <Grid item xs={12} p={0}>
                            <TextField 
                            onChange={e => dispatch(putFilterData({field: key, data: e.target.value, parent_node: 'additional'}))}
                            defaultValue={filters?.additional?.[key]}
                            className='tab_text_field' size='small' />
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            <Grid container p={0} justifyContent={'flex-start'}>
                {Object.keys(DATA2_1).map(key => (
                    <Grid item xs={12} md={4} pr={1} pt={1}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">{DATA2_1[key]}</InputLabel>
                            <Select
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={DATA2_1[key]}
                                defaultValue={filters?.additional?.[key]}
                                onChange={(e) => dispatch(putFilterData({ 'field': key, 'data': e.target.value, parent_node: 'additional' }))}
                            >
                                <MenuItem value={''}>Choose {DATA2_1[key]}</MenuItem>
                                <MenuItem value={'yes'}>yes</MenuItem>
                                <MenuItem value={'no'}>no</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                ))}
            </Grid>

            <Grid container p={0} justifyContent={'flex-start'} mt={1}>
                {Object.keys(DATA2).map(key => (
                    <Grid item xs={12} md={4}>
                        <FormControlLabel
                            className='checkbox_field'
                            control={<Checkbox
                            onChange={(e) => dispatch(putFilterData({field: key, data: e.target.checked, parent_node: 'additional'}))}
                            checkedIcon={<SquareRoundedIcon />}
                            checked={filters?.additional?.[key]}
                            />}
                            label={DATA2[key]}
                            labelPlacement="right"
                        />
                    </Grid>
                ))}
            </Grid>

            <Grid container p={0}>
                {Object.keys(FEATURES).map(key => (
                    <Grid item xs={12} md={6} pt={2} pr={1}>
                        <Autocomplete
                            multiple
                            limitTags={matches ? 1 : 2}
                            id="multiple-limit-tags"
                            size="small"
                            options={FEATURES[key]}
                            onChange={(e, v) => {putAutocompleteData(key, v)}}
                            defaultValue={() => defaultAutocomplete(key)}
                            getOptionLabel={(option) => option.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1) + ' ')}
                            renderInput={(params) => (
                                <TextField {...params} className='tab_text_field'
                                    label={key.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1) + ' ')} />
                            )}
                            sx={{ width: 'auto' }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>

    )
}