import * as React from "react";
import {
    Grid, TextField, Typography, Button, FormControl, InputLabel, OutlinedInput,
    InputAdornment, IconButton, MenuItem, Select,
    Dialog, DialogTitle, DialogContent, Slide
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm, Controller } from 'react-hook-form'
import FormError from '../../../AdminPanel/components/FormError/FormError'
import makeApiRequest from "../../../../api/api";
import '../registration.css'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function SignIn() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [showPassword, setShowPassword] = React.useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors } } = useForm({ mode: 'onChange' })
    const handleSave = async (form_data) => {

        let data = {
            first_name: form_data.first_name,
            last_name: form_data.last_name,
            password: form_data.password,
            email: form_data.email,
            have_access_like_realtor: form_data.who_am_i === "realtor" ? true : false,
            have_access_like_investor: form_data.who_am_i === "investor" ? true : false,
        }

        await makeApiRequest(`/auth/register/`, 'POST', data, true).then(() => {
            window.location.href = '/success_registration'
        })
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);


    return (
        <form onSubmit={handleSubmit(handleSave)}>

            <Grid item xs={12} mb={1}>
                <Typography variant="body1" align='center' sx={{ 'fontSize': '25px', 'fontWeight': '600' }}>
                    Sign Up
                </Typography>
            </Grid>

            <Grid item xs={12} mb={2}>
                <Typography variant="body1" align='center'
                    sx={{ 'fontSize': '13px', 'fontWeight': '400', 'color': '#212121' }}>
                    Enter your credentials to sing up
                </Typography>
            </Grid>

            <Grid item xs={5.7}>
                <TextField
                    size="small"
                    sx={{ 'background': 'white' }}
                    fullWidth
                    {...register('first_name', { required: 'First Name is required field' })}
                    id="filled-required"
                    label="First Name"
                    variant="outlined"
                />
                <FormError error={errors?.first_name} />
            </Grid>

            <Grid item xs={5.7}>
                <TextField
                    size="small"
                    sx={{ 'background': 'white' }}
                    fullWidth
                    {...register('last_name', { required: 'Last Name is required field' })}
                    id="filled-required"
                    label="Last Name"
                    variant="outlined"
                />
                <FormError error={errors?.last_name} />
            </Grid>

            <Grid item xs={12} mt={3}>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Who am I</InputLabel>
                    <Controller
                        name='who_am_i'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select {...field} 
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Measure"
                                sx={{ 'background': 'white' }}
                            >
                                <MenuItem value={'investor'}>Investor</MenuItem>
                                <MenuItem value={'realtor'}>Realtor</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormError error={errors?.who_am_i} />
            </Grid>

            <Grid item xs={12} mt={3}>
                <TextField
                    size="small"
                    sx={{ 'background': 'white' }}
                    fullWidth
                    {...register('email', {
                        required: 'Email is required field!',
                        pattern: {
                            message: 'Please enter valid email!',
                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                        }
                    })}
                    id="filled-required"
                    label="Email address"
                    variant="outlined"
                />
                <FormError error={errors?.email} />
            </Grid>

            <Grid item xs={12} mt={3}>
                <FormControl sx={{  width: '100%' }} variant="outlined" size="small">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        
                        id="outlined-adornment-password"
                        sx={{ 'background': 'white' }}
                        type={showPassword ? 'text' : 'password'}
                        
                        {...register('password', {
                            required: 'Password is required field!',
                            pattern: {
                                value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*_)(?!.* ).{8,16}$/,
                                message: 'The password must contain upper and lower case Latin letters, numbers and special character!'
                            },
                            minLength: {
                                value: 8,
                                message: 'The password must consist of at least 8 characters!'
                            }
                        })}
                        endAdornment={
                            <InputAdornment position="end" >
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
                <FormError error={errors?.password} />
            </Grid>

            <Grid container item p={0} xs={12} mt={2} alignItems="center">
                <Grid item xs={1} align='start'>
                    <Controller
                        name="switch"
                        control={control}
                        rules={{ required: 'Field is required!' }}
                        render={({ field: { onChange, value } }) => (
                            <input
                                type="checkbox"
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                            />
                        )}
                    />
                    <FormError error={errors?.switch} />
                </Grid>
                <Grid item xs={11} sm={8} onClick={handleClickOpen}>
                    <Typography>Agree with <a className='link_conditions'>Terms & Conditions</a></Typography>
                </Grid>
                <Grid item xs={12} pl={5} onClick={handleClickOpen}>
                    <Typography>Click to Read <a className='link_conditions'>Clarification & PDPL Text</a></Typography>
                </Grid>
            </Grid>


            <Grid item xs={12} mt={2} align="center">
                <Button type="Submit" className="btn_sign_up">Sing Up</Button>
            </Grid>

            <Grid item xs={12} mt={2}>
                <div className="line"></div>
            </Grid>

            <Grid item xs={12} mt={2} mb={2}>
                <Typography variant="body1" align='center'
                    sx={{ 'fontSize': '15px !important', 'fontWeight': '400' }}>
                    <a href="/sign_in/" className='link_conditions'>Already have an account?</a>
                </Typography>
            </Grid>

            <React.Fragment>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Clarification & PDPL Text"}</DialogTitle>
                    <DialogContent>

                        
                        <span className="dialog_level1">Personal Data Processing Policy</span>
                        <br />
                        <br />
                        <span className="dialog_level1">Purpose and scope</span>
                        <br />
                        <br />

This document describes the policy of processing, accumulation and storage of documents containing information related to personal data.
                        <br />
                        <br />
The purpose of formulation of this Policy is the provision of protection of human and civil rights and freedoms while processing their personal data, including the right to personal and family privacy, as well as establishment of responsibility of officials, having access to personal data, for the noncompliance with the regulations related to personal data processing and protection.
                        <br />
                        <br />  
                        <span className="dialog_level1">Abbreviations, basic definitions and terms</span>
                        <br />
                        <br />
Personal data — any information related to directly or indirectly specified individual (personal data subject);
                        <br />
                        <br />
Personal data authorized by the personal data subject for distribution — personal data, access to which by the public is provided by the subject of personal data by giving consent to the processing of personal data, authorized by the personal data subject for distribution in the manner prescribed by Federal Law;
                        <br />
                        <br />
Operator — state authority, municipal authority, legal entity or individual, who independently or jointly with other persons arranges and/or performs personal data processing, as well as defines the purposes of personal data processing, the volume of personal data subject to processing and actions performed towards personal data;
                        <br />
                        <br />
Personal data processing — any action (operation) or a series of actions (operations) performed towards personal data with or without use of the software, including collection, recording, systematization, accumulation, storage, update and alteration, extraction, use, transfer (distribution, presentation, providing access), depersonalization, blocking, deleting and destruction of personal data;
                        <br />
                        <br />
Automated personal data processing — personal data processing via PC software;
                        <br />
                        <br />
Personal data presentation — actions aimed at personal data disclosure to a certain person or a certain group of persons;
                        <br />
                        <br />
Personal data blocking — temporary interruption of personal data processing (except where processing is required for personal data update or alteration);
                        <br />
                        <br />
Personal data destruction — actions making it impossible to restore personal data volume in the personal data information system and/or resulting in the elimination of tangible media;
                        <br />
                        <br />
Personal data depersonalization — actions making it impossible to identify personal data as related to a certain data subject without involving additional information;
                        <br />
                        <br />
Personal data information system — a set of personal data included into personal data databases, as well as the software and tools used for their processing;
                        <br />
                        <br />
Trans-border transfer of personal data — transfer of personal data to a foreign territory, foreign government body, to a foreign individual or a foreign legal entity located in a foreign territory;
                        <br />
                        <br />
Information — messages, data independently of their particular representation.
                        <br />
                        <br />
                        <span className="dialog_level1" >Legal basis for personal data processing</span>
                        <br />
                        <br />
The Policy is based on the following statutes and regulations of the Republic of Turkey:
                        <br />
                        <span className="dialog_option">• Law on the Protection of Personal Data" (Kişisel Verilerin Korunması Kanunu or KVKK in Turkish);</span>
                        <br />
                        <span className="dialog_option">• European Union's General Data Protection Regulation (GDPR);</span>
                        <br />
                        <span className="dialog_option">• Communiqué on the Procedures and Principles for Fulfilling the Obligation to Inform;</span>
                        <br />
                        <span className="dialog_option">• Regulation on the Data Controllers Registry (VERBİS);</span>
                        <br />
                        <span className="dialog_option">• Decision on the Deletion, Destruction, or Anonymization of Personal Data;</span>
                        <br />
                        <span className="dialog_option">• Council of Europe Convention for the Protection of Individuals with regard to Automatic Processing of Personal Data dated January 28, 1981 as amended and approved by the Committee of Ministers of the Council of Europe on June 15, 1999;</span>
                        <br />
                        <span className="dialog_option">• Constitution of the Republic of Turkey;</span>
                        <br />
                        <span className="dialog_option">• Civil Code of the Republic of Turkey;</span>
                        <br />
                        <span className="dialog_option">• Administrative Violations Code of the Republic of Turkey;</span>
                        <br />
                        <span className="dialog_option">• Labour Code of the Republic of Turkey;</span>
                        <br />
                        <span className="dialog_option">• Criminal Code of the Republic of Turkey.</span>
                        <br />
                        <br />
Go4invest (hereinafter - the Company) performs personal data processing on a legitimate equitable basis. The legal basis for personal data processing is a set of legal acts and legally significant documents in compliance with which Go4invest performs personal data processing:
                        <br />
                        <span className="dialog_option">• Labour Code of the Republic of Turkey;</span>
                        <br />
                        <span className="dialog_option">• Civil Code of the Republic of Turkey;</span>
                        <br />
                        <span className="dialog_option">• Agreements concluded between Go4invest and a personal data subject;</span>
                        <br />
                        <span className="dialog_option">• Agreements concluded between Go4invest and other legal entities under which personal data processing is involved;</span>
                        <br />
                        <span className="dialog_option">• Consent to personal data processing, etc.</span>
                        <br />
                        <br />
<span className="dialog_level1" >Purposes of collection, volume and categories of subjects of personal data processed</span>
                        <br />
                        <br />
Content and volume of personal data processed fully meet the specified purposes.
                        <br />
                        <br />
Purposes for personal data processing are the following:
                        <br />
                        <br />
                        <span className="dialog_level1" >1. Conclusion of services agreements with individuals.</span>
                        <br />
                        <span className="dialog_level1" >1.1. Categories of personal data subjects: individuals with whom services agreements have been signed.</span>
                        <br />
Categories of personal data processed: other personal data.
                        <br />
List of personal data processed: full name, nationality, gender, passport details, contact telephone number, details of the document confirming registration in the system of individual (personalized) record-keeping (individual insurance account number), taxpayer identification number, place of employment, position, date of birth, gender, place of birth.
                        <br />
Periods for processing and storing personal data: personal data shall be stored in the form which allows to determine the personal data subject not longer than it is required by a purpose of their processing.
                        <br />
Procedure of personal data destruction when objectives are achieved and upon the occurrence of other legal grounds: destruction shall be fulfilled by erasing information from data storage media (including backup copies). Monitoring of compliance with the procedures of destruction of personal data shall be carried out by the person responsible for the organization of personal data processing.
                        <br />
                        <br />
                        <span className="dialog_level1" >2. Market promotion.</span>
                        <br />
                        <span className="dialog_level1" >2.1. Categories of personal data subjects:</span>
                        <br />
clients.
                        <br />
Categories of personal data processed: other personal data.
                        <br />
List of personal data processed: full name, gender, date of birth, contact telephone number, contact e-mail, district of residence;
                        <br />
                        <br />
Periods for processing and storing personal data: personal data shall be stored in the form which allows to determine the personal data subject not longer than it is required by a purpose of their processing.
                        <br />
                        <br />
Procedure of personal data destruction when objectives are achieved and upon the occurrence of other legal grounds: destruction shall be fulfilled by erasing information from data storage media (including backup copies). Monitoring of compliance with the procedures of destruction of personal data shall be carried out by the person responsible for the organization of personal data processing.
                        <br />
                        <br />
                        <span className="dialog_level1" >Procedure and terms of personal data processing</span>
                        <br />
                        <br />
Go4invest shall receive all personal data of subjects from these subjects or from their legal representatives.
                        <br />
While collecting personal data, including through the information and telecommunications network "Internet", the recording, systematization, accumulation, storage, clarification (update, change), and extraction of personal data of citizens using databases located in the Republic of Turkey are ensured.
                        <br />
                        <br />
Data automatically saved by the information system for user analysis, such as: phone model, mobile operator, phone operating system, phone manufacturer, ip-address of the website visitor - is used to identify and correct faulty functions of the website and application, to protect against hacking attempts, spam and other injurious actions through the service: https://www.kaspersky.com/web-privacy-policy.
                        <br />
                        <br />
Personal data processing shall be carried out in accordance with the current legislation of the Republic of Turkey on the basis of consent of the personal data subject, except as otherwise provided in the “Law on the Protection of Personal Data” (Kişisel Verilerin Korunması Kanunu or KVKK in Turkish). Adjustment of a form of consent of a subject to standard forms of documents containing personal data of a subject (for example: questionnaire, letterheads) shall be allowed, except when personal data is provided for distribution.
                        <br />
                        <br />
Personal data processing means actions (operations) with personal data including:
                        <br />
                        <span className="dialog_option">• collection, storage, update and alteration;</span>
                        <br />
                        <span className="dialog_option">• systematization, accumulation;</span>
                        <br />
                        <span className="dialog_option">• use, distribution, transfer;</span>
                        <br />
                        <span className="dialog_option">• depersonalization, blocking, destruction.</span>
                        <br />
                        <br />
The personal data subject shall make a decision on provision of their personal data and give a consent to their processing wilfully and for their own benefit.
<br />
Obtainment of personal data of the subject from the third parties shall be possible only upon prior notification of the subject and subject to their written approval, except when personal data is provided for distribution.
<br />
<br />
Personal data of subjects shall be processed in structural divisions of Go4invest in accordance with functions performed.
<br />
Access to personal data processed without the use of automation facilities shall be exercised in accordance with the list approved.
<br />
<br />
Access to personal data processed in personal data information systems shall be exercised in accordance with the list and procedure approved by Go4invest.
<br />
Authorized persons of Go4invest allowed to receive personal data of subjects shall have a right to obtain only personal data of a subject that are necessary for fulfillment of certain functions in compliance with job description of the authorized persons.
<br />
<br />
Personal data shall be destroyed or depersonalized in the following cases:
<br />
<span className="dialog_option">• objects of processing are achieved or their achievement becomes unnecessary;</span>
<br />
<span className="dialog_option">• withdrawal of consent of the personal data subject to personal data processing is received;</span>
<br />
<span className="dialog_option">• the personal data subject or their legal representative provide information confirming that the personal data have been received illegally or are not necessary for the declared purpose of their processing;</span>
<br />
<span className="dialog_option">• detection of unlawful processing of personal data when the personal data subject or their legal representative informs of it, and it is impossible to provide lawful processing of personal data.</span>
<br />
<span className="dialog_option">• the request of the subject of Personal Data if the Personal Data is incomplete, outdated, inaccurate, illegally obtained or not necessary to achieve the stated purpose.</span>
<br />
<br />
Material media bearing the personal data shall be stored in specially fitted cabinets and safe boxes. Storage places shall be determined by the order on approval of storage places for Go4invest material media bearing the personal data.
<br />
Within 7 business days from the day of provision by the personal data subject or their legal representative of information confirming that the personal data are incomplete, inaccurate or outdated, Go4invest shall make adjustments as required and notify the subject of the alterations.
<br />
<br />
Destruction of personal data shall be fulfilled within 30 business days from the moment of achieving the purpose of personal data processing unless otherwise provided for by the federal laws of the Republic of Turkey.
<br />
Destruction of personal data shall be fulfilled within 30 business days from the moment of withdrawal of consent of the personal data subject to personal data processing.
<br />
Destruction of personal data shall be fulfilled within 7 business days from the moment of provision by the personal data subject or their representative of information confirming that the personal data have been received illegally or are not necessary for the declared purpose of their processing.
<br />
<br />
In case of detection of unlawful processing of personal data when the personal data subject or their legal representative informs of it, and if it is impossible to provide lawful personal data processing, destruction of personal data shall be fulfilled within 10 business days from the moment of detection of unlawful processing of personal data. Decision on the unlawfulness of personal data processing and the necessity of their destruction shall be made by the person responsible for organization of personal data processing who shall inform the management of it. Go4invest shall inform a personal data subject or their legal representative of destruction of personal data.
<br />
<br />
Destruction of personal data shall be fulfilled by the committee consisting of employees of the structural division which processed personal data of the subject and established the necessity of destruction of personal data under the control of a director of this structural division.
<br />
<br />
The method of destruction of material media bearing the personal data shall be determined by the committee. The use of the following methods shall be admitted:
<br />
<span className="dialog_option">• burning;</span>
<br />
<span className="dialog_option">• shredding (chopping);</span>
<br />
<span className="dialog_option">• transfer to special-purpose grounds (landfills);</span>
<br />
<span className="dialog_option">• chemical treatment.</span>
<br />
<br />
An act shall be drawn up and signed by a chairman of the committee that performed destruction of material media bearing the personal data.
<br />
Special organizations may be involved if it is needed to destroy a large number of material media or to apply special methods of destruction. In this case the members of the committee responsible for personal data destruction shall be present at destruction of the material media bearing the personal data. The slip confirming transfer of material media bearing personal data, subject to destruction , to a special organization, shall be attached to the act of destruction.
<br />
<br />
Destruction of Go4invest data base fields containing personal data of the subject shall be fulfilled in the following cases:
<br />
<span className="dialog_option">• objects of processing are achieved or their achievement becomes unnecessary;</span>
<br />
<span className="dialog_option">• withdrawal of consent of the personal data subject to personal data processing is received;</span>
<br />
<span className="dialog_option">• the personal data subject or their legal representative provide information confirming that the personal data have been received illegally or are not necessary for the declared purpose of their processing;</span>
<br />
<span className="dialog_option">• detection of unlawful processing of personal data when the personal data subject or their legal representative informs of it, and it is impossible to provide lawful processing of personal data.</span>
<br />
<span className="dialog_option">• the request of the subject of Personal Data if the Personal Data is incomplete, outdated, inaccurate, illegally obtained or not necessary to achieve the stated purpose.</span>
<br />
<br />
Destruction shall be fulfilled by the committee consisting of persons that are responsible for the technical maintenance of automated systems and that own the databases.
<br />
Destruction of electronic records archives and electronic communications protocols may not be fulfilled if their maintenance and security within a certain period are stipulated in the corresponding regulatory and/or contractual documents.
<br />
<br />
A decision that produces legal effects concerning the personal data subject or otherwise involves their rights and legitimate interests may be made on the basis of entirely automated processing of their personal data with the express written agreement of the personal data subject.
<br />
Protection of personal data of the subjects from unlawful use or loss shall be provided by Go4invest at its own expense in accordance with the procedure established by the legislation of the Republic of Turkey.
<br />
<br />
Cross-border transfer of personal data to foreign territories or to persons and organizations located in foreign territories is carried out in strict compliance with the provisions of European Union's General Data Protection Regulation (GDPR).
<br />
Necessary organizational and technical measures to ensure confidentiality of personal data shall be taken while processing.
<br />
Technical measures to protect personal data while processing with hardware shall be determined in accordance with:
<br />
<span className="dialog_option">• Law on the Protection of Personal Data (KVKK);</span>
<br />
<span className="dialog_option">• European Union's General Data Protection Regulation (GDPR);</span>
<br />
<span className="dialog_option">• Communiqué on the Procedures and Principles for Fulfilling the Obligation to Inform;</span>
<br />
<span className="dialog_option">• Regulation on the Data Controllers Registry (VERBİS);</span>
<br />
<span className="dialog_option">• Internal documents of Go4invest operating in the information security sphere.</span>
<br />
Personal data protection shall provide for restriction of access to them.
<br />
Internal policies and procedures, including the following documents, have been approved and accepted for execution by the order of Go4invest CEO:
<br />
<span className="dialog_option">• documents determining the procedure of personal data processing;</span>
<br />
<span className="dialog_option">• orders on approval of storage places of material media bearing personal data;</span>
<br />
<span className="dialog_option">• orders on determination of the list of persons carrying out processing of personal data or having access to them;</span>
<br />
<span className="dialog_option">• forms of consent of subjects to processing of their personal data;</span>
<br />
<span className="dialog_option">• documents determining the procedures aimed at prevention and detection of breaches of the Republic of Turkey legislation and remedial measures;</span>
<br />
<span className="dialog_option">• documents regulating the procedure of internal control;</span>
<br />
<span className="dialog_option">• documents aimed at estimation of damage that may be caused to personal data subjects in case of breach of the federal legislation relating to personal data;</span>
<br />
<span className="dialog_option">• arrangement of access to rooms where personal data are processed;</span>
<br />
<span className="dialog_option">• standard obligation of personal data nondisclosure;</span>
<br />
<span className="dialog_option">• standard form of explanation to the personal data subject of the legal consequences of refusal to provide personal data;</span>
<br />
<span className="dialog_option">• list of personal data information systems.</span>
<br />
<br />
Go4invest shall reserve the right to check completeness and accuracy of personal data provided with the consent of the personal data subject. In case of detection of erroneous or incomplete personal data, Go4invest shall have a right to end all relationships with the personal data subject.
<br />
<br />
<span className="dialog_level1">Procedure of processing applications and inquiries of personal data subjects.</span>
<br />
<br />

When receiving an application or a written inquiry from the personal data subject or their legal representative for the access to their personal data, Go4invest shall follow the requirements of the Law on the Protection of Personal Data (KVKK);
<br />
<br />
The access of the personal data subject or their legal representative to their personal data shall be provided by Go4invest only under the control of the person responsible for the organization of personal data processing by Go4invest.
<br />
Inquiries of the personal data subject or their legal representative shall be fixed in the registry of inquiries of citizens (personal data subjects) related to processing of personal data.
<br />
Written inquiries of the personal data subject or their legal representative shall be fixed in the registry of written inquiries of citizens for the access to their personal data.
<br />
<br />
The person responsible for the organization of personal data processing shall make a decision on providing access to personal data to the subject.
<br />
<br />
Information on availability of personal data shall be provided by Go4invest to the personal data subject in an intelligible form, and it shall not contain personal data related to other personal data subjects. Control over provision of information to the subject or their legal representative shall be carried out by the person responsible for the organization of personal data processing.
<br />
<br />
Information on availability of personal data shall be provided to the subject upon the respond to the inquiry within ten business days from the date of receipt of the inquiry of the personal data subject or their legal representative. This period may be extended, but by not more than five business days, if the operator delivers a reasoned notice to the personal data subject, specifying the reasons for extending the deadline for providing the requested information.
<br />
<br />
<span className="dialog_level1">Procedure of interaction with regulatory (supervisory) authorities.</span>
<br />
<br />
In case of an incident resulting in unauthorized access, provision, distribution or transfer of personal data, Go4invest shall notify the authorized body for the protection of the rights of personal data subjects within twenty-four hours from the moment of detection of such incident. Within seventy-two hours, Go4invest shall provide the results of an internal investigation into such incident.
<br />
<br />
Organisation of interaction with the competent authorities to protect the rights of subjects of personal data in the case of incidents is carried out in accordance with the procedure established by Law on the Protection of Personal Data (KVKK).
<br />
<br />
Collection of information for preparation of the substantive response to the inquiry of regulatory authorities shall be carried out by the person responsible for the organization of personal data processing, if necessary involving Go4invest employees.
<br />
<br />
Within the period established by the legislation the person responsible for the organization of personal data processing shall prepare the substantive response and other necessary documents and send them to the authorized body.
            
                    </DialogContent>

                </Dialog>
            </React.Fragment>
        </form>
    )
}
