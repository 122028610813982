import { Grid, Skeleton, Button } from "@mui/material";
import SortImagesDialog from '../../Projects/components/dialogs/SortImagesDialog/SortImagesDialog';
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from "react-image-gallery";
import ReactPlayer from 'react-player'
import { useStyles } from './styles';
import { useState } from "react";

export default function MediaSlider({mediaFiles, purpose, project}) {
    const classes = useStyles()
    const [showFullScreen, setFullScreen] = useState(true)
    const [sortImages, setSortImages] = useState(false)
    const images = mediaFiles.map(el => (
            {
                original: el.file, thumbnail: el.file, fullscreen: el.type === 'video' ? false : true,
                thumbnailClass: 'thumbImg', video: el.type === 'video' ? true : false,
            }
        )
    )

    return (
        <Grid container p={0} className={classes.root}>
            <Grid item xs={12}>
            <ImageGallery items={images} showPlayButton={false}
                onBeforeSlide={(e) => setFullScreen(images[e].fullscreen)}
                showFullscreenButton={showFullScreen}
                renderItem={(item) => (
                    item.video 
                        ? <ReactPlayer className="preview_video" controls={true} url={item.original} />
                        : <img src={item.original} className="image-gallery-image" alt='item' />
                )}
                renderThumbInner={(item) => 
                    item.video
                        ? <ReactPlayer className='video-gallery-thumbnail-video' controls={false} url={item.original} />
                        : <img src={item.original} className="image-gallery-thumbnail-image" alt='thumb-item' />
                }
            />
            </Grid>
            {
                mediaFiles?.length === 0 &&
                 <Grid item xs={12} className="slider-container" >
                     <Skeleton variant="rounded" height={300} sx={{ bgcolor: '#7e7e7e' }} />
                 </Grid>
             }
             {
                 (purpose === 'edit' || purpose === 'add') &&
                 <Grid item xs={12} mt={1} align='center'>
                     <Button className="view_btn_overview" onClick={() => setSortImages(true)}>Sort/Edit Images</Button>
                 </Grid>

             }
             {
                 (purpose === 'edit' || purpose === 'add') &&
                 <SortImagesDialog open={sortImages} setOpen={setSortImages} project={project} />
            }
        </Grid>
    )
}




// import { Grid, Button, Skeleton, Box, Link } from '@mui/material';
// import Slider from "react-slick";
// import React, { useRef, useState, useEffect } from 'react';
// import SortImagesDialog from '../../Projects/components/dialogs/SortImagesDialog/SortImagesDialog';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import ReactPlayer from 'react-player'
// import { useStyles } from './styles';

// export default function OverviewSlider({ imagePreviews, purpose = '', project }) {
//     const classes = useStyles()
//     const [sortImages, setSortImages] = useState(false)
//     const [nav1, setNav1] = useState(null);
//     const [nav2, setNav2] = useState(null);

//     const count_prev = imagePreviews?.length ? imagePreviews?.length : 0
//     let sliderRef1 = useRef(null);
//     let sliderRef2 = useRef(null);

//     function byField(fieldName) {
//         return (a, b) => a[fieldName] > b[fieldName] ? 1 : -1;
//     }

//     useEffect(() => {
//         setNav1(sliderRef1);
//         setNav2(sliderRef2);
//     }, []);


//     return (
//         <Grid item className={classes.root}>
//             {imagePreviews
//                 &&
//                 <Grid className="slider-container">
//                         <Slider arrows={false} {...settings}>
                            
//                             {imagePreviews.sort(byField('position')).map((preview, index) => (
//                                 <Grid container p={0} key={index}>
//                                     <Grid item align='center'>
//                                     {preview.type === 'image' && <img height={purpose === 'view' ? '400px' : '300px'} sx={{ margin: 'auto !important' }} width={'-webkit-fill-available'} key={index} src={preview.file} alt='Preview' />}
//                                     {preview.type === 'video' && <ReactPlayer className='preview_video' controls={true} url={preview.file} />}
//                                     </Grid>
//                                 </Grid>
//                             ))}

//                         </Slider>
//                     </Grid>

//                     <Grid item className="slider-container">
//                         <Slider asNavFor={nav2} arrows={false} ref={slider => (sliderRef1 = slider)}>
//                             {imagePreviews.sort(byField('position')).map((preview, index) => (
//                                 <Grid container p={0} key={index}>
//                                     <Grid item align='center'>
//                                         {preview.type === 'image' && <img sx={{ margin: 'auto !important' }} maxHeight={'300px'} key={index} src={preview.file} alt='Preview' />}
//                                         {preview.type === 'video' && <ReactPlayer className='preview_video' controls={true} url={preview.file} />}
//                                     </Grid>
//                                 </Grid>
//                             ))}
//                         </Slider>

//                         <Slider
//                             asNavFor={nav1}
//                             ref={slider => (sliderRef2 = slider)}
//                             slidesToShow={imagePreviews.length <= 3 ? imagePreviews.length : 3}
//                             swipeToSlide={true}
//                             focusOnSelect={true}
//                             className={classes.secondSlider}
//                         >
//                             {imagePreviews.sort(byField('position')).map((preview, index) => (
//                                 <Grid item p={0} key={index}>

//                                     {preview.type === 'image' && <img height={purpose === 'view' ? '30px' : '30px'} sx={{ margin: 'auto !important' }} width={'-webkit-fill-available'} key={index} src={preview.file} alt='Preview' />}
//                                     {preview.type === 'video' && <ReactPlayer className='preview_video' controls={true} url={preview.file} />}
//                                 </Grid>
//                             ))}
//                         </Slider>
//                     </Grid>


//             }

//             {
//                 imagePreviews?.length === 0 &&
//                 <Grid item xs={12} className="slider-container" >
//                     <Skeleton variant="rounded" height={300} sx={{ bgcolor: '#7e7e7e' }} />
//                 </Grid>
//             }

//             {
//                 (purpose === 'edit' || purpose === 'add') &&
//                 <Grid item xs={12} mt={1} align='center'>
//                     <Button className="view_btn_overview" onClick={() => setSortImages(true)}>Sort/Edit Images</Button>
//                 </Grid>

//             }
//             {
//                 (purpose === 'edit' || purpose === 'add') &&
//                 <SortImagesDialog open={sortImages} setOpen={setSortImages} project={project} />
//             }
//         </Grid >
//     )
// }