import * as React from 'react';
import {Typography, Grid} from '@mui/material';
import "./projects.css";
import png1 from "../../../../assets/images/home/projects/1.png";
import png2 from "../../../../assets/images/home/projects/2.png";
import png3 from "../../../../assets/images/home/projects/3.png";
import png4 from "../../../../assets/images/home/projects/4.png";



export default function FeaturedProjects(){
    return (
        <Grid container justifyContent="center" className="projectLayout" id='projects_blog'>
            <Grid container item  alignItems="center" direction="column" xl={7} sm={10}  mt={15}>
                <Grid item xl={6} sm={12} justifyContent="center">
                    <Typography variant="body1" className="fut_pr">
                        FEATURED PROJECTS
                    </Typography>
                </Grid>

                <Grid item xs={12} lg={6}  align="center">
                    <Typography className="dis_our">
                    Discover our Portfolios and Seize The Opportunities
                    </Typography>
                </Grid>
            </Grid>



            <Grid container item xs={12} mt={10} justifyContent="center" p={0}>
                <Grid container item xs={12} lg={6} mb={6} p={0} justifyContent="center">
                    <Grid item xs={12} align="center">
                        <img className="pr_img" src={png1} alt="png1"/>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography className="title_image" mt={4}>
                            NEW CENTRAL GARDEN
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography className="desc_image">
                            BALTIMORE, MD
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} lg={6} p={0} alignItems="center" mb={6}>
                    <Grid item xs={12} align="center">
                        <img className="pr_img" src={png2} alt="png2"/>
                    </Grid>
                    <Grid item xs={12} mt={4} align="center">
                        <Typography className="title_image">
                            CITY SHOPPING COMPLEX
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography className="desc_image">
                            CHARLOTTESVILLE, VA
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} lg={6} p={0} alignItems="center" mb={6}>
                    <Grid item xs={12} align="center">
                        <img className="pr_img" src={png3} alt="png3"/>
                    </Grid>
                    <Grid item xs={12} mt={4} align="center">
                        <Typography className="title_image">
                            MODERN RESIDENTIAL PARK
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography className="desc_image">
                            NEW YORK, NY
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} lg={6} p={0} alignItems="center"  mb={6}>
                    <Grid item xs={12} align="center">
                        <img className="pr_img" src={png4} alt="png4"/>
                    </Grid>
                    <Grid item xs={12} mt={4} align="center">
                        <Typography className="title_image" >
                            PARAGON HOTEL CONCEPT
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography className="desc_image">
                            NEW ORLEANS, LA
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item mb={14}>
                    <a className="btn btn_tran" data-animation="fadeInUp" data-delay=".6s" href='/projects/'>
                        <div className="btn_m">
                            <div className="btn_c">
                                <div className="btn_t1">EXPLORE ALL</div>
                                <div className="btn_t2">EXPLORE ALL</div>
                            </div>
                        </div>
                    </a>
                </Grid>
            </Grid>
        </Grid>
    )
}