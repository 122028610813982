import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            borderRadius: '10px',
            background: colors.secondary.DEFAULT,
            '& .MuiButtonBase-root': {
                textTransform: 'none',
            },
            '& fieldset': {
                borderColor: colors.primary.DEFAULT,
            },
            '& .MuiFormControl-root': {
                '& .MuiInputBase-root': {
                    borderRadius: '10px'
                }
            }

        },
        searchBlock: {
            border: `1px solid ${colors.primary.DEFAULT}`,
            borderRadius: '10px',
            '& .MuiSvgIcon-root': {
                stroke: colors.primary.DEFAULT,
                fill: colors.primary.DEFAULT
            },
            '& .MuiInputBase-root': {
                '& ::placeholder': {
                    color: colors.primary.DEFAULT
                }
            }
        },
        addBtn: {
            background: `${colors.primary.DEFAULT} !important`,
            color: `${colors.white.DEFAULT} !important`,
            '&:hover': {
                background: `${colors.primary[850]} !important`,
                color: `${colors.primary[200]} !important`,
            }
        }

    })
})