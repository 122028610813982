import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    
    return ({
        navBarWrap: {
            display: 'flex',
            background: colors.black.DEFAULT,
            zIndex: 4,
            '& .MuiSpeedDial-root': {
                borderRadius: '10px',
                '& .MuiButtonBase-root': {
                    borderRadius: '10%'
                },
                '& .MuiAvatar-root': {
                    width: '46px',
                    height: '46px'
                }
            },
            '& .MuiSpeedDial-root:hover': {
                background: colors.primary.DEFAULT
            },
            '& .MuiSpeedDial-actionsClosed': {
                width: 'auto'
            }
        },
        logoImage: {
            scale: 1.5
        },
        switchAppBarBtn: {
            background: `${colors.primary.DEFAULT} !important`,
            borderRadius: '8px !important',
            padding: '8px !important',
            '&:hover': {
                background: `${colors.primary.DEFAULT} !important`,
            },
            '&:hover .MuiSvgIcon-root': {
                color: `${colors.black.DEFAULT} !important`,
            }
        },

    })

})