import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import "./toolbar.css"
import { Divider, List, ListItem, ListItemButton, SwipeableDrawer, Card, Collapse } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


export default function ButtonAppBar(props) {
  const [state, setState] = React.useState({ left: false });
  const [scroll, setScroll] = React.useState(0);
  const [aboutOpen, setAboutOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>

        <ListItem key={1} disablePadding>
          <ListItemButton p={1} onClick={() => navigate('/')}>
            <ListItemText primary='HOME' className="navbar_text" />
          </ListItemButton>
        </ListItem>

        <ListItem key={2} disablePadding>
          <ListItemButton p={1} >
            <ListItemText primary='ABOUT' className="navbar_text" onClick={() => setAboutOpen(!aboutOpen)} />
            {aboutOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        <Collapse in={aboutOpen} timeout="auto" unmountOnExit>
          {aboutOpen &&
            <List component="div" disablePadding className='list_content'>

              <ListItemButton sx={{ p: 0 }} onClick={() => navigate('/about/overview')}>
                <ListItemText align='center' primary="Overview" />
              </ListItemButton>

              <ListItemButton sx={{ p: 0 }} onClick={() => navigate('/about/mission')}>
                <ListItemText align='center' primary="Mission" />
              </ListItemButton>

              <ListItemButton sx={{ p: 0 }} onClick={() => navigate('/about/our_team')}>
                <ListItemText align='center' primary="Our Team" />
              </ListItemButton>

            </List>


          }
        </Collapse>

        {/* <ListItem key={3} disablePadding>
          <ListItemButton p={1} onClick={() => navigate('/projects')}>
            <ListItemText primary='PROJECTS' className="navbar_text" />
          </ListItemButton>
        </ListItem> */}

        <ListItem key={4} disablePadding>
          <ListItemButton p={1} onClick={() => navigate('/blog')}>
            <ListItemText primary='BLOG' className="navbar_text" />
          </ListItemButton>
        </ListItem>

        <ListItem key={5} disablePadding>
          <ListItemButton p={1} onClick={() => navigate('/contact')}>
            <ListItemText primary='CONTACT' className="navbar_text" />
          </ListItemButton>
        </ListItem>

      </List>
      <Divider />
    </Box>
  );

  return (
    <Grid sx={{ flexGrow: 1 }} id="head_of_page">
      <AppBar position="static" className={scroll > 100 ? "BoxNavBar" :
        props.mode === 'tran' ? 'BoxNavBar_absolute dark_mode' : "BoxNavBar_absolute"} >
        <Toolbar >
          <Grid container p={0} item alignItems="flex-end" xs={12} justifyContent='space-between'>
            <Grid item xs={1}>
              {['left',].map((anchor) => (
                <React.Fragment key={anchor} >
                  <Button onClick={toggleDrawer(anchor, true)} className="navbarMenuButton">
                    <svg className="navbar_svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M3.3335 6.66666H16.6668M8 13.3333H16.6668"
                        stroke={
                          scroll > 100
                            ? '#000'
                            : props.mode === 'tran'
                              ? "#fff"
                              : "#000"
                        }

                        strokeWidth="2"
                        strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Button>
                  <SwipeableDrawer
                    className='swipe_drawer'
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Grid container justifyContent="center" alignItems="center" mt={4} direction="column" >
                      <Typography variant="body1" className='navbar_reland'
                        mb={4}>RELAND</Typography>

                      {list(anchor)}
                    </Grid>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Grid>

            <Grid container item xs={6} md={12} p={0} justifyContent="end" alignItems="center">

              <Grid container item p={0} xs={0} md={11} justifyContent="center">
                <Grid item p={2}>
                  <Link href="/" className={
                    scroll > 100
                      ? props.page === 'home'
                        ? "menuLinks_pointed_mode menuLinks"
                        : "menuLinks_white_mode menuLinks"
                      : props.mode === 'tran'
                        ? props.page === 'home'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_tran_mode menuLinks"
                        : props.page === 'home'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_white_mode menuLinks"

                  }>HOME</Link>
                </Grid>
                <Grid item p={2} className="menuLinks">
                  <Link className={
                    scroll > 100
                      ? props.page === 'about'
                        ? "menuLinks_pointed_mode menuLinks"
                        : "menuLinks_white_mode menuLinks"
                      : props.mode === 'tran'
                        ? props.page === 'about'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_tran_mode menuLinks"
                        : props.page === 'about'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_white_mode menuLinks"
                  }
                  >ABOUT</Link>
                  <Card sx={{ minWidth: 160 }} className="toolbar_card">
                    <CardContent className="toolbar_card_content">
                      <Link href="/about/overview" className="toolbar_card_content_link">Overview</Link>
                      <Link href="/about/mission" className="toolbar_card_content_link">Mission</Link>
                      <Link href="/about/our_team" className="toolbar_card_content_link">Our Team</Link>
                    </CardContent>
                  </Card>
                </Grid>
                {/* <Grid item p={2}>
                  <Link href="/projects" className={
                    scroll > 100
                      ? props.page === 'projects'
                        ? "menuLinks_pointed_mode menuLinks"
                        : "menuLinks_white_mode menuLinks"
                      : props.mode === 'tran'
                        ? props.page === 'projects'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_tran_mode menuLinks"
                        : props.page === 'projects'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_white_mode menuLinks"

                  } >PROJECTS</Link>
                </Grid> */}
                <Grid item p={2}>
                  <Typography className=
                    {
                      scroll > 100
                        ? "menuTextReland menuLinks_pointed_mode"
                        : props.mode === 'tran'
                          ? "menuTextReland "
                          : "menuTextReland menuLinks_pointed_mode"
                    }


                    variant="div">RELAND</Typography>
                </Grid>
                <Grid item p={2}>
                  <Link className={
                    scroll > 100
                      ? props.page === 'blog'
                        ? "menuLinks_pointed_mode menuLinks"
                        : "menuLinks_white_mode menuLinks"
                      : props.mode === 'tran'
                        ? props.page === 'blog'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_tran_mode menuLinks"
                        : props.page === 'blog'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_white_mode menuLinks"
                  } href="/blog">BLOG</Link>
                </Grid>
                {/* <Grid item p={2}>
                  <Link className={
                    scroll > 100
                      ? props.page === 'pages'
                        ? "menuLinks_pointed_mode menuLinks"
                        : "menuLinks_white_mode menuLinks"
                      : props.mode === 'tran'
                        ? "menuLinks_tran_mode menuLinks"
                        : props.page === 'pages'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_white_mode menuLinks"
                  }>PAGES</Link>
                </Grid> */}
                <Grid item p={2}>
                  <Link className={
                    scroll > 100
                      ? props.page === 'contact_css'
                        ? "menuLinks_pointed_mode menuLinks"
                        : "menuLinks_white_mode menuLinks"
                      : props.mode === 'tran'
                        ? "menuLinks_tran_mode menuLinks"
                        : props.page === 'contact_css'
                          ? "menuLinks_pointed_mode menuLinks"
                          : "menuLinks_white_mode menuLinks"
                  } href="/contact">CONTACT</Link>
                </Grid>
              </Grid>

              <Grid container item p={0} xs={4} md={1}>
                <Grid item align='flex-end' >
                  <Link className={
                    scroll > 100
                      ? 'menuLinks_white_mode'
                      : props.mode === 'tran'
                        ? "menuLinks_tran_mode"
                        : "menuLinks_white_mode"
                  }
                    href="/admin_panel/projects/my_projects" >Admin Panel</Link>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <a href='#head_of_page' className={scroll > 300 ? "button_turn_back show_turn_back" : "button_turn_back"}><KeyboardArrowUpIcon /></a>
    </Grid>
  );
}