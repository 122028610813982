import { Grid, Typography, TextField, FormControl, Autocomplete } from "@mui/material";
import { useStyles } from "../styles";
import { useFormContext, Controller } from "react-hook-form";
import { countries } from "../Personal/constants";
import FormError from '../../../../../components/FormError/FormError'
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../../../../../features/admin_panel/adminPanelSlice";

const DATA = [
    { label: 'Company Name', name: 'name' },
    { label: 'About Company / Sector / Info', name: 'about' },
    { label: 'Company Address', name: 'address' },
    { label: 'Company Website', name: 'website' },
    { label: 'Zip Code', name: 'zip_code', type: 'number' },
]

export default function Company() {
    const { register, control, formState: { errors } } = useFormContext()
    const userInfo = useSelector(selectUserInfo)
    const classes = useStyles()
    return (
        <Grid container item xs={12} className={classes.root} spacing={2}>
            {DATA.map((el, index) => (
                <Grid item xs={12} md={6} sx={{ 'position': 'relative' }} key={index}>
                    <Typography>{el.label}</Typography>
                    <TextField fullWidth placeholder={el.label}
                        type={el?.type ? el.type : 'text'}
                        variant="outlined" size='small'
                        defaultValue={userInfo.company?.[el.name]}
                        {...register(el.name,
                            el?.type === 'number' && {
                                min: {
                                    value: 0,
                                    message: 'The value must be greater than or equal to 0!'
                                }
                            }
                        )}
                    />
                    <FormError error={errors[el.name]} errors={errors} name={el.name} />
                </Grid>
            ))}
            <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '99%' }} size="small">
                    <Typography>Company Country</Typography>
                    <Controller
                        name={'country'}
                        defaultValue={userInfo?.company?.country}
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                defaultValue={countries.find(el => el?.label === userInfo?.company?.country)}
                                label='Company Country'
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                options={countries}
                                required
                                getOptionLabel={(option) => option?.label}
                                onChange={(e, v) => {
                                    v?.code && field.onChange(v.label)
                                }}
                                renderInput={(params) => <TextField  {...params} placeholder='Company Country' />}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}