import { Grid, Typography } from "@mui/material";

const DATA = {
    'months_6_12': '6-12 months', 'years_1_5': '1-5 Years', 'years_5_10': '5-10 Years', 'years_10_15': '10-15 Years',
    'years_15_20': '15-20 Years', 'years_20_and_more': '20+'
}

export default function TabPanelDetails({ data }) {
    const get_data = (value) => {
        if (Object.keys(DATA).includes(value)) {
            return DATA[value]
        }
        else if (typeof (value) === 'string') {
            return value.replace(/_/g, ' ').replace(/ and /g, ' & ').replace(/\b\w/g, char => char.toUpperCase())
        }
        return value
    }

    return (
        <Grid container xs={12} p={0}>
            {Object.keys(data)?.map((row) => (
                (data[row] !== '' && data[row] !== undefined && (typeof data[row] !== 'object') && row !== 'project' && row !== 'id') && (
                    <Grid container xs={12} p={0} mt={1} alignItems='flex-start'>
                        <Grid item xs={6}>
                            <Typography color='grey'>{get_data(row)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color='white'>
                                {data[row] === false
                                    ? 'no'
                                    : data[row] === true
                                        ? 'yes'
                                        : get_data(data[row])
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                )
            ))
            }
        </Grid>
    )
}