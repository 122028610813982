import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react';
import makeApiRequest from '../../../../../../api/api.js'
import { useDispatch, useSelector } from 'react-redux';
import { putFilterData, selectProjectFilters } from '../../../../../../features/projects/projectsSlice.js';

export default function FilterLocation() {
    const dispatch = useDispatch()
    const [countries, setCountries] = useState([])
    const [regions, setRegions] = useState([])
    const [cities, setCities] = useState([])

    const filters = useSelector(selectProjectFilters)

    const [currentCountry, setCurrentCountry] = useState(filters.location__country)
    const [currentRegion, setCurrentRegion] = useState(filters.location__region)
    const [currentCity, setCurrentCity] = useState(filters.location__city)


    useEffect(() => {
        const fetchCountry = async () => {
            makeApiRequest('/api/countries_filter/').then(res => {
                setCountries(JSON.parse(res.data))
                !currentCountry && load_region(currentCountry, 'init')
                !currentRegion && load_cities(currentRegion, 'init')
            })
        }
        fetchCountry()
    }, [])

    const load_region = (country, purpose = '') => {
        setCurrentCountry(country)
        if (country !== '') {
            makeApiRequest('/api/regions_filter/', 'GET', {}, false, { params: { 'country': country } }).then(res => {
                setRegions(JSON.parse(res.data))
                if (purpose === '') {
                    dispatch(putFilterData({ 'field': 'location__country', 'data': country }))
                    dispatch(putFilterData({ 'field': 'location__region', 'data': '' }))
                    dispatch(putFilterData({ 'field': 'location__city', 'data': '' }))
                    setCurrentRegion("")
                    setCurrentCity("")
                }
            })
        }
    }

    const load_cities = (region, purpose = '') => {
        setCurrentRegion(region)
        if (region !== '') {
            makeApiRequest('/api/cities_filter/', 'GET', {}, false, { params: { 'country': currentCountry, 'region': region } }).then(res => {
                setCities(JSON.parse(res.data))
                dispatch(putFilterData({ 'field': 'location__region', 'data': region }))
                purpose === '' && dispatch(putFilterData({ 'field': 'location__city', 'data': '' }))
            })
        }
    }

    return (
        <Grid container item xs={12} md={2} p={1} mb={2} spacing={1} alignSelf={'center'}>
            <Grid item xs={12} pr={1}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-select-small-label">Country</InputLabel>
                    <Select
                        onChange={(e) => load_region(e.target.value)}
                        className='tab_text_field'
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Country"
                        value={currentCountry}
                        defaultValue={filters?.location__country}
                    >
                        <MenuItem value="">
                            <em>Choose Region</em>
                        </MenuItem>
                        {countries?.map((country) => (
                            <MenuItem value={country}>{country}</MenuItem>
                        ))}

                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} pr={1}>
                <FormControl fullWidth size="small" >
                    <InputLabel id="demo-select-small-label">Region</InputLabel>
                    <Select
                        className='tab_text_field'
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Region"
                        onChange={(e) => load_cities(e.target.value)}
                        defaultValue={filters?.location__region}
                        value={currentRegion}
                    >
                        <MenuItem value="">
                            <em>Choose Region</em>
                        </MenuItem>
                        {regions?.map(region => (
                            <MenuItem value={region}>{region}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} pr={1}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-select-small-label">City</InputLabel>
                    <Select
                        className='tab_text_field'
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="City"
                        value={currentCity}
                        defaultValue={filters?.location__city}
                        onChange={(v) => {
                            setCurrentCity(v.target.value)
                            dispatch(putFilterData({ 'field': 'location__city', 'data': v.target.value }))
                        }
                        }
                    >
                        <MenuItem value="">
                            <em>Choose City</em>
                        </MenuItem>
                        {cities.map(city => (
                            <MenuItem value={city}>{city}</MenuItem>
                        ))}

                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}