import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& .MuiButtonBase-root': {
                color: colors.white.DEFAULT,
            }
        },
        declineBtn: {
            background: `${colors.primary[850]} !important`
        }
    })
})