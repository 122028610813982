import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        bmMenuWrap: {
            background: colors.black.DEFAULT,
            overflow: "auto",
            height: 'inherit',
            bottom: 0,
            borderRadius: '0 5px 5px 0',
            transition: '1s',
            '@media screen and (max-width: 900px)': {
                position: 'absolute'
            }
        },
    })

})