import * as React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import Footer from "../../HomePage/components/Footer/Footer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ToolBar from "../../HomePage/components/ToolBar/ToolBar";
import pic1 from "../../../../assets/images/about/mission/1.png";
import pic2 from "../../../../assets/images/about/mission/2.png";
import svg1 from "../../../../assets/images/about/mission/3.svg";
import svg2 from "../../../../assets/images/about/mission/4.svg";
import svg3 from "../../../../assets/images/about/mission/5.svg";

import Testimon from "../../HomePage/Testimonials/Testimonials";
import QuickInquiry from "../../HomePage/components/QuickInquiry/quick_inquiry";
import './mission.css';


export default function Mission() {
    return (
        <Box className='mission_root'>
            <ToolBar mode="dark" page='about' />
            <Grid container pl={0} pr={0} id="toolBar">
                <Grid container mt={20}>
                    <Grid item xl={12} xs={12}>
                        <Typography variant="h1" className="overview_title">
                        MISSION
                        </Typography>
                    </Grid>
                    <Grid item xl={12} xs={12} align="center" alignContent="center">
                        <Typography variant="body1">
                            <a href="/" className="color_home_overview">HOME</a> <NavigateNextIcon/>
                            <span className="overview_text-overview">MISSION</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container mt={4} pl={0} pr={0} alignItems="center">
                <Grid container item xs={12} md={6} justifyContent="flex-end">
                    <img className='mission_img' width="462" height="640" src={pic1} alt="pic_1"/>
                </Grid>
                <Grid container item xs={12} md={6} mt={16}>
                    <Grid item xl={12} xs={12} mb={2}>
                        <Typography variant="caption" id="title">
                            Our Mission
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={8}  align="start">
                        <Typography variant="body1" className="we_are_30_years" align="left">
                            To be the most trusted name in real estate globally
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={8}>
                        <Typography variant="caption">
                        Our primary goal is to foster financial independence by providing unparalleled insights into
                        the world of real estate. Through expert analysis, market trends, and personalized guidance,
                        we aim to equip you with the confidence and understanding needed to make informed investment decisions.
                        </Typography>
                    </Grid>

                    <Grid item  mt={6} xs={12}>
                        <a className="btn btn_tran" data-animation="fadeInUp" data-delay=".6s">
                            <div className="btn_m">
                                <div className="btn_c">
                                    <div className="btn_t1">LEARN MORE</div>
                                    <div className="btn_t2">LEARN MORE</div>
                                </div>
                            </div>
                        </a>
                    </Grid>

                </Grid>
            </Grid>

            <Grid container mb={4} pl={0} pr={0} alignItems="center">

                <Grid container item xs={12} md={6} mt={16} justifyContent="flex-end">
                    <Grid item xs={12} mb={2} align="end">
                        <Typography variant="caption" id="title" >
                            Our vision
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={8} align="end">
                        <Typography variant="body1" className="we_are_30_years" align="end">
                            We believe that we are enablers of people's dreams
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={8}>
                        <Typography variant="caption">
                        With years of industry expertise, Go4Invest stands as a beacon of reliability and excellence in
                        the realms of investment and real estate. Our team of seasoned professionals is dedicated to providing
                        you with insightful market analysis, personalized investment strategies, and access to prime real
                        estate opportunities.
                        </Typography>
                    </Grid>

                    <Grid item mb={14} mt={6} xl={12} xs={12} align="end">
                        <a className="btn btn_tran" data-animation="fadeInUp" data-delay=".6s">
                            <div className="btn_m">
                                <div className="btn_c">
                                    <div className="btn_t1">LEARN MORE</div>
                                    <div className="btn_t2">LEARN MORE</div>
                                </div>
                            </div>
                        </a>
                    </Grid>

                </Grid>

                <Grid container item xs={12} md={6}>
                    <img className='mission_img' width="462" height="640" src={pic2} alt="pic_2"/>
                </Grid>
            </Grid>

            <Grid container className="what_we_do_mission" pt={10} pb={10} mr={0} ml={0} justifyContent="center">
                <Grid container item xs={12} justifyContent="center">
                    <Grid item xs={12} align="center">
                        <Typography variant="caption" id="title">WHAT WE DO</Typography>
                    </Grid>

                    <Grid sm={8} align="center">
                        <Typography className="create_greate_tomorrow_mission">CREATING A GREAT TOMORROW FOR
                            EVERYONE</Typography>
                    </Grid>
                </Grid>

                <Grid container p={0} lg={10} alignItems='baseline'>
                    <Grid container p={0} item justifyContent="center" xs={12} md={6} lg={4} mt={10}>
                        <Grid item mt={2} xs={12} mb={2} align="center">
                            <img src={svg1} width="195" height="195" alt="svg1" className="svg_mission"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h2" className="vidaloka_text">
                                Residential Development
                            </Typography>
                        </Grid>
                        <Grid container item justifyContent="center" xs={12} mt={1} p={1}>
                            <Typography variant="caption" className="montserrat_text" >
                            Embracing the power of technology, we harness innovative tools to simplify
                            complex processes, making real estate investment accessible, efficient, and rewarding.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <a href="#toolBar" className="learn_more_link">LEARN MORE</a>
                        </Grid>

                    </Grid>

                    <Grid container p={0} item justifyContent="center" xs={12} md={6} lg={4} mt={10}>
                        <Grid item mt={2} mb={2} xs={12} align="center">
                            <img src={svg2} width="195" height="195" alt="svg1" className="svg_mission"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h2" className="vidaloka_text">
                                Commercial Development
                            </Typography>
                        </Grid>
                        <Grid container item justifyContent="center" xs={12} mt={1} p={1}>
                            <Typography variant="caption" className="montserrat_text" >
                            Our platform is dedicated to providing the investment wisdom and real estate insights
                            you need to prosper in an ever-evolving market.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <a href="#toolBar" className="learn_more_link">LEARN MORE</a>
                        </Grid>

                    </Grid>

                    <Grid container p={0} item justifyContent="center" xs={12} md={6} lg={4} mt={10}>
                        <Grid item mt={2} mb={2} xs={12} align="center">
                            <img src={svg3} width="195" height="195" alt="svg1" className="svg_mission"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h2" className="vidaloka_text">
                                Industrial Development
                            </Typography>
                        </Grid>
                        <Grid container item justifyContent="center" xs={12} mt={1} p={1}>
                            <Typography variant="caption" className="montserrat_text" >
                            We strive to demystify the intricacies of real estate investment, offering educational
                            resources that empower you to make confident decisions aligned with your financial goals.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <a href="#toolBar" className="learn_more_link">LEARN MORE</a>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            <Testimon />
            <Grid container xl={12} sm={12} justifyContent="center">
                <QuickInquiry/>
            </Grid>
            <Footer/>

        </Box>
    )
}