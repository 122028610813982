import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& .fui-dropzone-root': {
                minHeight: '125px',
                minWidth: '125px',
                width: '125px',
                border: 'none',
                color: colors.white.DEFAULT,
                fontSize: '18px',
                fontWeight: '600',
                background: `${colors.gray[150]} !important`
            },
            '& .files-ui-file-mosaic-file-name': {
                display: "none"
            }
        },
        cardBlock: {
            background: `${colors.black.DEFAULT} !important`,
            border: `1px solid ${colors.primary.DEFAULT}`,
            borderRadius: '10px'
        },
        activeBtn: {
            background: `${colors.primary[850]} !important`,
            color: `${colors.gray[100]} !important`,
            '&:hover': {
                background: colors.black.DEFAULT
            }

        },
        passiveBtn: {
            background: `${colors.primary.DEFAULT} !important`,
            color: `${colors.white.DEFAULT} !important`,
            '&:hover': {
                background: `${colors.black.DEFAULT} !important`
            }
        },
        emailIconBtn: {
            '& .MuiButtonBase-root': {
                background: colors.primary[925]
            }
        },
        uploadPicBlock: {
            position: 'relative',
            width: '150px',
            height: '150px',
            background: `${colors.primary[850]} !important`,
            alignContent: 'center',
            '& .MuiAvatar-root': {
                width: '90%',
                height: '90%'
            },
            '& .MuiButtonBase-root': {
                position: 'absolute',
                background: `${colors.primary[850]} !important`,
                top: '-10px',
                right: '-10px'
            }
        }
    })
})