import {Grid, Typography} from "@mui/material";
import b1 from "../../../../../assets/images/home/we_are/B-1.png";
import b2 from "../../../../../assets/images/home/we_are/B-2.png";
import b3 from "../../../../../assets/images/home/we_are/B-3.png";
import b4 from "../../../../../assets/images/home/we_are/B-4.png";
import b5 from "../../../../../assets/images/home/we_are/B-5.png";
import b6 from "../../../../../assets/images/home/we_are/B-6.png";
import * as React from "react";

export default function Property() {
    return (
        <Grid container item mt={10} justifyContent="center" mb={14} alignItems='baseline'>

                <Grid container item xs={12} sm={6} lg={4} mt={3} p={0} alignItems="flex-start" justifyContent="center">
                    <Grid item xs={12} align="center" className="descIcon">
                        <img src={b1} alt={'b1'} width="100" height="100"/>
                    </Grid>
                    <Grid item mt={3} xs={12}>
                        <Typography variant="body1" className="vidaloka_text">
                            INCREASE YOUR PROFIT
                        </Typography>
                    </Grid>
                    <Grid item mt={2} xs={12} sm={6} md={8} lg={12}>
                        <Typography align="center" className="montserrat_text">
                            Discover the key to financial prosperity and unlock the doors to a 
                            world of lucrative real estate opportunities with Go4Invest.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} lg={4} mt={3} p={0} alignItems="center" justifyContent="center">
                    <Grid item xs={12} align="center" className="descIcon">
                        <img src={b2} alt={'b2'} width="100" height="100"/>
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Typography variant="body1" className="vidaloka_text">
                            Find Opportunities
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2} sm={6} md={8} lg={12}>
                        <Typography align="center" className="montserrat_text">
                            Dive into a diverse portfolio of residential and commercial properties that cater
                            to every investment strategy. 
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} lg={4} mt={3} p={0} alignItems="center" justifyContent="center">
                    <Grid item xs={12} align="center" className="descIcon">
                        <img src={b3} alt={'b3'} width="100" height="100"/>
                    </Grid>
                    <Grid item mt={3} xs={12}>
                        <Typography variant="body1" className="vidaloka_text">
                            CATCH THE TRENDS
                        </Typography>
                    </Grid>
                    <Grid item mt={2} xs={12} sm={6} md={8} lg={12}>
                        <Typography align="center" className="montserrat_text">
                            Our team of experts is dedicated to providing you with the latest market trends, insights,
                            and analyses.
                        </Typography>
                    </Grid>
                </Grid>


                <Grid container item xs={12} sm={6} lg={4} mt={3} p={0} alignItems="center" justifyContent="center">
                    <Grid item xs={12} align="center" className="descIcon">
                        <img src={b4} alt={'b4'} width="100" height="100"/>
                    </Grid>
                    <Grid item mt={3} xs={12}>
                        <Typography variant="body1" className="vidaloka_text">
                            PROFESSIONAL SERVICES
                        </Typography>
                    </Grid>
                    <Grid item mt={2} xs={12} sm={6} md={8} lg={12}>
                        <Typography align="center" className="montserrat_text">
                            Get quick help from our expert consultant teams for all your
                            transactions or anything you want to learn.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} lg={4}  mt={3} p={0} alignItems="center" justifyContent="center">
                    <Grid item xs={12} align="center" className="descIcon">
                        <img src={b5} alt={'b5'} width="100" height="100"/>
                    </Grid>
                    <Grid item mt={3} xs={12}>
                        <Typography variant="body1" className="vidaloka_text">
                            Expand Your Invest Zone
                        </Typography>
                    </Grid>
                    <Grid item mt={2} xs={12} sm={6} md={8} lg={12}>
                        <Typography align="center" className="montserrat_text">
                            Our community-driven platform fosters collaboration, allowing you to share experiences,
                            gain valuable advice, and build a network that propels you toward success.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} lg={4} mt={3} p={0} alignItems="center" justifyContent="center">
                    <Grid item xs={12} align="center" className="descIcon">
                        <img src={b6} alt={'b6'} width="100" height="100"/>
                    </Grid>
                    <Grid item mt={3} xs={12}>
                        <Typography variant="body1" className="vidaloka_text">
                            INVEST SAFELY
                        </Typography>
                    </Grid>
                    <Grid item mt={2} xs={12} sm={6} md={8} lg={12}>
                        <Typography align="center" className="montserrat_text">
                            Make your transactions quickly and safely, keep full control of your
                            investments and track their values statistically.
                        </Typography>
                    </Grid>
                </Grid>
        </Grid>
    )
}