import { Grid, Typography, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useStyles } from "./styles";
import { useState } from "react";

const SELECT_DATA = [
    {
        label: 'Extra Project Adding', name: 'ad', options: [
            { label: '5 Ads (All Estate Types)', value: 5 },
            { label: '10 Ads (All Estate Types)', value: 10 },
            { label: '15 Ads (All Estate Types)', value: 15 },
            { label: '20 Ads (All Estate Types)', value: 20 },
            { label: '25 Ads (All Estate Types)', value: 25 },
            { label: '30 Ads (All Estate Types)', value: 30 },
        ],
        extra_section: { label: '5 Ads (All Estate Types)', price: '$ 20' }
    },
    {
        label: 'Highligh Quantity', name: 'highligh', options: [
            { label: '1 Use (Qty) Ad Promotion', value: 1 },
            { label: '2 Use (Qty) Ad Promotion', value: 2 },
            { label: '3 Use (Qty) Ad Promotion', value: 3 },
            { label: '4 Use (Qty) Ad Promotion', value: 4 },
            { label: '5 Use (Qty) Ad Promotion', value: 5 },
            { label: '6 Use (Qty) Ad Promotion', value: 6 },
        ],
        extra_section: { label: '1 Use (Qty) Ad Promotion', price: '$ 10' }
    }
]

export default function ExtraAdditions() {
    const classes = useStyles()
    const [addValue, setAddValue] = useState(0)
    const [highlightsValue, setHighlightsValue] = useState(0)

    const handleChange = (el, value) => {
        el === 'ad' && setAddValue(value)
        el === 'highligh' && setHighlightsValue(value)
    }

    return (
        <Grid container className={classes.root} justifyContent={'space-between'}>

            <Grid container p={0} mb={2} >
                {SELECT_DATA.map((el, ind) => (
                    <Grid container p={1} key={ind} spacing={1} alignItems={'center'}>
                        <Grid item flexGrow={1}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">{el.label}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={el.name === 'ad' ? addValue : highlightsValue}
                                    label={el.label}
                                    onChange={(e) => handleChange(el.name, e.target.value)}
                                >
                                    <MenuItem value={0}> {el.label}</MenuItem>
                                    {el.options.map((option, key) => (
                                        <MenuItem value={option.value} key={key}>{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item flexGrow={1} align='center'>
                            <Typography color={'primary'}> | </Typography>
                        </Grid>

                        <Grid item p={0} flexGrow={1} >
                        <Grid container p={0}>
                            <Grid item flexGrow={1}>
                                <Typography variant="caption" color={'gray'}>

                                    {el.extra_section.label}
                                </Typography>
                            </Grid>
                            <Grid item flexGrow={1}>
                                <Typography variant="body1">
                                    {el.extra_section.price}
                                </Typography>
                            </Grid>
</Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            <Grid container p={1} pl={2}>
                <Typography variant="body1">Total:</Typography>
            </Grid>
            <Grid container p={0} pl={2}>
                <Grid item xs={6} align='start'>
                    <Button variant="outlined" className={classes.totalPriceBtn}>
                        {`${highlightsValue * 10 + addValue * 4} USD`}
                    </Button>
                </Grid>
                <Grid item xs={6} align="end">
                    <Button variant="contained" >Buy / Add Additions</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}