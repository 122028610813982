import { Grid } from "@mui/material"
import PartA from "./TourismPartA"
import PartB from "./TourismPartB"

export default function TourismFilter() {
    return (
        <Grid container p={0} justifyContent={'space-evenly'} alignItems={'center'} mt={1}>
            <PartA />
            <Grid item alignSelf={'normal'} mb={1} mt={1} className='filter_line '></Grid>
            <PartB />
        </Grid>

    )
}