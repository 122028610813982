import { makeStyles } from "@mui/styles";
import { tokens } from '../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            background: colors.black.DEFAULT,
            height: '100%',
            '& .MuiPaper-root': {
                width: '100%',
                height:'100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px 0 0 8px'
            },
            
        },

        unconfirmedBlock: {
            position: 'relative',
            borderRadius: '10px'
        },

        unconfirmedBlockInfo: {
            borderTop: `1px solid ${colors.primary.DEFAULT}`,
            borderBottom: `1px solid ${colors.primary.DEFAULT}`,
            background: `${colors.secondary.DEFAULT}`
        },
        unconfirmedBlockBtn: {
            borderTop: `1px solid ${colors.primary.DEFAULT}`,
            borderBottom: `1px solid ${colors.primary.DEFAULT}`,
            borderRight: `1px solid ${colors.primary.DEFAULT}`,
            borderRadius: '0 10px 10px 0',
            background: colors.secondary.DEFAULT,
            '& .MuiButtonBase-root': {
                color: colors.white.DEFAULT,
                textTransform: 'capitalize',
                padding: '3px 5px !important',
                borderRadius: '10px',
                width: '100%'
            }

        },
        unconfirmedBtnCert: {
            background: `${colors.primary.DEFAULT} !important`,
            color: colors.white.DEFAULT,
            padding: '0 8px !important',
            position: 'absolute !important',
            bottom: '8px',
            left: '60%',
            borderRadius: '5px 5px 0 0 !important',
            textTransform: 'capitalize',
            fontWeight: 600,
            '& .Mui-disabled': {
                color: `${colors.gray.DEFAULT} !important`,
            }
        },
        noContentText: {
            color: colors.gray.DEFAULT,
            fontSize: '42px !important',
            fontWeight: `700 !important`
        }
    })
})