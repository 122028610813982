import * as React from 'react';
import {Grid, Switch, TextField, Typography, Button, Snackbar, Alert, FormControl, InputLabel,
    Select, MenuItem
} from "@mui/material";
import './quick_inquiry.css';
import api from '../../../../../api/api';
import { useForm, Controller } from 'react-hook-form'
import FormError from '../../../../AdminPanel/components/FormError/FormError';


export default function QuickInquiry() {
    const { register, control, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onSubmit" })
    const [openSuccess, setOpenSuccess] = React.useState(false);

    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccess(false);
    };


    const handleSend = async (form_data) => {

        await api(`/api/requests/`, 'POST',
            form_data,
            true // noAuth
        ).then(() => {
            reset()
            setOpenSuccess(true)
        })
    }

    return (
        <Grid container item xs={12} sm={11} pb={10} pt={4} mt={10} mb={15} pr={2} pl={2} className="container_question" justifyContent="center">
            <Grid item xs={12} mt={4} className="quick_text">QUICK INQUIRY</Grid>
            <Grid item xs={12} lg={5} mt={2} className="if_you_text">IF YOU HAVE ANY QUESTION? WE ARE GLAD TO CONSULT YOU AS SOON AS POSSIBLE</Grid>

            <form onSubmit={handleSubmit(handleSend)}>
                <Grid container item xs={12} sm={10} mt={4} p={0} justifyContent="center" alignItems="center" className="container_text_field">

                    <Grid container item p={0} xs={12} lg={9} alignItems="center" justifyContent='center'>
                        <Grid item className="input_quick_inquiry" xs={12} lg={6} align="start">
                            <TextField sx={{ width: '100%' }} size='small'
                                label="Your Name"
                                placeholder="Your Name"
                                {...register("sender", { required: true })}
                            />
                            <FormError error={errors?.sender} errors={errors} name={'sender'}/>
                        </Grid>
                        <Grid item className="input_quick_inquiry" xs={12} lg={6} align="end">
                            <TextField sx={{ width: '100%' }} size='small'
                                label="Phone Number"
                                placeholder="Phone Number"
                                {...register("phone_number", { required: true })}
                            />
                            <FormError error={errors?.phone_number} errors={errors} name={'phone_number'}/>
                        </Grid>

                        <Grid item className="input_quick_inquiry" xs={12} lg={6} align="start" mt={1}>
                            <TextField sx={{ width: '100%' }} size='small'
                                label="Email"
                                placeholder="Email"
                                {...register("email", {
                                    required: true,
                                    pattern: {
                                        message: 'Please enter valid email!',
                                        value: /^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@([-0-9A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/
                                    }
                                })}
                            />
                            <FormError error={errors?.email} errors={errors} name={'email'}/>
                        </Grid>

                        <Grid item className="input_quick_inquiry" xs={12} lg={6} align="end" mt={1}>
                            <TextField sx={{ width: '100%' }} size='small'
                                label="Type of estate"
                                placeholder="Type of estate"
                                {...register("type_of_estate", { required: true })}
                            />
                            <FormError error={errors?.type_of_estate} errors={errors} name={'type_of_estate'}/>
                        </Grid>


                        <Grid item xs={12} lg={4} align="start" mt={1} className="input_quick_inquiry">
                            <TextField sx={{ width: '100%' }} size='small' type='number'
                                label="Budget min"
                                placeholder="Budget min"
                                {...register("budget_min", {
                                    required: true,
                                    valueAsNumber: true,
                                    min: { value: 0, message: 'The number must be positive!' }
                                })}
                            />
                            <FormError error={errors?.budget_min} errors={errors} name={'budget_min'}/>
                        </Grid>

                        <Grid item xs={12} lg={4} align="center" mt={1} className="input_quick_inquiry">
                            <TextField sx={{ width: '100%' }} size='small' type='number'
                                label="Budget max"
                                placeholder="Budget max"
                                {...register("budget_max", {
                                    required: true,
                                    valueAsNumber: true,
                                    min: { value: 0, message: 'The number must be positive!' }
                                })}
                            />
                            <FormError error={errors?.budget_max} errors={errors} name={'budget_max'}/>
                        </Grid>

                        <Grid item xs={12} lg={4} align="end" mt={1} className="input_quick_inquiry">
                            <FormControl sx={{ width: '100%' }} size="small">
                                <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                                <Select

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Currency"
                                    {...register('currency', { required: true })}
                                >   

                                    <MenuItem value={'USD'}>USD</MenuItem>
                                    <MenuItem value={'EUR'}>EUR</MenuItem>
                                </Select>
                            </FormControl>
                            <FormError error={errors?.currency} errors={errors} name={'currency'}/>
                        </Grid>

                        <Grid item xs={12} mt={2} className="textarea_quick_inquiry">
                            <TextField sx={{ width: '100%' }} size='small'
                                label="Description"
                                placeholder="Description"
                                multiline minRows={5}
                                {...register("description", { required: true })}
                            />
                            <FormError error={errors?.description} errors={errors} name={'description'} />
                        </Grid>


                    </Grid>

                    <Grid container item p={1} xs={12} lg={3} alignItems="center" justifyContent='center'>
                        <Grid item className="btn_submit_quick_inquiry_div">
                            <Button type='submit'>
                                <a className="btn btn_color " data-animation="fadeInUp" data-delay=".6s">
                                    <div className="btn_m">
                                        <div className="btn_c">
                                            <div className="btn_t1">I want invest</div>
                                            <div className="btn_t2">Send message</div>
                                        </div>
                                    </div>
                                </a>
                            </Button>

                        </Grid>
                        <Grid container item xs={12} justifyContent="center" p={0}>
                            <Grid item align="center" mt={1}>
                                <Typography variant="body1" className="accept_terms_text">I accept
                                    <span className="accept_terms_span"> Terms & Conditions</span> for processing personal
                                    data</Typography>
                            </Grid>


                            <Grid item >
                                <Controller
                                    name="checkbox"
                                    control={control}
                                    defaultValue={false}
                                    rules={{ required: 'Field is required!' }}
                                    render={({ field: { onChange, value } }) => (
                                        <Switch
                                            color="primary"
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value} />
                                    )}
                                />
                                <FormError error={errors?.checkbox} errors={errors} name={'checkbox'}/>

                            </Grid>

                        </Grid>


                    </Grid>

                </Grid>
            </form>
            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleCloseSuccess}
                message="Note archived"
            >
                <Alert severity="success">Your requests has been sent!</Alert>
            </Snackbar>
        </Grid>

    )
}