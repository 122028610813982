import { Grid, Card, CardContent, Typography, Button } from "@mui/material"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { useState } from "react"
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from "react-redux";
import { selectProject, deleteMedia, updateMediaPosition } from "../../../../../../features/projects/projectsSlice";
import ReactPlayer from "react-player";
import { styled } from '@mui/material/styles';
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import './sort_images.css'

const VisuallyHiddenInput = styled('input')({
    height: 1,
    width: 1,
});

export default function DragCards({ cardList, setCardList, sliderValue }) {
    const project = useSelector((state) => selectProject(state, 'edit'))
    const { resetField, watch, setValue, formState:{ dirtyFields} } = useFormContext()
    const [currentCard, setCurrentCard] = useState(null)
    const dispatch = useDispatch()

    function dragStartHandler(e, card) {
        setCurrentCard(card)
    }

    function dragOverHandler(e) {
        e.preventDefault()
    }

    function dropHandler(e, card) {
        e.preventDefault()
        if (e.position !== 10 && card.position !== 10) {
            setCardList(cardList.map(c => {
                if (c.id === card.id) {
                    return { ...c, position: currentCard.position }
                }

                if (c.id === currentCard.id) {
                    return { ...c, position: card.position }
                }
                return c
            }
            ))
            enqueueSnackbar('The positions of the pictures have been updated!', { variant: 'success', style: { background: '#8d724d' } })
            let card_pr = project?.media?.find(item => item.file === card.url)
            let curr_card_pr = project?.media?.find(item => item.file === currentCard.url)
            let media = watch('media')

            if (card_pr){
                dispatch(updateMediaPosition({'position': currentCard.position, 'media_id': card_pr.id, 'project_id': project.id}))
            }
            else {
                let form_el = media?.findIndex(item => item.file.name === card.real_url)
                if (form_el !== -1) {
                    media[form_el].position = currentCard.position
                    setValue('media', [...media])
                } 
            }

            if (curr_card_pr) {
                dispatch(updateMediaPosition({'position': card.position, 'media_id': curr_card_pr.id, 'project_id': project.id}))
            }
            else {
                let form_el = media.findIndex(item => item.file.name === currentCard.real_url)
                if (form_el !== -1) {
                    media[form_el].position = card.position
                    setValue('media', [...media])
                } 
            }
        }
    }

    const sortCards = (a, b) => {
        if (a.position > b.position) {
            return 1
        } else {
            return -1
        }
    }

    function delete_card(card) {
        if (card.real_url !== '') {
            let data =  [...watch('media')]
            let card_id = data.findIndex(item => item.position === card.position)
            data.splice(card_id, 1)

            data.length > 0
                ? setValue('media', [...data])
                : resetField('media', { keepDirtyValues: false, defaultValue: project?.media })
        }
        else {
            let media_file = project?.media.find(el => el.file === card.url)
            if (media_file) {
                dispatch(deleteMedia({ 'media_id': media_file.id, 'project_id': media_file.project_id }))
            }
        }
    }

    function uploadImage(e, card) {
        if (!dirtyFields.media){
            setValue('media', [{file: e.target.files[0], position: card.position, size: e.target.files[0].size, type: e.target.files[0].type}], {shouldDirty: true, shouldValidate: true})
        }
        else {
            let data = [...watch('media')]
            data.push({file: e.target.files[0], position: card.position, size: e.target.files[0].size, type: e.target.files[0].type})
            setValue('media', [...data], {shouldDirty: true})
        }
    }

    function changeSliderImage(card){
        let new_card_list = {}
        Object.keys(cardList).forEach(key => {
            if (cardList[key].url !== '') {
                new_card_list[Object.keys(new_card_list).length] = cardList[key];
            }
        });
        let ind = Object.keys(new_card_list).findIndex(item => new_card_list[item].url === card.url)

        if (ind !== -1){
            sliderValue.slickGoTo(ind)
        }
    }

    return (

        <Grid container item xs={12} mt={2} className='sort_container' justifyContent={'center'}>
            {cardList.sort(sortCards).map((card, index) => (
                <Grid item  mt={1} p={1} alignContent={'center'} alignSelf={'stretch'} draggable={card.position !== 10 ? true : false}
                    onDragStart={(e) => dragStartHandler(e, card)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, card)}
                    onClick={() => changeSliderImage(card)}
                    width={'200px'}
                    height={'200px'}
                >

                    <Grid container item p={0} className="card_drag" >

                        {card.url !== ''
                            ? card.position !== 10
                                ? <Grid item sx={{backgroundImage: `url(${card.url})`}} className="img_blog_drag_card"></Grid>
                                : <Grid item > <ReactPlayer width='100%' height={150} url={card.url} /></Grid>
                            :
                            <CardContent sx={{ padding: '15px' }}>
                                <Grid container p={1} justifyContent={'center'}>
                                    <Grid item xs={10}>
                                        <Typography align="center" className="number_card_text">
                                            {index + 1}
                                        </Typography>
                                    </Grid>
                                    <Grid item={12}>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            className="upload_btn"
                                            tabIndex={-1}
                                            
                                        >
                                        
                                            <AddBoxRoundedIcon className="add_svg" /> {card.position !== 10 ? 'Upload Image' : 'Upload Video'}
                                            <VisuallyHiddenInput type="file" accept={card.position === 10 ? 'video/*' : 'image/*'} onChange={(e) => uploadImage(e, card)}/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>

                        }

                        <Button className='cross_btn' onClick={() => delete_card(card)}>
                            <CloseRoundedIcon />
                        </Button>
                    </Grid>
                    <SnackbarProvider autoHideDuration={2000} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} />

                </Grid>

            ))}
        </Grid>

    )
}