import { Grid } from "@mui/material";
import PartA from "./RealEstatePartA";
import PartB from "./RealEstatePartB";

export default function RealEstateFilter() {
    return (
        <Grid container p={0} justifyContent={'space-evenly'} alignItems={'center'} mt={1}>

            <PartA />
            <Grid item alignSelf={'normal'} mb={1} mt={1} className='filter_line '></Grid>
            <PartB />

        </Grid>
    )
}