import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            background: colors.secondary.DEFAULT,
            borderRadius: '10px',
            height: 'inherit',
            overflow: 'auto',
        },
        navigateBlock: {
            zIndex: 1,
            maxWidth: '40px',
            height: 'auto',
            borderRadius: '10px',
            '& .MuiButtonBase-root': {
                border: `1px solid ${colors.primary.DEFAULT}`,
                borderRadius: '10px',
                height: '100%',
                padding: 'inherit'
            }
        },
        elBlock: {
            transition: '.5s',
            overflow: 'hidden'
        },

        elAccordion: {
            maxWidth: '0px !important',
            textAlign: '-webkit-center',
            opacity: 0,
        },
        elAccordionShow: {
            animation: '$show .5s forwards',
            maxWidth: '100% !important',
            flex: 'auto'
        },

        '@keyframes show': {
            '100%': {
                opacity: 1,
            }
        }
    })
})