import { Collapse, ListItemButton, ListItemIcon, ListItemText, List } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import React from 'react';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import TopicRoundedIcon from '@mui/icons-material/TopicRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { removeStorageAndGoToLogin } from '../../../../../api/api';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectChosenItem, selectCollapseItems, selectChosenCollapseItem, setCollapseItems } from '../../../../../features/admin_panel/adminPanelSlice';


const DROP_DATA = [
    {
        node_name: 'projects', label: 'Projects', icon: <TopicRoundedIcon />, child_nodes: [
            { node_name: 'my_projects', label: 'My Projects', path: '/admin_panel/projects/my_projects', icon: <HomeRepairServiceRoundedIcon /> },
            { node_name: 'confirmation_projects', label: 'Confirmation', path: '/admin_panel/projects/confirmation_projects', icon: <AssignmentTurnedInRoundedIcon /> }
        ]
    },
    {
        node_name: 'requests', label: 'Requests', path: '/admin_panel/requests', icon: <EmailRoundedIcon />
    },
    {
        node_name: 'pricing', label: 'Pricing', path: '/admin_panel/pricing', icon: <LocalOfferRoundedIcon />
    },
    {
        node_name: 'profile', label: 'Profile', icon: <AssignmentIndRoundedIcon />, child_nodes: [
            { node_name: 'profile', label: 'General Info', path: '/admin_panel/profile/profile', icon: <PersonIcon /> },
            { node_name: 'personal_details', label: 'Personal Details', path: '/admin_panel/profile/personal_details', icon: <DisplaySettingsRoundedIcon /> },
            { node_name: 'security_settings', label: 'Security Settings', path: '/admin_panel/profile/security_settings', icon: <SecurityRoundedIcon /> },
            { node_name: 'packages', label: 'Packages', path: '/admin_panel/profile/packages', icon: <CorporateFareRoundedIcon /> },
            { node_name: 'general_settings', label: 'My Company', path: '/admin_panel/profile/general_settings', icon: <LocationCityRoundedIcon /> },
            { node_name: 'wallet', label: 'Wallet', path: '/admin_panel/profile/wallet', icon: <AccountBalanceWalletRoundedIcon /> },
        ]
    },
    {
        node_name: 'logout', label: 'Logout', icon: <ExitToAppRoundedIcon />
    }
]

export default function TreeMenu({swStatus}) {
    const dispatch = useDispatch()
    const chosenItem = useSelector(selectChosenItem)
    const collapseItems = useSelector(selectCollapseItems)
    const chosenCollapseItem = useSelector(selectChosenCollapseItem)

    const navigate = useNavigate()
    const classes = useStyles()

    return (
        <React.Fragment>
            <List component="nav" disablePadding className={classes.root}>
                {DROP_DATA.map((el, index) => (
                    <React.Fragment key={index}>
                        <ListItemButton className={[classes.navItem, chosenItem === el.node_name && classes.chosenNavItem].join(' ')} onClick={() => {
                            el.node_name === 'logout' && removeStorageAndGoToLogin()
                            el.child_nodes && dispatch(setCollapseItems({collapseItemKey: el.node_name, collapseItemValue: !collapseItems[el.node_name]}))
                            el.path && navigate(el.path)
                        }}>
                            <ListItemIcon>
                                {el.icon}
                            </ListItemIcon>
                            <ListItemText primary={el.label} />
                            {el.child_nodes && (collapseItems[el.node_name] ? <ExpandLess /> : <ExpandMore />)}
                        </ListItemButton>
                        {el.child_nodes && swStatus  &&
                            el.child_nodes.map((child, index) => (
                                <Collapse key={index} in={collapseItems[el.node_name]} timeout="auto" unmountOnExit>
                                    <List  component="div" disablePadding>
                                        <ListItemButton  className={[classes.collapseListItem, chosenCollapseItem === child.node_name && classes.chosenNavItem].join(' ')}
                                            sx={{ pl: 4 }} onClick={() => {
                                                navigate(child.path)
                                            }}>
                                            <ListItemIcon>
                                                {child.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={child.label} />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            ))
                        }
                    </React.Fragment>
                ))}
            </List>
        </React.Fragment>
    );
}