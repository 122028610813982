import { Grid } from "@mui/material";
import InvestorPriceList from "./components/InvestorPriceList";
import RealtorPriceList from "./components/RealtorPriceList";
import { useSelector } from "react-redux";
import { selectUserInfo } from '../../../features/admin_panel/adminPanelSlice'


export default function Pricing() {
    const userInfo = useSelector(selectUserInfo)

    return (
        <Grid container p={0} alignItems="flex-start" justifyContent='space-between' height={'100%'}>
            {(userInfo.have_access_like_realtor || userInfo?.is_staff) &&
                <RealtorPriceList user={userInfo} />
            }
            {(userInfo.have_access_like_investor ) &&
                <InvestorPriceList />
            }
        </Grid>
    )
}