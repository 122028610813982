import { Grid, Box, Tab } from "@mui/material"
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../../../features/admin_panel/adminPanelSlice'
import { useStyles } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Profile from './components/Profile/Profile'
import PersonalDetails from "./components/PersonalDetails/PersonalDetails";
import SecuritySettings from "./components/Security/SecuritySettings";
import GeneralSettings from "./components/GeneralSettings/GeneralSettings";
import Packages from "./components/Packages/Packages";

const TABS = [
    { name: 'profile', label: 'Profile', icon: <AccountCircleIcon /> },
    { name: 'personal_details', label: 'Personal Details', icon: <ReceiptLongIcon /> },
    { name: 'security_settings', label: 'Security Settings', icon: <LockPersonIcon /> },
    { name: 'packages', label: 'Packages', icon: <AccountCircleIcon /> },
    { name: 'general_settings', label: 'General Settings', icon: <AccountCircleIcon /> },
    { name: 'wallet', label: 'Wallet', icon: <AccountCircleIcon /> },
]

export default function MainProfile() {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const value = pathname.split('/').slice(-1)[0];

    const userInfo = useSelector(selectUserInfo)
    const classes = useStyles()

    const handleChange = (event, newValue) => {
        navigate(`/admin_panel/profile/${newValue}`)
    };

    return (
        <Grid container sx={{ padding: { xs: 1, md: 2 } }} className={classes.root} alignItems="flex-start" alignContent="baseline">
            <Grid sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value} centered>
                    <Box sx={{ borderBottom: 1, borderColor: '#BD9A68' }} >
                        <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable">
                            {TABS.map((tab, index) => (
                                <Tab key={index} label={tab.label} value={tab.name} icon={tab.icon} iconPosition="start" />
                            ))}
                        </TabList>
                    </Box>

                    <TabPanel value="profile" sx={{ padding: '30px 0', }}>
                        <Profile userInfo={userInfo} />
                    </TabPanel>

                    <TabPanel value="personal_details" sx={{ padding: '30px 0', }}>
                        <PersonalDetails />
                    </TabPanel>

                    <TabPanel value="security_settings" sx={{ padding: '30px 0', }}>
                        <SecuritySettings />
                    </TabPanel>

                    <TabPanel value="general_settings" sx={{ padding: '30px 0', }}>
                        <GeneralSettings />
                    </TabPanel>

                    <TabPanel value="packages" sx={{ padding: '30px 0', }}>
                        <Packages />
                    </TabPanel>

                </TabContext>
            </Grid>
        </Grid>
    )
}