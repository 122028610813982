import { Grid, Typography, Collapse } from '@mui/material';
import { useStyles } from './styles';
import { useState } from 'react';

export default function TabPanelGeneral({ data }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [shortText, setShortText] = useState(true)
    const classes = useStyles({ isExpanded })

    const DATA = [
        ['estate_category', 'Estate Category'], ['estate_type', 'Estate Type'],
        ['gross_size', 'Total Size'], ['state', 'Project State'], ['description', 'Description']
    ]

    const COLLAPSE_TIME = 800

    const get_data = (field) => {
        if (field === 'gross_size') {
            return data?.gross_size && data?.measure ? `${data?.gross_size} ${data?.measure}` : ''
        }
        let value = ''
        field === 'estate_category' && (value = data?.estate_category)
        field === 'estate_type' && (value = data?.estate_type)
        field === 'state' && (value = data?.state)
        field === 'description' && (value = data?.description)

        return value && value.replace(/_/g, ' ').replace(/ and /g, ' & ').replace(/\b\w/g, char => char.toUpperCase())
    }

    const handleExpand = (v) => {
        if (v === 'description') {
            setIsExpanded(!isExpanded)
            if (!shortText) {
                setTimeout(() => {
                    setShortText(!shortText)
                }, COLLAPSE_TIME)
            }
            else setShortText(!shortText)
        }
    }

    return (
        <Grid container xs={12} p={0} className={classes.root}>
            {DATA.map((row) => (
                Object.keys(data).includes(row[0]) &&
                <Grid container xs={12} p={0} mt={1} alignItems='flex-start'>
                    <Grid item xs={6}>
                        <Typography color='grey'>{row[1]}</Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <Collapse in={isExpanded} timeout={COLLAPSE_TIME} collapsedSize={row[0] === 'description' ? '45px' : '20px'} onClick={() => handleExpand(row[0])}>
                            <Typography color='white' className={[classes.text, row[0] === 'description' && shortText ? classes.collapsed : classes.expanded].join(' ')}>
                                {get_data(row[0])}
                            </Typography>
                        </Collapse>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}