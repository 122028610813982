import { Grid, Typography, Skeleton, Rating } from "@mui/material"
import EuroSharpIcon from '@mui/icons-material/EuroSharp';
import LocalFireDepartmentSharpIcon from '@mui/icons-material/LocalFireDepartmentSharp';
import { useSelector } from "react-redux";
import { selectUserLogo } from "../../../../../features/admin_panel/adminPanelSlice";
import { useStyles } from "./styles";

const Categories = {
    'business': 'Business', 'real_estate': 'Real Estate',
    'land_and_terrain': 'Land & Terrain', 'tourism_and_facility': 'Tourism & Facility'
}

export default function ProjectCard({ content, location, image_preview, purpose = '', details = {} }) {
    const classes = useStyles()
    const get_discount = () => {
        let price = content?.price
        let discount = content?.discount
        let discount_price = (price - (discount * price / 100)).toFixed(0)
        return `${discount_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    const user_logo = useSelector((state) => selectUserLogo(state))

    return (
        <Grid container p={0} className={classes.root} alignSelf={'normal'} alignContent={'space-between'} maxWidth={'340px'}>
            <Grid container p={0}>
                {!image_preview
                    ? <Skeleton variant="rounded" height={200} />
                    : <Grid container p={0} className={classes.imageBlog} sx={{ backgroundImage: `url(${image_preview})` }}></Grid>
                }

                <Grid container pl={1} className={classes.blockLogo} sx={{ width: 'auto' }} alignItems={'center'}>
                    <Grid item >
                        <Typography>
                            {Categories[content?.estate_category]}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ border: '1px solid #bd9a68' }} alignSelf={'normal'} ml={1} mr={1} mt={1} mb={1}></Grid>
                    {(content?.logo || user_logo) &&
                        <Grid item >
                            <img src={content?.logo ? content?.logo : user_logo} alt="logo" width='35px' />
                        </Grid>
                    }
                </Grid>

                <Grid container pl={2} pr={2} pt={1} >
                    <Grid item xs={12} >
                        {(!content?.name || !location)
                            ? <Skeleton variant="rounded" height={20} />
                            :
                            <Typography color='white' variant="h6" component="p" align="start">
                                {content?.name.slice(0, 22)} {content?.name.length > 22 && '...'} from {location?.country}, {location?.city}
                                {content?.is_urgent && <span> <LocalFireDepartmentSharpIcon className="urgent" /></span>}
                            </Typography>
                        }
                    </Grid>

                    <Grid item xs={12} alignSelf={'normal'}>
                        {!content?.description
                            ? <Skeleton variant="rounded" height={20} sx={{ margin: '10px 0' }} />
                            : <Typography color='grey' height={45} variant="body1" component="p" align="start">
                                {content?.description.slice(0, 60)} {content?.description.length > 60 && '...'}
                            </Typography>
                        }
                    </Grid>

                    <Grid item xs={12} align="start" pt={1}>
                        <Rating name="half-rating-read" defaultValue={purpose === 'preview' && 5} precision={1} readOnly />
                    </Grid>

                </Grid>
            </Grid>

            <Grid container pl={2} pr={2} pb={2}>
                {details?.flat_for_land_basis &&
                    <Grid item xs={12} >
                        <Typography fontSize={'20px'} color={'white'} align="start">(Flat for Land Basis)</Typography>
                    </Grid>
                }

                {content?.discount &&
                    <Grid item xs={12} className={classes.discountPriceCard}>
                        <Typography variant="h5" component="p" align="start">
                            {content?.currency === 'USD' && <span>$</span>}
                            {content?.currency === 'EUR' && <EuroSharpIcon />}
                            {`${content?.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </Typography>
                    </Grid>
                }

                <Grid item xs={12} className="price_project_card">
                    {!content?.price && !details?.flat_for_land_basis
                        ? <Skeleton variant="rounded" height={20} />
                        : <Typography color='white' variant="h5" component="p" align="start">
                            {content?.currency === 'USD' && !details?.flat_for_land_basis && '$'}
                            {content?.currency === 'EUR' && !details?.flat_for_land_basis && <EuroSharpIcon />}
                            {content?.discount ? get_discount() : !details?.flat_for_land_basis && `${content?.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </Typography>
                    }
                </Grid>
            </Grid>

        </Grid>
    )
}