
export const CHECKOUT_DATA = {
    "data": {
        "type": "checkouts",
        "attributes": {
            "product_options": {
                
            },
            'checkout_data': {
        
            }
        },
        "relationships": {
            "store": {
                "data": {
                    "type": "stores",
                    "id": process.env.REACT_APP_LEMON_SQUEEZY_STORE_ID
                }
            },
            "variant": {
                "data": {
                    "type": "variants",
                    "id": process.env.REACT_APP_LEMON_SQUEEZY_VARIANT_ID
                }
            }
        }
    }
}