import { makeStyles } from "@mui/styles";
import { tokens } from '../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            height: '100%',
            background: colors.black.DEFAULT
        },
        contentBlock: {
            background: colors.black.DEFAULT,
            overflow: 'auto',
            borderRadius: '10px',
            height: '100%'
        }
        
    })

})