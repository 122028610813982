import { makeStyles } from "@mui/styles";
import { tokens } from '../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            background: colors.secondary.DEFAULT,
            borderRadius: '10px',
            height: '100%',
            overflow: 'auto',
            '& .MuiTab-root': {
                textTransform: 'capitalize',
            },
            '& .MuiTab-root.Mui-selected': {
                color: colors.primary.DEFAULT,
                borderBottom: '2px solid transparent',
                animation: `$selected 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards`
            },
        },
        alertBlog: {
            border: `1px dashed ${colors.primary.DEFAULT}`,
            borderRadius: '10px'
        },
        '@keyframes selected': {
            '100%': {
                borderBottom: `2px solid ${colors.primary.DEFAULT}`,
            },
        },
    })
})