import * as React from 'react';
import {Box, Grid, Typography, CircularProgress} from "@mui/material";
import ToolBar from '../../HomePage/components/ToolBar/ToolBar';
import Footer from '../../HomePage/components/Footer/Footer';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import Slider from './components/Slider.jsx';
import Property from '../../HomePage/components/Property/Property';
import png2 from '../../../../assets/images/projects/png2.png';
import png3 from '../../../../assets/images/projects/png3.png';
import QuickInquiry from "../../HomePage/components/QuickInquiry/quick_inquiry";
import {selectProjectId, selectAllProjects, fetchProjects} from '../../../../features/projects/projectsSlice'
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import './slider.css';


export default function ProjectInfo() {
    const dispatch = useDispatch()
    const params = useParams()
    const projectId = params.projectId
    const projects = useSelector(selectAllProjects)

    useEffect(() => {
        if (projects.length === 0) {
            dispatch(fetchProjects())
        }

    }, [dispatch, projects])

    const project = useSelector((state) => selectProjectId(state, projectId))


    if (!project) {
        return (
            <Grid container justifyContent='center' alignItems='center' style={{'height': '100%'}}>
                <CircularProgress/>
            </Grid>
        )
    }

    return (
        <Box>
            <ToolBar page="projects" mode="dark"/>
            <Grid container mb={6} >
                <Grid container item mt={20} xs={12} md={7} sx={{padding: {xs:0}}}>
                    <Grid item xl={12} sm={12}>
                        <Typography variant='body1' className="title_project">
                            {project.address}
                        </Typography>
                    </Grid>
                    <Grid item xl={12} sm={12}>
                        <Typography variant='body1' className="name_project">
                            {project.name}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Typography variant="body1" className="description_project">
                        {project.description}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container mb={4} p={1} justifyContent="center">
                <Grid container item xs={12} sm={6} md={4} lg={2} p={0} mb={4} justifyContent="center" alignItems="center">
                    <Grid item xs={2} md={4} align='end'>
                        <CropRotateIcon sx={{fontSize: 45}}/>
                    </Grid>
                    <Grid item xs={10} sm={6} md={8}>
                        <Typography className="property_text_project" pl={3}>
                            PROPERTY SIZE
                        </Typography>
                        <Typography className="property_text_project" pl={3}>
                            {project.property_size} {project.measure_es.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} md={4} lg={2} p={0} mb={4} justifyContent="center" alignItems="center">
                    <Grid item xs={2} md={4} align='end'>
                        <CurrencyExchangeIcon sx={{fontSize: 45}}/>
                    </Grid>
                    <Grid item xs={10} sm={6} md={8}>
                        <Typography className="property_text_project" pl={3}>
                            PRICE RANGE
                        </Typography>
                        <Typography className="property_text_project" pl={3}>
                            {project.price_from} - {project.price_up_to}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} md={4} lg={2} p={0} mb={4} justifyContent="center" alignItems="center">
                    <Grid item xs={2} md={4} align='end'>
                        <ApartmentIcon sx={{fontSize: 45}}/>
                    </Grid>
                    <Grid item xs={10} sm={6} md={8}>
                        <Typography className="property_text_project" pl={3}>
                            TYPE -
                        </Typography>
                        <Typography className="property_text_project" pl={3}>
                            {project.type_estate.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} md={4} lg={2} p={0} mb={4} justifyContent="center" alignItems="center">
                    <Grid item xs={2} md={4} align='end'>
                        <DomainDisabledIcon sx={{fontSize: 45}}/>
                    </Grid>
                    <Grid item xs={10} sm={6} md={8}>
                        <Typography className="property_text_project" pl={3}>
                            FLAT SIZE
                        </Typography>
                        <Typography className="property_text_project" pl={3}>
                            {project.flat_size} {project.measure_es.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} md={4} lg={2} p={0} mb={4} justifyContent="center" alignItems="center">
                    <Grid item xs={2} md={4} align='end'>
                        <PrecisionManufacturingIcon sx={{fontSize: 45}}/>
                    </Grid>
                    <Grid item xs={10} sm={6} md={8}>
                        <Typography className="property_text_project" pl={3}>
                            STATUS
                            {' '+project.status.toUpperCase()}
                        </Typography>

                    </Grid>
                </Grid>

            </Grid>

            <Box mt={10} className='project_list_site_part' >
                <Slider images={project.project_images} />
            </Box>

            <Property/>

            <Grid container xs={12} lg={10} justifyContent="center" alignItems='center'>
                <Grid item xs={12} md={7} mb={5} align="center">
                    <img width="462" height="640" src={png2} alt='pic'/>
                </Grid>
                <Grid container item xs={12} md={5}>
                    <Grid item xs={12}>
                        <Typography variant="body1" className="property_text">
                            Baltimore, MD
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className="property_text_bold">
                            8601 Honeygo Boulevard
                        </Typography>
                    </Grid>

                    <Grid container item  xs={12} mt={6} pl={0} justifyContent="space-between">
                        <Typography variant="body1">
                            <span className="property_text_1">View on Sea: </span>{project.view_on_sea ? 'Yes' : 'No'}
                        </Typography>

                        <Typography variant="body1">
                            <span className="property_text_1">View on Mountain: </span>{project.view_on_mountain ? 'Yes' : 'No'}
                        </Typography>
                    </Grid>


                </Grid>
            </Grid>

            <Grid container justifyContent="center" mt={10}>
                <img className='just_info_project_draft_img' src={png3}/>
            </Grid>

            <Grid container p={0}>
                <Grid container item justifyContent="center" p={0}>
                    <QuickInquiry/>

                </Grid>
            </Grid>


            <Footer/>
        </Box>
    )
}

