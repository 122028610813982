import { Grid, Paper, IconButton, Typography } from "@mui/material"
import ChangePassword from "./components/ChangePassword"
import ConfirmedInfo from "./components/ConfirmedInfo"
import EmailIcon from '@mui/icons-material/Email';
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../../../features/admin_panel/adminPanelSlice";
import { useStyles } from "./styles"

export default function SecuritySettings() {
    const userInfo = useSelector(selectUserInfo)
    const classes = useStyles()

    return (
        <Grid container alignItems="flex-start" justifyContent='space-evenly' className={classes.root} p={1}>
            <Grid container item p={0} pt={2} pb={2} xs={12} sx={{ mb: { xs: 2, lg: 0 } }}
                component={Paper} className={classes.cardBlock} justifyContent='space-evenly' alignItems={'start'}>
                <Grid item xs={12} md={6} mt={1}>
                    <ChangePassword />
                </Grid>
                <Grid item xs={12} md={6} mt={1}>
                    <ConfirmedInfo userInfo={userInfo} />
                </Grid>
                <Grid item xs={12} mt={4}>
                    <Grid container alignItems={'center'} className={classes.emailIconBtn}>
                        <Grid item mr={1} >
                            <IconButton color="primary" >
                                <EmailIcon />
                            </IconButton>
                        </Grid>
                        <Grid item ><Typography color={'primary'}>{
                            userInfo?.company?.contact_email ? userInfo?.company?.contact_email : 'no company email'}
                        </Typography></Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}