import { Grid, Typography, Button, Collapse } from '@mui/material'
import CantProcessTrans from '../../components/CantProcessTrans/CantProcessTrans';
import { useState, useEffect, useRef } from 'react'
import { useStyles } from '../styles';
import { InvestorTariffs } from './constants';
import PackageCard from './PackageCard';

export default function InvestorPriceList() {
    const scrollableRef = useRef(null);
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    const handlePurchase = () => {
        setOpen(true)
    }

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                if (scrollableRef.current && open) {
                    scrollableRef.current.scrollTop = 0;
                }
            }, 1000)

            setTimeout(() => {
                open && setOpen(false)
            }, 5000)
        }
    }, [open])

    return (
        <Grid container p={3} className={classes.root} alignContent={'baseline'} ref={scrollableRef}>
            <Grid container p={0} pl={1} mb={1}>
                <Typography color={'white'} variant='h4'>
                    Packages for Investors
                </Typography>
            </Grid>

            <Grid container p={0} justifyContent={'center'}>
                <Collapse in={open} timeout={10000}>
                    <CantProcessTrans />
                </Collapse>
            </Grid>

            <Grid container p={0} justifyContent={'center'} >
                {InvestorTariffs.map((el, index) => (
                    <Grid container item xs={12} md={6} lg={4} p={1} alignSelf={'stretch'} key={index} >
                        <PackageCard properties={el.properties} price={el.price} label={el.label} description={el.description} duration={el.duration} logo={el.logo} maxWidth={'480px'} />
                        <Grid item xs={12} align={'center'} mt={2}>
                            <Button variant='contained' onClick={handlePurchase}>
                                Purchase
                            </Button>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}