import { Grid, Paper, Button } from '@mui/material';
import Advert from './ui/Advert';
import Category from './ui/Category';
import Media from './ui/Media';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function General({ activeStep, setActiveStep, setCompleted, project, purpose }) {
    const [estate_type, setEstateType] = useState()
    const {trigger} = useFormContext()


    const  check_for_next = async () => {
        let gen = await trigger('general') 

        if (gen) {
            if (await trigger('media')) {
                if (await trigger('drawings')) {
                    if (await trigger('certificates'))
                        setActiveStep(activeStep+1)
                        setCompleted({0: true})
                }
            }
        }  
    }

    return (
        <Grid container p={2} component={Paper}
            className={activeStep !== 0 ? 'wrap_tab_container display_none' : 'wrap_tab_container'} alignItems='baseline'>
            <Category estate_type={estate_type} setEstateType={setEstateType} project={project}/>
            <Advert project={project}/>
            <Media project={project} purpose={purpose}/>
            <Grid item xs={12} align='end'>
                <Button onClick={check_for_next} 
                    className='next_btn'>NEXT</Button>
            </Grid>
        </Grid>
    )
}