import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            background: colors.secondary.DEFAULT,
            position: 'relative',
            margin: '5px 20px 15px',
            borderRadius: '5px',
            boxShadow: `0 0 30px 0 ${colors.black.DEFAULT}`,
            '& p': {
                wordWrap: 'break-word'
            }
        },
        blockLogo: {
            color: colors.white.DEFAULT,
            position: 'absolute',
            top: 0,
            right: 0,
            background: colors.secondary.DEFAULT,
            padding: '0px 12px',
            borderRadius: '0 0 0 10px',
            '& img': {
                scale: 1.5
            }
        },
        imageBlog: {
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '200px'
        },
        discountPriceCard: {
            color: '#ffffff9f',
            fontSize: '22px',
            marginTop: '5px',
            lineHeight: 'normal',
            textDecoration: `line-through solid ${colors.tomato.DEFAULT}`,
            '& span:first': {
                textDecoration: 'none'
            }
        },
        priceProjectCard: {
            '& svg': {
                verticalAlign: 'text-top',
                paddingRight: '3px',
            }
        },
        urgent: {
            '& path:nth-child(1)': {
                color: 'orange'
            },
            '& path:nth-child(3)': {
                color: colors.tomato.DEFAULT
            }
        }
    })
})