import { Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from "react-redux";
import { putFilterData, selectProjectFilters } from "../../../../../../features/projects/projectsSlice";
import React from "react";

const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator="."
                decimalSeparator=','
                valueIsNumericString
            />
        );
    },
);

export default function FilterMeasure() {
    const dispatch = useDispatch()
    const filters = useSelector(selectProjectFilters)

    return (
        <Grid container item xs={12} md={2} p={1} spacing={1} alignSelf={'center'} >
            <Grid item xs={12} pr={1} mb={1}>
                <Typography color={'white'}>Size Range</Typography>
            </Grid>

            <Grid container pl={1} pr={1} alignItems={'center'}>
                <Grid item xs={12} mb={1}>
                    <TextField fullWidth size='small' InputProps={{ inputComponent: NumericFormatCustom }}
                        onChange={(e) => dispatch(putFilterData({ 'field': 'gross_size__gte', 'data': e.target.value }))}
                        defaultValue={filters?.gross_size__gte}
                        className='tab_text_field' label="from" variant="outlined" />
                </Grid>

                <Grid item xs={12}>
                    <TextField fullWidth size='small' InputProps={{ inputComponent: NumericFormatCustom }}
                        onChange={(e) => dispatch(putFilterData({ 'field': 'gross_size__lte', 'data': e.target.value }))}
                        defaultValue={filters?.gross_size__lte}
                        className='tab_text_field' label="to" variant="outlined" />
                </Grid>
            </Grid>

            <Grid item xs={12} pr={1} mb={1}>
                <Typography color={'white'}>Measure</Typography>
            </Grid>

            <Grid container xs={12} pl={1} pr={1}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Measure</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Measure"
                        className='tab_text_field'
                        onChange={(e) => dispatch(putFilterData({'field': 'measure', 'data': e.target.value}))}
                        defaultValue={filters?.measure}
                    >
                        <MenuItem value={''}>Choose Measure</MenuItem>
                        <MenuItem value={'sq.m'}>sq.m</MenuItem>
                        <MenuItem value={'hec'}>hec</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}