import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';


export const RootTariff = {
    label: 'Root Package', price: 0, duration: 'infinity',
    description: 'Root Package for owner of Go4Invest company!',
    properties: [
        { label: 'Can Add Unlimited Ads (for All Estate Types)', active: true, name: 'ads_amount', value: 0 },
        { label: 'Highlight - Promotion (Unlimited) Ad', active: true, name: 'highlights', value: 10 },
        { label: 'Customer and Target Market Analysis ', active: true, name: 'support', value: true },
    ]
}

export const InvestorTariffs = [
    {
        label: 'Starter Package', price: 299, duration: 3,
        logo: <StarBorderIcon fontSize="inherit" />,
        description: 'Basic plan for premium usage, By purchasing this plan, you can access real estate portfolios and all investment projects, and receive statistical information and analysis support about the investment and real estate market.',
        properties: [
            { label: 'Full Access to All Real Estate Portfolios', active: true },
            { label: 'Access to Private Investment Projects', active: true },
            { label: 'Analysis and Statistical Data Support', active: true },
            { label: 'Express and Priority Request Privilege', active: false },
            { label: 'VIP Access for Urgent - Discounted Sales', active: false },
            { label: 'Direct Contact for All Your Requests', active: false },
        ]
    },
    {
        label: 'Basic Package', price: 499, duration: 6,
        logo: <WorkspacePremiumIcon fontSize="inherit" />,
        description: 'You can create priority requests for opportunities you find or projects you are interested in, get priority access to all services including support center and act faster. ',
        properties: [
            { label: 'Full Access to All Real Estate Portfolios', active: true },
            { label: 'Access to Private Investment Projects', active: true },
            { label: 'Analysis and Statistical Data Support', active: true },
            { label: 'Express and Priority Request Privilege', active: true },
            { label: 'VIP Access for Urgent - Discounted Sales', active: false },
            { label: 'Direct Contact for All Your Requests', active: false },
        ]
    },
    {
        label: 'Pro Package', price: 899, duration: 12,
        logo: <EmojiEventsOutlinedIcon fontSize="inherit" />,
        description: ' By purchasing the highest premium package, you will have full VIP access, make your moves faster, easier and on time, view exclusive opportunities and monitor your investments with precise reviews and analysis, and use all privileges for a year.',
        properties: [
            { label: 'Full Access to All Real Estate Portfolios', active: true },
            { label: 'Access to Private Investment Projects', active: true },
            { label: 'Analysis and Statistical Data Support', active: true },
            { label: 'Express and Priority Request Privilege', active: true },
            { label: 'VIP Access for Urgent - Discounted Sales', active: true },
            { label: 'Direct Contact for All Your Requests', active: true },
        ]
    }
]

export const RealtorTariffs = [
    {
        label: 'Starter Plan', price: 49,
        description: 'This package and its features are valid for 1 Months',
        properties: [
            { label: 'Can Add 10 Ads (for All Estate Types)', active: true, name: 'ads_amount', value: 10 },
            { label: 'Highlight - Promotion for Ads', active: false, name: 'highlights', value: 0 },
            { label: 'Customer and Target Market Analysis ', active: false, name: 'support', value: false },
        ]
    },
    {
        label: 'Basic Plan', price: 189,
        description: 'This package and its features are valid for 3 Months',
        properties: [
            { label: 'Can Add 15 Ads (for All Estate Types)', active: true, name: 'ads_amount', value: 15 },
            { label: 'Highlight - Promotion (1 Use) Ad', active: true, name: 'highlights', value: 1 },
            { label: 'Customer and Target Market Analysis ', active: false, name: 'support', value: false },
        ]
    },
    {
        label: 'Pro Plan', price: 329,
        description: 'This package and its features are valid for 3 Months',
        properties: [
            { label: 'Can Add 25 Ads (for All Estate Types)', active: true, name: 'ads_amount', value: 25 },
            { label: 'Highlight - Promotion (3 Use) Ad', active: true, name: 'highlights', value: 3 },
            { label: 'Customer and Target Market Analysis ', active: true, name: 'support', value: true },
        ]
    },
    {
        label: 'VIP Plan', price: 499,
        description: 'This package and its features are valid for 3 Months',
        properties: [
            { label: 'Can Add Unlimited Ads (for All Estate Types)', active: true, name: 'ads_amount', value: 0 },
            { label: 'Highlight - Promotion (10 Use) Ad', active: true, name: 'highlights', value: 10 },
            { label: 'Customer and Target Market Analysis ', active: true, name: 'support', value: true },
        ]
    }
]

export const FilterData = [
    {
        field: 'how_many_ads',
        label: 'How Many Ads',
        options: [
            { key: 5, value: '5 Ads (All Estate Types)' },
            { key: 10, value: '10 Ads (All Estate Types)' },
            { key: 15, value: '15 Ads (All Estate Types)' },
            { key: 20, value: '20 Ads (All Estate Types)' },
            { key: 25, value: '25 Ads (All Estate Types)' },
            { key: 30, value: '30 Ads (All Estate Types)' },
        ]
    },
    {
        field: 'valid_tome_period',
        label: 'Valid Time Period',
        options: [
            { key: 1, value: '1 Months Valid Period' },
            { key: 2, value: '2 Months Valid Period' },
            { key: 3, value: '3 Months Valid Period' },
            { key: 6, value: '6 Months Valid Period' },
            { key: 12, value: '12 Months Valid Period' },
        ]
    },
    {
        field: 'highlight_qty',
        label: 'Highlight Qty',
        options: [
            { key: 1, value: '1 Use (Qty) Ad Promotion' },
            { key: 2, value: '2 Use (Qty) Ad Promotion' },
            { key: 3, value: '3 Use (Qty) Ad Promotion' },
            { key: 4, value: '4 Use (Qty) Ad Promotion' },
            { key: 5, value: '5 Use (Qty) Ad Promotion' },
            { key: 6, value: '6 Use (Qty) Ad Promotion' },
        ]
    }
]