import {Grid, Typography} from '@mui/material';

const Location = [
    ['country', 'Country'], ['region', 'Region'], ['state', 'State'], ['city', 'City / Province'],
    ['zip_code', 'Zip Code'], ['full_address', 'Full Address']
]

export default function TabPanelLocation({data}) {

    return (
        <Grid container xs={12} p={0}>
            {Location.map((row) => (
                <Grid container xs={12} p={0} mt={1}  alignItems='flex-start'>
                    <Grid item xs={6}>
                        <Typography color='grey'>{row[1]}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color='white'>
                            {data[row[0]]}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}