import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            overflow: 'hidden',
            transition: '1s',
        },
        navItem: {
            borderRadius: '4px !important',
            paddingTop: '5px !important',
            paddingBottom: '5px !important',
            margin: '2px 0px 2px 0px !important',
            '&$chosenNavItem': {
                background: colors.secondary.DEFAULT,
            },
            '& .MuiTypography-root': {
                transition: '1s',
            }
        },
        collapseListItem: {
            borderRadius: '4px !important',
            paddingTop: '5px !important',
            paddingBottom: '5px !important',
            margin: '2px 0px 2px 0px !important',
        },
        chosenNavItem: {
            color: `${colors.primary.DEFAULT} !important`,
        },
    })

})