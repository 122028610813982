import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            overflow: 'auto',
            '& .fui-dropzone-root': {
                minHeight: '125px',
                minWidth: '125px',
                width: '125px',
                border: 'none',
                color: colors.white.DEFAULT,
                fontSize: '18px',
                fontWeight: '600',
                background: colors.gray[150],
            },
            '& .files-ui-file-mosaic-file-name': {
                display: "none"
            }
        },
        cardBlock: {
            background: `${colors.black.DEFAULT} !important`,
            border: `1px solid ${colors.primary.DEFAULT}`,
            borderRadius: '10px',
            
        },
        contentBlock: {
            border: `1px solid ${colors.primary.DEFAULT}`,
        }
    })
})