import { Grid, IconButton, Avatar, SpeedDial, SpeedDialAction, Link } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../../assets/images/basic/logo.png'
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../../features/admin_panel/adminPanelSlice';
import makeApiRequest, { removeStorageAndGoToLogin } from '../../../../api/api';
import DiamondRoundedIcon from '@mui/icons-material/DiamondRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useStyles } from './styles';


export default function NavBar({ funSw, isOpen }) {
    const navigate = useNavigate()
    const user_info = useSelector(selectUserInfo)
    const classes = useStyles()


    const logoutHandle = async () => {
        await makeApiRequest(`/auth/logout/`, 'POST', { refresh: localStorage.getItem('refresh_token') },)
            .then((response) => {
                removeStorageAndGoToLogin()
            }).catch((error) =>
                removeStorageAndGoToLogin()
            )
    }

    const actions = [
        { icon: <PowerSettingsNewRoundedIcon />, name: 'Logout' },
        { icon: <WbSunnyRoundedIcon />, name: 'Mode' },
        { icon: <NotificationsActiveRoundedIcon />, name: 'Notification' },
        { icon: <DiamondRoundedIcon />, name: 'Diamond' },
        { icon: <AccountCircleRoundedIcon />, name: 'Profile', path: '/admin_panel/profile/profile' }
    ];

    return (
        <Grid container className={classes.navBarWrap} justifyContent="space-between" p={0} pr={2} pl={2} alignItems='center' >
            <Grid container item p={0} xs={6} md={4} lg={4} alignItems="center" spacing={2}>
                <Grid container item p={0} pl={3} xs={8} sm={6} lg={4} >
                    <Link href="/" sx={{ cursor: 'pointer' }}>
                        <img src={logo} width="80px" alt='logo' className={classes.logoImage} />
                    </Link>
                </Grid>

                <Grid item xs={4} sm={6} lg={6}>
                    <IconButton
                        onClick={() => funSw(!isOpen)}
                        className={classes.switchAppBarBtn}
                        size="large"
                        edge="start"
                        aria-label="menu"
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container item xs={2} sx={{ position: 'relative' }}>
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    role='menu'
                    icon={
                        <Avatar alt="Remy Sharp" src={user_info?.avatar} variant="rounded" />
                    }
                    direction={'left'}
                    sx={{ position: 'absolute', right: 0, bottom: '-30px', }}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            onClick={() => {
                                action.path && navigate(action.path)
                                action.name === 'Logout' && logoutHandle()
                            }}
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                        />
                    ))}
                </SpeedDial>
            </Grid>
        </Grid>
    );
}