import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useStyles } from './styles';


export default function FormError({ error, errors, name, align_to_right=false }) {
    const [show, setShow] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        if (error) {
            const keys = Object.keys(errors);
            const index = keys.indexOf(name);
            index === 0 && setShow(true)
        }
    }, [error])

    return (
        <React.Fragment >
            {error && show &&
                <Card className={[classes.cardError, align_to_right && classes.toRight].join(' ')}>
                    <WarningIcon sx={{ color: '#bd9a68' }} />
                    <p>{error?.message === '' ? "Please fill in this field!" : error?.message}</p>
                </Card>
            }
        </React.Fragment>
    )
}