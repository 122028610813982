import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& .image-gallery': {
                maxWidth: '-webkit-fill-available'
            },
            '& .image-gallery-slides': {
                '& .image-gallery-slide': {
                    alignContent: 'center',
                    height: '400px',
                    '& div': {
                        width: 'auto'
                    },
                }
            },
            '& .image-gallery-image': {
                height: 'inherit'
            },
            '& .video-gallery-thumbnail-video': {
                all: 'unset',
                '& video': {
                    height: '30px !important',
                    width: 'auto',
                    verticalAlign: 'middle'
                }
            },
            '& .fullscreen': {
                '& .image-gallery-slide': {
                    height: '90vh !important',
                },
                '& .preview_video': {
                    height: '-webkit-fill-available !important'
                }
            },
            '& .image-gallery-svg': {
                stroke: colors.white.DEFAULT,
                maxWidth: '60px',
                maxHeight: '60px',
                '&:hover': {
                    stroke: colors.primary.DEFAULT,
                }
            },
            '& .image-gallery-thumbnail-image': {
                width: 'auto',
                height: '30px'
            },
            '& .image-gallery-thumbnail-inner': {
                textAlign: '-webkit-center'
            },
            '& .active': {
                border: `2px solid ${colors.primary.DEFAULT}`
            },
            '& .thumbImg:hover': {
                border: `2px solid ${colors.primary.DEFAULT} !important`
            },

        },
    })
})