import { Grid, Paper, Button, IconButton, Typography, Avatar } from "@mui/material"
import { Dropzone, FileItem } from "@dropzone-ui/react";
import React, { useState } from "react"
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, FormProvider, Controller } from "react-hook-form"
import { selectUserInfo } from "../../../../../features/admin_panel/adminPanelSlice";
import { useSelector, useDispatch } from "react-redux";
import Personal from "./components/Personal/Personal"
import Company from "./components/Company/Company"
import FormError from "../../../components/FormError/FormError";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import { removeCompanyLogo, removeUserAvatar, updateUserInfo, updateCompanyInfo } from "../../../../../features/admin_panel/adminPanelSlice";
import { only_image, file_size_validator } from "../../../../../validators/file_validators";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { useStyles } from "./styles"


export default function PersonalDetails() {
    const [personalStatus, setPersonalStatus] = useState(true)
    const methods = useForm({ mode: 'onChange' })
    const [files, setFiles] = useState([]);
    const [isOpen, setIsOpen] = useState(false)

    const userInfo = useSelector(selectUserInfo)
    const classes = useStyles()
    const dispatch = useDispatch()

    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };

    const changePersonalStatus = () => {
        setPersonalStatus(!personalStatus)
        setFiles([])
        methods.reset()
    }

    const deleteAvatarLogo = () => {
        dispatch(personalStatus ? removeUserAvatar() : removeCompanyLogo())
        setIsOpen(false)
    }

    const renderLogo = () => {
        const logo = personalStatus ? userInfo.avatar : userInfo.company.logo
        return (
            <React.Fragment>
                <Avatar alt="Person/Company Logo" variant="rounded" src={logo} />
                <IconButton size="small" onClick={() => setIsOpen(true)}><CloseIcon fontSize="small" color={'primary'} /></IconButton>
            </React.Fragment>
        )
    }

    const renderDropzone = () => {
        return (
            <Controller
                name={personalStatus ? 'avatar' : 'logo'}
                control={methods.control}
                rules={{
                    validate: {
                        image_size: v => file_size_validator(v) || 'The image file size must not exceed 1MB!',
                        image_type: v => only_image(v) || 'You can upload only image!',
                    },
                }}
                render={({ field: { onChange } }) => (
                    <Dropzone footer={false} header={false} maxFiles={1} onChange={(e) => {
                        setFiles(e)
                        onChange(e)
                    }}
                        value={files} label={personalStatus ? 'PROFILE PICTURE' : 'COMPANY LOGO'}>
                        {files.length > 0 &&
                            files.map((file) => (
                                <FileItem {...file} onDelete={removeFile} key={file.id} preview />
                            ))}
                    </Dropzone>
                )}
            />
        )
    }

    const handleSave = (data) => {
        data?.avatar && (data.avatar = data.avatar[0].file)
        data?.logo && (data.logo = data.logo[0].file)
        dispatch(
            personalStatus
                ? updateUserInfo({ 'data': data, 'userId': userInfo?.pk })
                : updateCompanyInfo({ 'data': data, 'companyId': userInfo.company?.pk })
        ).then(() => enqueueSnackbar('Information has been updated', { variant: 'success', style: { background: '#BD9A68' } })
        )
    }

    return (
        <Grid container p={1} className={classes.root}>
            <Grid container item p={0} pt={2} pb={2} xs={12} lg={8} xl={9} sx={{ mb: { xs: 2, lg: 0 } }}
                component={Paper} className={classes.cardBlock} justifyContent='space-evenly'>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSave)} >
                        {personalStatus ? <Personal /> : <Company />}
                        <Grid container item xs={12} justifyContent={'space-between'} spacing={2} mt={1}>
                            <Grid item >
                                <Grid container p={0} alignItems={'center'} className={classes.emailIconBtn}>
                                    <Grid item mr={1} >
                                        <IconButton color="primary" >
                                            <EmailIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item ><Typography color={'primary'}>{
                                        personalStatus
                                            ? userInfo?.email
                                            : userInfo?.company?.contact_email !== '' ? userInfo?.company?.contact_email : 'no company email'}
                                    </Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Button disabled={Object.keys(methods.formState.dirtyFields).length > 0 ? false : true} variant="contained" type="submit" sx={{ color: 'white' }}>
                                    Save Changes
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Grid>

            <Grid container item p={0} xs={12} lg={4} xl={3} justifyContent={'center'} alignSelf={'stretch'} alignContent={'space-between'}>
                <Grid item align={'center'} mb={1}>
                    <Paper elevation={1} className={classes.uploadPicBlock}>
                        {
                            personalStatus
                                ? userInfo?.avatar !== null
                                    ? renderLogo()
                                    : renderDropzone()
                                : userInfo?.company?.logo !== null
                                    ? renderLogo()
                                    : renderDropzone()
                        }
                        <FormError error={methods.formState.errors?.[personalStatus ? 'avatar' : 'logo']} errors={methods.formState.errors} name={personalStatus ? 'avatar' : 'logo'} align_to_right={true} />
                    </Paper>
                </Grid>

                <Grid container p={0} align='center' spacing={1}>
                    <Grid item xs={12}>
                        <Button variant='contained' className={!personalStatus ? classes.activeBtn : classes.passiveBtn}
                            onClick={!personalStatus ? changePersonalStatus : null}>
                            Personal Information
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' className={personalStatus ? classes.activeBtn : classes.passiveBtn}
                            onClick={personalStatus ? changePersonalStatus : null}>
                            Company Information
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <InfoDialog isOpen={isOpen} setIsOpen={setIsOpen} successFunction={deleteAvatarLogo} textType={personalStatus ? 'delete_avatar' : 'delete_logo'} />
            <SnackbarProvider autoHideDuration={2000} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} />
        </Grid>
    )
}