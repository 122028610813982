import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./styles";

export default function CancelPackages({setAction}) {
    const classes = useStyles()

    return (
        <Grid container p={1} justifyContent={'center'} className={classes.root}>
            <Grid item xs={12} md={10}>
                <Typography variant="h5" align="center">Are you sure you want to cancel your relevant package?</Typography>
            </Grid>

            <Grid item xs={12} md={10} mt={2}>
                <Typography variant="body2" align="left">(After this process, you cannot renew your current ads if they have expired, and you cannot add new ads if your package usage has expired.)</Typography>
            </Grid>

            <Grid container item xs={12} mt={2} spacing={2}>
                <Grid item xs={6} align={'right'}>
                    <Button variant="contained" className={classes.declineBtn}>decline</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" onClick={() => setAction('')}>cancel</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}