import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& .MuiButtonBase-root': {
                background: colors.black.DEFAULT,
                color: colors.white.DEFAULT
            },
            '& .MuiPaper-root': {
                border: `1px solid ${colors.primary.DEFAULT}`,
                background: colors.secondary.DEFAULT,
                padding: '10px'
            },
            '& p': {
                color: colors.white.DEFAULT
            }
        }
    })

})