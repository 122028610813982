import axios from 'axios'
import { Cookies } from 'react-cookie'

const cookie = new Cookies()
const URL = process.env.REACT_APP_API_DEV

const api = axios.create({
    baseURL: URL,
});

export const removeStorageAndGoToLogin = () => {
    localStorage.clear()
    window.location.href = '/sign_in'
}

const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    try {
        const response = await api.post('/auth/token/refresh/', { refresh: refreshToken });
        const { access, refresh } = response.data;
        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);
        return access;
    } catch (error) {
        removeStorageAndGoToLogin()
        throw error;
    }
};

const makeApiRequest = async (endpoint, method = 'GET', data, noAuth = false, { ...customConfig } = {}) => {
    const config = {
        method,
        url: endpoint,
        ...customConfig,
    };

    if (!noAuth) {
        const accessToken = localStorage.getItem('access_token');
        const csrftoken = cookie.get('csrftoken')
        if (accessToken) {
            config.headers = {
                Authorization: `Bearer ${accessToken}`,
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
                ...customConfig.headers
            }
        }
    }

    // const headers =
    //     !noAuth
    //         ? {
    //             Authorization: `Bearer ${access_token}`,
    //             'X-CSRFToken': csrftoken,
    //             'Content-Type': 'application/json',
    //         }
    //         : {
    //             'X-CSRFToken': csrftoken,
    //             'Content-Type': 'application/json',
    //         }

    try {
        if (data) {
            config.data = data
        }
        const response = await api.request(config)
        return response
    } catch (error) {
        if (error.response && error.response.status === 401) {
            try {
                const newAccessToken = await refreshToken();
                config.headers.Authorization = `Bearer ${newAccessToken}`;
                const retryResponse = await api.request(config);
                return retryResponse
            } catch (refreshError) {
                // error place
            }
        }
        throw error;
    }
}

export default makeApiRequest