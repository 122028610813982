import { Grid, TextField, MenuItem, InputLabel, FormControl, Select, FormControlLabel, Checkbox, Autocomplete } from "@mui/material";
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Controller, useFormContext } from "react-hook-form";
import FormError from "../../../../../../components/FormError/FormError";
import { is_english_text } from "../../../../../../../../validators/file_validators";
import React from 'react';

const CONSTR_OPTIONS = ['Metal', 'Cement', 'Concrete', 'Structure Steel', 'Carbon Fiber', 'Reinforced Steel', 'Steel Wire',
    'Bitumen', 'Adobe', 'Plastic Materials', 'Bamboo', 'Briquette', 'Tile', 'Soil - Sand - Clay', 'Aggregate - Gravel - Stone',
    'Brick', 'Polymer', 'Pine', 'Wood - Plank', 'Ceramic', 'Plastic', 'Polymer', 'Aluminum']

export default function Business({ project }) {
    const { register, formState: { errors }, control, setValue} = useFormContext()

    return (
        <Grid container item xs={12} spacing={2} p={0} alignItems={'center'}>
            <Grid item xs={12} sm={6} md={3} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    fullWidth
                    defaultValue={project?.details?.building_type}
                    {...register('details.building_type', { required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    id="outlined-required"
                    label={<React.Fragment>Building Type<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.building_type} errors={errors.details} name={'building_type'} />

            </Grid>

            <Grid item xs={12} sm={6} md={3} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">
                        <React.Fragment>
                            Infrastructure <span>*</span>
                        </React.Fragment>
                    </InputLabel>
                    <Controller
                        name={'details.infrastructure'}
                        defaultValue={project?.details?.infrastructure}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Garden or Private Area"
                            >
                                <MenuItem value={'fully_equipped'}>Fully Equipped</MenuItem>
                                <MenuItem value={'under_construction'}>Under Construction</MenuItem>
                                <MenuItem value={'unmade'}>Unmade</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormError error={errors?.details?.infrastructure} errors={errors.details} name={'infrastructure'} />
            </Grid>

            <Grid item xs={12} md={6} className='advert_field'>
                <Controller
                    control={control}
                    name={'details.construction_materials'}
                    defaultValue={project?.details?.construction_materials}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags"
                            size="small"
                            options={CONSTR_OPTIONS}
                            onChange={(e, v) => {setValue('details.construction_materials', v, {shouldDirty: true})}}
                            defaultValue={project?.details?.construction_materials}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField {...params} className='tab_text_field' label={<React.Fragment>Construction Material<span>*</span></React.Fragment>} />
                            )}
                            sx={{ width: 'auto' }}
                        />
                    )}
                />

                <FormError error={errors?.details?.construction_materials} errors={errors.details} name={'construction_materials'} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    defaultValue={project?.details?.number_of_rooms}
                    {...register('details.number_of_rooms', {min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label="Number of Rooms"
                />
                <FormError error={errors?.details?.number_of_rooms} errors={errors.details} name={'number_of_rooms'} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    size='small'
                    defaultValue={project?.details?.number_of_workspace}
                    {...register('details.number_of_workspace', {min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    fullWidth
                    id="outlined-required"
                    label="Number of Workspace"
                />
                <FormError error={errors?.details?.number_of_workspace} errors={errors.details} name={'number_of_workspace'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    size='small'
                    defaultValue={project?.details?.number_of_store}
                    {...register('details.number_of_store', {min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    fullWidth
                    id="outlined-required"
                    label="Number of Store / Shop"
                />
                <FormError error={errors?.details?.number_of_store} errors={errors.details} name={'number_of_store'} />
            </Grid>

            <Grid item xs={12} md={6} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    defaultValue={project?.details?.number_of_employee_capacity}
                    {...register('details.number_of_employee_capacity', { required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Number of Employee Capacity<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.number_of_employee_capacity} errors={errors.details} name={'number_of_employee_capacity'} />

            </Grid>

            <Grid item xs={12} md={6} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Garden or Private Area</InputLabel>
                    <Controller
                        name={'details.garden_or_private_area'}
                        defaultValue={project?.details?.garden_or_private_area}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Garden or Private Area"
                            >
                                <MenuItem value={'yes'}>yes</MenuItem>
                                <MenuItem value={'no'}>no</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Heating Method</InputLabel>
                    <Controller
                        name={'details.heating_method'}
                        defaultValue={project?.details?.heating_method}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Heating Method"
                            >
                                <MenuItem value={'central_heating'}>central heating</MenuItem>
                                <MenuItem value={'floor_heating'}>floor heating</MenuItem>
                                <MenuItem value={'air_conditioned'}>air conditioned</MenuItem>
                                <MenuItem value={'gas_or_water_heater'}>gas or water heater</MenuItem>
                                <MenuItem value={'solar_system'}>solar system</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Ventilation Method</InputLabel>
                    <Controller
                        name={'details.ventilation_method'}
                        defaultValue={project?.details?.ventilation_method}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Ventilation Method"
                            >
                                <MenuItem value={'air_extraction_components'}>air extraction components</MenuItem>
                                <MenuItem value={'natural_ventilation'}>natural ventilation</MenuItem>
                                <MenuItem value={'air_conditioner'}>air conditioner</MenuItem>
                                <MenuItem value={'auxiliary ventilation'}>auxiliary ventilation</MenuItem>
                                <MenuItem value={'range_hood_method'}>range hood method</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={4} mt={2} className='advert_field'>
                <FormControlLabel
                    size='small'
                    className='tab_text_field checkbox_field form_checkbox'
                    control={
                        <React.Fragment >
                            <Controller
                                name={"details.furnished"}
                                defaultValue={project?.details?.furnished}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                        icon={<CropSquareRoundedIcon />}
                                        checkedIcon={<SquareRoundedIcon />}
                                        checked={value}
                                        onChange={(e) => onChange(e.target.checked)}
                                    />
                                )}
                            />
                        </React.Fragment>}
                    label="Furnished or Full Factory"
                    labelPlacement="top"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Security</InputLabel>
                    <Controller
                        name={'details.security'}
                        defaultValue={project?.details?.security}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Security"
                            >
                                <MenuItem value={'yes'}>yes</MenuItem>
                                <MenuItem value={'no'}>no</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Elevator</InputLabel>
                    <Controller
                        name={'details.elevator'}
                        defaultValue={project?.details?.elevator}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Elevator"
                            >
                                <MenuItem value={'yes'}>yes</MenuItem>
                                <MenuItem value={'no'}>no</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Parking Lot</InputLabel>
                    <Controller
                        name={'details.parking'}
                        defaultValue={project?.details?.parking}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Security"
                            >
                                <MenuItem value={'yes'}>yes</MenuItem>
                                <MenuItem value={'no'}>no</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    type="number"
                    defaultValue={project?.details?.number_of_floors}
                    {...register('details.number_of_floors', { required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Number of Floors<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.number_of_floors} errors={errors.details} name={'number_of_floors'} />

            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    defaultValue={project?.details?.floor_number}
                    {...register('details.floor_number', {min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    type="number"
                    fullWidth
                    id="outlined-required"
                    label="Floor Number"
                />
                <FormError error={errors?.details?.floor_number} errors={errors.details} name={'floor_number'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    defaultValue={project?.details?.business_type}
                    {...register('details.business_type', { required: true, 
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Business Type<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.business_type} errors={errors.details} name={'business_type'} />
            </Grid>

        </Grid>
    )
}