import { createContext, useState, useMemo } from 'react'
import { createTheme } from '@mui/material'

export const tokens = (mode) => ({
    ...(mode === 'dark'
        ? {
            primary: {
                DEFAULT: '#BD9A68',
                50: '#F5EFE8',
                100: '#EFE6DA',
                200: '#E2D3BD',
                300: '#D6C0A1',
                400: '#C9AD84',
                500: '#BD9A68', 
                600: '#A57F48',
                700: '#7E6137',
                800: '#6F5E45',
                850: '#514636',
                900: '#302515',
                925: '#27231f',
                950: '#1C160C',
            },
            secondary: {
                DEFAULT: '#212121',
            },
            black: {
                DEFAULT: '#161616',
                50: '#727272',
                100: '#686868',
                200: '#535353',
                300: '#3F3F3F',
                400: '#2A2A2A',
                500: '#161616',
                600: '#000000',
                700: '#000000',
                800: '#000000',
                900: '#000000',
                950: '#000000'
            },
            white: {
                DEFAULT: '#FFFFFF',
                100: '#F7F7F7',
            },
            gray: {
                DEFAULT: '#3C3C3C',
                50: '#CCCCCC',
                100: '#b4b0ac',
                150: '#a99e8f',
                1000: '#21212138' // a bit transparent
            },
            fruitSalad: '#50A34E',
            tomato: '#FF5353',
        }
        : {
            primary: {
                DEFAULT: '#BD9A68',
                50: '#1C160C',
                100: '#302515',
                200: '#574326',
                300: '#7E6137',
                400: '#A57F48',
                500: '#BD9A68',
                600: '#C9AD84',
                700: '#D6C0A1',
                800: '#E2D3BD',
                900: '#EFE6DA',
                950: '#F5EFE8'
            },
            secondary: {
                DEFAULT: '#212121',
            },
            black: {
                DEFAULT: '#161616',
                50: '#000000',
                100: '#000000',
                200: '#000000',
                300: '#000000',
                400: '#000000',
                500: '#161616',
                600: '#2A2A2A',
                700: '#3F3F3F',
                800: '#535353',
                900: '#686868',
                950: '#727272',
            },
            white: {
                DEFAULT: '#FFFFFF',
                100: '#F7F7F7',
            },
            gray: {
                DEFAULT: '#3C3C3C',
            },
            fruitSalad: '#50A34E',
            tomato: '#FF5353',
            info: '#F7F7F7'
        }),
})

export const themeSettings = (mode) => {
    const colors = tokens(mode)
    return {
        palette: {
            mode: mode,
            ...(mode === 'dark'
                ? {
                    primary: {
                        main: colors.primary.DEFAULT,
                    },
                    secondary: {
                        main: colors.secondary.DEFAULT,
                    },
                    neutral: {
                        dark: colors.black[500],
                        light: colors.white[100],
                    },
                }
                : {
                    primary: {
                        main: colors.primary.DEFAULT,
                    },
                    secondary: {
                        main: colors.secondary.DEFAULT,
                    },
                    neutral: {
                        dark: colors.black[500],
                        light: colors.white[100],
                    },
                }),
        },
        typography: {
            fontFamily: ['Poppins', 'sans-serif'].join(','),
            fontSize: 14,
            h1: {
                fontFamily: ['Poppins', 'sans-serif'].join(','),
                fontSize: 40,
                fontWeight: 600,
            },
            h2: {
                fontFamily: ['Poppins', 'sans-serif'].join(','),
                fontSize: 35,
                fontWeight: 600,
            },
            h3: {
                fontFamily: ['Poppins', 'sans-serif'].join(','),
                fontSize: 30,
                fontWeight: 500,
            },
            h4: {
                fontFamily: ['Poppins', 'sans-serif'].join(','),
                fontSize: 25,
                fontWeight: 500,
            },
            p: {
                fontFamily: ['Poppins', 'sans-serif'].join(','),
                fontSize: 20,
            },
        },
    }
}

export const ColorModeContext = createContext({
    toggleColorMode: () => { },
})

export const useMode = () => {
    const [mode, setMode] = useState('dark')

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
                setMode((prev) => (prev === 'light' ? 'dark' : 'light')),
        }),
        [],
    )

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])

    return [theme, colorMode]
}