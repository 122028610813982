import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {

        },

        text: {
            cursor: 'pointer',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            transition: 'max-height 0.3s ease'
        },

        collapsed: {
            '-webkit-line-clamp': 2,
        },

        expanded: {
            '-webkit-line-clamp': 'unset',
        }
    })
})