import { Grid, Card, CardContent, Typography } from "@mui/material"
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { useStyles } from "./styles";

export default function CantProcessTrans() {
    const classes = useStyles()

    return (
        <Grid container justifyContent='center' mt={4} p={0} className={classes.root} name='errorMessage'>
            <Grid item xs={12} lg={10}>
                <Card component={Grid}>
                    <CardContent>
                        <Grid container p={0} alignItems='center'>
                            <Grid container item xs={3} sm={2} p={1} justifyContent='center'>
                                <Grid item >
                                    <ReportProblemRoundedIcon className='attention_icon' />
                                </Grid>
                            </Grid>
                            <Grid container item xs={9} sm={10} p={0}>
                                <Grid item xs={12}>
                                    <Typography variant='h5' sx={{ fontWeight: 600 }}>
                                        We can't process your transaction.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1' sx={{ fontWeight: 300 }}>
                                        User can only have one package.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}