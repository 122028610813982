import { Grid, Typography, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { useDispatch, useSelector } from 'react-redux';
import { putFilterData, selectProjectFilters } from '../../../../../../features/projects/projectsSlice';


const ESTATES = [
    ['real_estate', 'Real Estate'], ['land_and_terrain', 'Land & Terrain'], ['tourism_and_facility', 'Tourism & Facility'],
    ['business', 'Business']
]

export default function FilterTypesEstate() {
    const dispatch = useDispatch()
    const filters = useSelector(selectProjectFilters)

    const choose_estate = (value, estate) => {
        dispatch(putFilterData({ 'field': 'estate_category', 'data': value ? estate : '' }))
        dispatch(putFilterData({ 'field': 'additional', 'data': {} }))
    }

    return (
        <Grid container item md={2} p={0} mb={2}>
            <Grid item xs={12} mb={1} pl={1}>
                <Typography color={'white'}>Type of Estate</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" >
                        {ESTATES.map(el => (
                            <FormControlLabel
                                className='checkbox_field'
                                control={<Checkbox
                                    onChange={(e) => choose_estate(e.target.checked, el[0])}
                                    checkedIcon={<SquareRoundedIcon />}
                                    checked={filters?.estate_category === el[0] && true}
                                    defaultChecked={filters?.estate_category === el[0] && true}
                                />}
                                label={el[1]}
                                labelPlacement="right"
                            />
                        ))}
                    </FormGroup>
                </FormControl>

            </Grid>
        </Grid>
    )
}