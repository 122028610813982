import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& h6': {
                textTransform: 'capitalize'
            },
            '& .MuiSvgIcon-root': {
                paddingBottom: '3px',
                paddingRight: '5px '
            },
            '& .MuiButtonBase-root': {
                color: colors.white.DEFAULT,
                width: '150px'
            },
            '& .MuiDialogTitle-root': {
                '& .MuiTypography-root': {
                    textAlign: 'center',
                    lineHeight: '20px'
                },
            },
            '& .MuiPaper-root': {
                background: `${colors.secondary.DEFAULT} !important`
            },
            '& .MuiDialogActions-root': {
                paddingBottom: '20px',
                '& .MuiGrid-root': {
                    '& .MuiGrid-root:nth-child(1)': {
                        '& .MuiButtonBase-root': {
                            background: colors.primary[850],
                            '&:hover': {
                                background: colors.primary[800]
                            }
                        },
                    }
                }
            },
        },
        tariff: {
            border: `2px solid ${colors.primary[850]}`,
            borderRadius: '10px',
            cursor: 'pointer'
        },
        chosenTariff: {
            border: `2px solid ${colors.primary.DEFAULT}`,
        },
        line: {
            width: '20%',
            height: '5px',
            border: `1px solid ${colors.primary[800]}`,
            background: colors.primary[800],
            borderRadius: '10px'
        },
        verticalLine: {
            border: `1px solid ${colors.primary[800]}`,
        }
    })
})