import { makeStyles } from "@mui/styles";
import { tokens } from '../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            scrollBehavior: 'smooth',
            background: colors.black.DEFAULT,
            borderRadius: '5px',
            height: '100%',
            overflow: 'auto',
            '& .MuiButton-root': {
                color: colors.white.DEFAULT,
                background: colors.primary.DEFAULT
            },
        },
        tariffCard: {
                height: '100%',
                background: colors.secondary.DEFAULT,
                border: `.3px solid ${colors.white.DEFAULT}`,
                transition: '.3s',
                '&:hover': {
                    borderColor: colors.primary.DEFAULT
                },
                '& .MuiIconButton-root': {
                    padding: '5px',
                    fontSize: '45px',
                },
                '& .MuiSvgIcon-root': {
                    color: colors.primary.DEFAULT
                },
                '& .MuiTableCell-root': {
                    borderBottom: `1px solid ${colors.black[200]} !important`,
                    color: colors.white.DEFAULT
                },
        },
        cardLine: {
            height: '10px',
            background: colors.primary.DEFAULT,
            borderRadius: '5px'
        },
        tariffTitle: {
            color: colors.white.DEFAULT,
            textTransform: 'capitalize',
            lineHeight: `1.6 !important`,
            fontSize: '35px !important',
            fontWeight: `600 !important`
        },
        tariffDescription: {
            color: colors.white.DEFAULT,
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1em !important',
            fontSize: '0.875rem'
        },
        tariffPrice: {
            color: colors.white.DEFAULT,
            fontSize: '30px'
        },
        notActiveRow: {
            color: `${colors.gray.DEFAULT} !important`
        },

        btnPurchaseDisabled: {
            background: `${colors.secondary.DEFAULT} !important`,
            color: `${colors.white.DEFAULT} !important`,
            border: `1px solid ${colors.primary.DEFAULT}`
        }

    })
})