import { Grid, Typography, LinearProgress, Button } from '@mui/material';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import {
    media_type_validator, count_media_validator, file_size_validator, video_size_validator,
    cert_type_validator, only_image, at_least_one_image
} from '../../../../../../../validators/file_validators'
import FormError from '../../../../../components/FormError/FormError';
import { Dropzone } from "@dropzone-ui/react";
import { useFormContext, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import SortImagesDialog from '../../../../components/dialogs/SortImagesDialog/SortImagesDialog';


export default function Media({ project, purpose }) {
    const { resetField, formState: { errors, dirtyFields }, watch, control } = useFormContext()
    const [count_files, set_count_files] = useState(0)
    const [count_draw, set_count_draw] = useState(0)
    const [count_cert, set_count_cert] = useState(0)
    const [sortImages, setSortImages] = useState(false)

    const category = watch('general.estate_category')

    const clear_media = () => {
        if (dirtyFields?.media) {
            resetField('media', { defaultValue: project?.media })
            enqueueSnackbar('You have reset the media field!', { variant: 'success' })
        }
        else
            enqueueSnackbar('Field media is empty', { variant: 'warning', style: { background: '#8d724d' } })
    }

    const clear_draw = () => {
        if (dirtyFields?.drawings) {
            resetField('drawings', { defaultValue: project?.drawings })
            enqueueSnackbar('You have reset the drawings field!', { variant: 'success' })
        }
        else
            enqueueSnackbar('Field drawings is empty', { variant: 'warning', style: { background: '#8d724d' } })
    }

    const clear_cert = () => {
        if (dirtyFields?.certificates) {
            resetField('certificates', { defaultValue: project?.certificates })
            enqueueSnackbar('You have reset the certificates field!', { variant: 'success' })
        }
        else
            enqueueSnackbar('Field certificates is empty', { variant: 'warning', style: { background: '#8d724d' } })
    }

    useEffect(() => {
        if (project) {
            if (watch('media')?.length && dirtyFields.media) {
                set_count_files(watch('media')?.length + project?.media.length)
            }
            else set_count_files(project?.media.length)

            if (watch('drawings')?.length && dirtyFields.drawings) {
                set_count_draw(watch('drawings')?.length + project?.drawings.length)
            }
            else set_count_draw(project?.drawings.length)

            if (watch('certificates')?.length && dirtyFields.certificates) {
                set_count_cert(watch('certificates')?.length + project?.certificates.length)
            }
            else set_count_cert(project?.certificates.length)

        }
        else {
            watch('media')?.length ? set_count_files(watch('media')?.length) : set_count_files(0)
            watch('drawings')?.length ? set_count_draw(watch('drawings')?.length) : set_count_draw(0)
            watch('certificates')?.length ? set_count_cert(watch('certificates')?.length) : set_count_cert(0)

        }
    }, [project, watch('media'), watch('drawings'), watch('certificates')])

    const upload_media = (el) => {
        let positions_array = []
        if (project) {
            Object.values(project?.media).map(item => positions_array.push(item.position))
        }

        Object.keys(el).forEach((key, index) => {
            if (el[key].type.slice(0, 5) === 'image') {
                for (let i = 1; i <= 10; i++) {
                    if (!positions_array.includes(i)) {
                        positions_array.push(i)
                        el[key]['position'] = i
                        break;
                    }
                }
            }
            else {
                el[key]['position'] = 10
            }
        })

        return el
    }

    return (
        <Grid container item xs={12} lg={4.5} xl={4} spacing={1} sx={{ p: { xs: 0, md: 1.5 } }}
        className={!category && 'not_able_block'}>
            <Grid item xs={12}>
                <Typography variant='h5' color='#fff'>Add Your Advert Media</Typography>
            </Grid>

            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Typography color="#fff" variant='h6'>
                        Media <span className='choose_category_span'>*</span>
                    </Typography>

                    <Typography className='media_upload_small_text'>
                        Use up to 9 photos and 1 video
                        to show your project’s
                        most important and
                        specifies features.
                    </Typography>
                </Grid>

                <Grid item xs={6} pl={1} className='grid_upload_inputs'>
                    <Controller
                        name={'media'}
                        control={control}
                        defaultValue={project?.media}
                        rules={{
                            required: true,
                            validate: {
                                media_type: v => media_type_validator(v) || 'You can upload JPG, PNG or Video files!',
                                image_size: v => file_size_validator(v) || 'The image file size must not exceed 1MB!',
                                video_size: v => video_size_validator(v) || 'The video file size must not exceed 30MB!',
                                media_count: v => count_media_validator(v, dirtyFields?.media && project?.media) || 'You can only upload 1 video and 9 photos!',
                                at_least_one: v => at_least_one_image(v, project?.media) || 'You must upload at least one image'
                            }
                        }}
                        render={({ field: { onChange } }) => (
                            <Dropzone header={false} footer={false} className='dropzone_media'
                                onChange={(e) => onChange(upload_media(e))}
                                label={<AddPhotoAlternateOutlinedIcon />}
                            />
                        )}
                    />
                    <Button className='clear_btn_media' onClick={clear_media}>x</Button>
                    <FormError error={errors?.media} errors={errors} name={'media'} align_to_right={true}/>
                </Grid>

                <Grid item xs={12} mt={3} className='span_linear_progress' align='end'>
                    <span > Number of media {count_files}/10</span>
                    <LinearProgress variant="determinate" value={count_files * 10 > 100 ? 100 : count_files * 10} />
                </Grid>
                {(purpose === 'edit' || purpose === 'add') &&
                    <Grid item xs={12} mt={0}>
                        <Button sx={{width: '100% !important'}} className="view_btn_overview" onClick={() => setSortImages(true)}>Sort/Edit Images</Button>
                    </Grid>
                }
            </Grid>

            <Grid container item xs={12} mt={2} alignItems={'center'}>
                <Grid item xs={6}>
                    <Typography color="#fff" variant='h6'>
                        Drawings & Plans
                    </Typography>

                    <Typography className='media_upload_small_text'>
                        You can upload project drawings
                        and plans of your land
                        or real estate here.
                    </Typography>
                </Grid>

                <Grid item xs={6} pl={2} align='center' className='grid_upload_inputs'>
                    <Grid item xs={12}>
                        <Controller
                            name={'drawings'}
                            control={control}
                            defaultValue={{ ...project?.drawings }}
                            rules={{
                                validate: {
                                    file_type: v => only_image(v) || 'You can upload JPG or PNG files!',
                                    image_size: v => file_size_validator(v) || 'The image file size must not exceed 1MB!',
                                    count: v => (0 < v?.length + (project?.drawings.length ? project?.drawings.length : 0) < 3) || 'You can upload only 2 files!'
                                }
                            }}
                            render={({ field: { onChange } }) => (
                                <Dropzone header={false} footer={false} className='dropzone_certificate_and_drawing'
                                    onChange={onChange}
                                    label='Upload Drawings' minHeight='50px'
                                />
                            )}
                        />
                    </Grid>
                    <Button className='clear_btn_media' onClick={clear_draw}>x</Button>
                    <FormError error={errors?.drawings} errors={errors} name={'drawings'} align_to_right={true}/>
                </Grid>

                <Grid item xs={12} mt={3} className='span_linear_progress' align='end'>
                    <span > Number of drawings {count_draw}/2</span>
                    <LinearProgress variant="determinate" value={count_draw * 50 > 100 ? 100 : count_draw * 50} />
                </Grid>
            </Grid>

            <Grid container item xs={12} mt={2}>
                <Grid item xs={6}>
                    <Typography color="#fff" variant='h6'>
                        Certificates
                    </Typography>

                    <Typography className='media_upload_small_text'>
                        Here you need to upload certificates for your estate.
                    </Typography>
                </Grid>

                <Grid item xs={6} pl={2} align='center' className='grid_upload_inputs'>
                    <Controller
                        name={'certificates'}
                        control={control}
                        defaultValue={project?.certificates}
                        rules={{
                            validate: {
                                file_type: v => cert_type_validator(v) || 'You can upload PDF files!',
                                cert_size: v => file_size_validator(v) || 'The file size must not exceed 1MB!',
                                count: v => 0 < v?.length + (project?.certificates.length ? project?.certificates.length : 0) < 3 || 'You can upload only 2 files!'
                            }
                        }}
                        render={({ field: { onChange } }) => (
                            <Dropzone header={false} footer={false} className='dropzone_certificate_and_drawing'
                                onChange={onChange} maxFiles={2}
                                label='Upload Certificates' minHeight='50px'
                            />
                        )}
                    />
                    <Button className='clear_btn_media' onClick={clear_cert}>x</Button>
                    <FormError error={errors?.certificates} errors={errors} name={'certificates'} align_to_right={true}/>
                </Grid>

                <Grid item xs={12} mt={3} className='span_linear_progress' align='end'>
                    <span > Number of certificates {count_cert}/2</span>
                    <LinearProgress variant="determinate" value={count_cert * 50 > 100 ? 100 : count_cert * 50} />
                </Grid>
            </Grid>
            <SnackbarProvider autoHideDuration={2000} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} />

            {(purpose === 'edit' || purpose === 'add') &&
                <SortImagesDialog open={sortImages} setOpen={setSortImages} project={project}/>
            }
            
        </Grid>
    )
}