import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& .MuiSvgIcon-root': {
                fontSize: '70px',
                fill: colors.primary.DEFAULT,
                '& path': {
                    backgroundColor: colors.black.DEFAULT
                }
            }
        }
    })
})