import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& p': {
                color: colors.gray[50]
            },
            '& .MuiTextField-root': {
                marginTop: '5px',
            },
            '& .MuiInputBase-root': {
                borderRadius: '8px',
                background: colors.white.DEFAULT,
                color: colors.gray.DEFAULT, 
            },
        },
        phoneInput: {
            '& .MuiInputBase-root': {
                padding: '6px',
            },
            '& .Mui-focused': {
                outline: `2px solid ${colors.primary.DEFAULT}`,
            }
        },
        
        
    })

})