import { Grid, Typography, OutlinedInput, InputAdornment, IconButton, Button } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormError from "../../../../components/FormError/FormError";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../../../../features/admin_panel/adminPanelSlice";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useStyles } from "../styles";

const DATA = [
    { label: 'New Password', autocomplete: "current-password" },
    { label: 'Confirm New Password', autocomplete: "new-password", name: 'password' }
]


export default function ChangePassword() {
    const { register, reset, formState: { dirtyFields, errors }, setValue, handleSubmit } = useForm({ mode: 'onChange' })
    const [checkInput, setCheckInput] = useState('')
    const dispatch = useDispatch()
    const classes = useStyles()

    const [showPassword, setShowPassword] = useState(true);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSave = (data) => {
        dispatch(changePassword({ 'data': data })).then((e) => {
            enqueueSnackbar('The password was successfully updated', { variant: 'success', style: { background: '#BD9A68' } })
            reset()
        })
    }

    return (
        <Grid container p={0} className={classes.root}>
            <Grid container className={classes.titleBlock}>
                <Typography variant="h4" >Change Your Password</Typography>
            </Grid>
            <form onSubmit={handleSubmit(handleSave)} >
                <Grid container spacing={1} mt={2}>
                    {DATA.map((el, index) => (
                        <Grid container item xs={12} p={0} alignItems={'center'} key={index} >
                            <Grid item xs={6} alignContent={'center'} >
                                <Typography >{el.label}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ 'position': 'relative' }}>
                                <OutlinedInput
                                    size="small"
                                    type={showPassword ? 'text' : 'password'}
                                    {...(el.name ? register(el.name, {
                                        validate: {
                                            same: v => v === checkInput || "Passwords don't match!"
                                        },
                                        required: 'Password is required field!',
                                        pattern: {
                                            value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*_)(?!.* ).{8,16}$/,
                                            message: 'The password must contain upper and lower case Latin letters, numbers and special character!'
                                        },
                                        minLength: {
                                            value: 8,
                                            message: 'The password must consist of at least 8 characters!'
                                        }
                                    }) : {})}
                                    autoComplete={el.autocomplete}
                                    onChange={(e) => !el?.name ? setCheckInput(e.target.value) : setValue(el.name, e.target.value, { shouldDirty: true })}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={el.label}
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormError error={errors[el.name]} errors={errors} name={el.name} />
                            </Grid>

                        </Grid>
                    ))}
                </Grid>
                <Grid container mt={2}>
                    <Button disabled={Object.keys(dirtyFields).length > 0 ? false : true} variant="contained" type="submit" sx={{ color: 'white' }}>
                        Set Changes
                    </Button>
                </Grid>
            </form>
            <SnackbarProvider autoHideDuration={2000} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} />
        </Grid>
    )
}