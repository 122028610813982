import axios from "axios"
import { CHECKOUT_DATA } from "./constants"

const HEADERS = {
    'Authorization': `Bearer ${process.env.REACT_APP_LEMON_SQUEEZY_API_KEY}`,
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
}

export const create_checkout = async (custom_price, product_data, checkout_data) => {
    const CHECKOUT_URL = 'https://api.lemonsqueezy.com/v1/checkouts'
    const DATA = CHECKOUT_DATA

    DATA.data.attributes.custom_price = custom_price
    DATA.data.attributes.product_options = product_data
    DATA.data.attributes.checkout_data = checkout_data


    const res = await axios.post(CHECKOUT_URL, DATA, {
        headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_LEMON_SQUEEZY_API_KEY}`,
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    })
    return res.data.data.attributes.url
}